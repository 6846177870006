import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logos/CodeStar-Icon-White.svg";

const Header = (props) => {
  return (
    <header className="bg-primary navbar-dark">
      <div className="container">
        <nav className="row navbar navbar-expand-lg align-items-start">
          <div className="col-12 col-lg-auto order-lg-2 d-flex justify-content-between">
            <a
              className="navbar-brand"
              href="/templates"
              style={{
                display: "inline-block",
                padding: 0.75 + "rem",
                backgroundColor: "rgb(59, 130, 246)",
                borderRadius: 0.75 + "rem",
              }}
            >
              <img
                src={logo}
                alt="CodeStar Logo"
                style={{ maxWidth: "100%", height: 2.5 + "rem" }}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-toggle"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="icon-menu"></i>
            </button>
          </div>
          <div className="col-12 col-lg order-lg-1">
            <div className="collapse navbar-collapse navbar-toggle">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/templates" className="nav-link">
                    Templates
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/brand-guidelines" className="nav-link">
                    Brand Guidelines
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav navbar-account">
                <li className="nav-item">
                  <Link to="/account" className="nav-link">
                    My Account
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="mailto:support@codestar.com"
                    className="nav-link"
                    target="_blank"
                  >
                    Submit Ticket
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/logout" className="nav-link">
                    Log Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
