import React from "react";
import PropTypes from "prop-types";

const LoginTemplate = ({ children }) => {
  return (
    <React.Fragment>
      <div className="cs-flex cs-h-screen cs-p-4">
        <div className="cs-m-auto cs-w-full lg:!cs-w-1/2 xl:!cs-w-1/3">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

LoginTemplate.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LoginTemplate;
