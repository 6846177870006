// Main
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
// Our Defined Functions
import { apiRequest } from "../helper/api";
import { authContext } from "../contexts/AuthContext";
import useErrorHandler from "../hooks/errorHandler";
// Components to make up the page
import { LoginTemplate } from "../components";
import ErrorMessage from "../components/SystemMessages/ErrorMessage";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);

  const history = useHistory();
  const { error, showError } = useErrorHandler(null);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const response = await apiRequest("signin", "POST", { email, password });
      // TODO Make the match the 'success' response
      if (!response.status) {
        setLoading(false);
        showError("Incorrect login details");
      } else {
        // Get the profile
        const response = await apiRequest("profile", "GET");
        if (response.success) {
          const { data } = response;
          const { profile } = data;
          auth.setAuthStatus({ ...profile, isAuthenticated: true });
          setLoading(false);
          history.push("/templates");
        } else {
          setLoading(false);
          showError("Unable to load profile.");
        }
      }
    } catch (error) {
      setLoading(false);
      showError(error.message);
    }
  };

  return (
    <LoginTemplate>
      <div className="cs-card">
        <div className="cs-p-6">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="cs-w-full cs-space-y-6"
          >
            <div>
              <h1 className="cs-text-xl cs-font-medium cs-tracking-tight cs-text-white">
                Sign in
              </h1>
            </div>
            <div>
              <input
                type="text"
                name="email"
                defaultValue={email}
                id="email"
                autoComplete="username"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                defaultValue={password}
                id="password"
                autoComplete="current-password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <ErrorMessage errorMessage={error} />}
            {loading ? (
              <div class="cs-flex cs-justify-center">
                <div class="cs-spinner"></div>
              </div>
            ) : (
              <div>
                <input type="submit" value="Sign in" className="cs-w-full" />
              </div>
            )}
          </form>
        </div>
      </div>
    </LoginTemplate>
  );
};

export default Login;
