import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/aptean/styles-aptean.css";

const Feature4 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const { blockKey, title, text, image1 } = props;

  return (
    <section key={blockKey} class="bg-white py-6 lg:py-12">
      <div class="relative px-4 lg:container lg:mx-auto lg:px-20">
        <div class="grid gap-y-12 md:grid-cols-2 md:gap-y-0 md:gap-x-8 lg:gap-x-12">
          <div>
            <div pardot-region="Feature-Variant-4--Image">
              <img src={`${image1}?x-timestamp=${Date.now()}`} alt="" />
            </div>
          </div>
          <div>
            <div class="wysiwyg-title" pardot-region="Feature-Variant-4--Title">
              <ContentEditable
                html={title}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("title", event);
                }}
              />
            </div>
            <div class="mt-8 lg:mt-10">
              <div
                class="wysiwyg-text-base"
                pardot-region="Feature-Variant-4--Text"
              >
                <ContentEditable
                  html={text}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text", event);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature4;
