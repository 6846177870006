import React, { useContext } from "react";

import Accordion from "../../../../components/elements/Accordion";
import Block from "../../../elements/Block";

import { authContext } from "../../../../contexts/AuthContext";
import COMPANY_BLOCKS from "../../../../blocks/";
import { PANE_OPTIONS } from "../../../../Constants";

const BlocksPane = (props) => {
  const auth = useContext(authContext);
  const brandGuidelines = auth.getBrandGuidelines();
  const blockPath = auth.getBlockPath();
  const BlockLibrary = COMPANY_BLOCKS[blockPath](brandGuidelines); // Inject the brandGuidelines into the chosen company blocks

  const renderBlock = (block) => {
    const { onClick, onVisiblePaneChange } = props;
    const { id, title, code, image } = block;
    return (
      <Block
        key={id}
        name={title}
        code={code}
        image={image}
        id={id}
        onClick={() => {
          onVisiblePaneChange(PANE_OPTIONS);
          onClick(block);
        }}
      />
    );
  };

  const renderCategory = (title, blocks, userBlocks) => {
    const content = [];
    for (const [blockName, block] of Object.entries(blocks)) {
      console.log("blockName", blockName);
      content.push(renderBlock(block));
    }

    if (content.length > 0) {
      return (
        <div key={title} label={title}>
          {content}
        </div>
      );
    }
    return false;
  };

  const blockPaneContent = [];
  for (const [, value] of Object.entries(BlockLibrary)) {
    const { name, blocks } = value;
    const block = renderCategory(name, blocks);
    blockPaneContent.push(block);
  }

  return <Accordion>{blockPaneContent}</Accordion>;
};

export default BlocksPane;
