import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { renderToString } from "react-dom/server";
import random from "random-key-generator";
import _ from "lodash";
import html2canvas from "html2canvas";
import inlineCss from "inline-css";
import { Helmet } from "react-helmet";

import { apiRequest } from "../helper/api";
import useErrorHandler from "../hooks/errorHandler";

import { BuilderTemplate } from "../components";
import BlocksPane from "../components/modules/builder/BlocksPane";
import NavPane from "../components/modules/builder/NavPane";
import CanvasPane from "../components/modules/builder/CanvasPane";
import TextToolbar from "../components/modules/builder/TextToolbar";
import TemplatesPane from "../components/modules/builder/TemplatesPane/TemplatesPane";

import { authContext } from "../contexts/AuthContext";

import { header, footer } from "../templates/common"; // TODO Change the import based on template type
import { headerLP, footerLP } from "../templates/common-lp"; // TODO Change the import based on template type
import { PANE_BLOCKS, PANE_OPTIONS } from "../Constants";

const BuilderPage = () => {
  let { id } = useParams();

  const history = useHistory();
  const initialSelectedBlock = null;
  const initialSelectedBlockOptions = null;
  const initialShowModal = false;
  const initialShowSettingsModal = false;
  const initialShowSaveModal = false;
  const initialSelectedBlockKey = null;
  const initialVisiblePane = PANE_BLOCKS;
  const initialModalContent = "";

  const auth = useContext(authContext);
  const brandGuidelines = auth.getBrandGuidelines();
  const blockPath = auth.getBlockPath();
  const user = auth.getAuthStatus();

  // Get the state hooks going
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [css, setCss] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("email");
  const [title, setTitle] = useState("");
  const [favicon, setFavicon] = useState("");
  const [extraHeader, setExtraHeader] = useState("");
  const [extraFooter, setExtraFooter] = useState("");
  const [masterExtraHeader] = useState(
    user.extraHeader === undefined ? "" : user.extraHeader,
  );
  const [masterExtraFooter] = useState(
    user.extraFooter === undefined ? "" : user.extraFooter,
  );
  const [updatedAt, setUpdatedAt] = useState("");
  const [emailContent, setEmailContent] = useState([]);
  const [blockOptions, setBlockOptions] = useState({});
  const [selectedBlock, setSelectedBlock] = useState(initialSelectedBlock);
  const [selectedBlockOptions, setSelectedBlockOptions] = useState(
    initialSelectedBlockOptions,
  );
  const [selectedBlockKey, setSelectedBlockKey] = useState(
    initialSelectedBlockKey,
  );
  const [showModal, setShowModal] = useState(initialShowModal);
  const [showSaveModal, setShowSaveModal] = useState(initialShowSaveModal);
  const [showSettingsModal, setShowSettingsModal] = useState(
    initialShowSettingsModal,
  );
  const [modalContent, setModalContent] = useState(initialModalContent);
  const [visiblePane, setVisiblePane] = useState(initialVisiblePane);
  const [saving, setSaving] = useState(false);

  // Set the error Handler
  const { showPersistentError } = useErrorHandler(null);

  // Load the templates
  useEffect(() => {
    getEmail(id);
  }, []);

  const getEmail = async () => {
    try {
      setLoading(true);
      const response = await apiRequest(`email/${id}`, "GET");
      if (response.success) {
        setLoading(false);
        const email = response.data;

        const { name, updatedAt, type, css, extraHeader, extraFooter, title } =
          email;

        setEmail(response.data);
        setName(name);
        setUpdatedAt(updatedAt);
        setCss(css);
        setTitle(title);
        setExtraHeader(extraHeader);
        setExtraFooter(extraFooter);
        if (type !== undefined) {
          setType(type);
        }
        // Used for older model of data
        if (response.data.hasOwnProperty("content")) {
          setEmailContent(response.data.content);
        }
        if (response.data.hasOwnProperty("blockOptions")) {
          setBlockOptions(response.data.blockOptions);
        }
      } else {
        setLoading(false);
        const { errorCode } = response;
        if (errorCode === 401) {
          history.replace("/login");
        } else if (errorCode === 404) {
          // Set to an empty array so it will show the no emails dialog
          setEmail(null);
        } else {
          showPersistentError("Unable to load saved emails: " + response.error);
        }
      }
    } catch (e) {
      setLoading(false);
      showPersistentError("Unable to load saved emails." + e);
    }
  };

  const save = async () => {
    setSaving(true);

    // TODO Get the canvas and then sort the base64 if the image and then migrate them to a new base64 image instead of remote element
    html2canvas(document.querySelector("#emailCanvasPane"), {
      allowTaint: true,
      useCORS: true,
      logging: true,
      // ignoreElements: (element) => {
      //   const elementsToHide = ["img", "p", "span", "head"];
      //   return elementsToHide.indexOf(element.nodeName.toLowerCase()) > -1;
      // },
    }).then((canvas) => {
      // TODO iterate over each IMG and base64 encode them
      saveToServer(canvas);
    });
  };

  const saveToServer = async (canvas) => {
    try {
      // Save to server
      const id = email._id;

      // TODO Resize the image.
      const payload = {
        content: emailContent,
        blockOptions,
        name: email.name,
        snapshot: canvas.toDataURL("image/jpeg"),
        title,
        favicon,
        extraFooter,
        extraHeader,
        type,
      };
      const response = await apiRequest(`email/${id}`, "PUT", payload);
      if (response.success) {
        setSaving(false);
        setLoading(false);
        //setShowSaveModal(true);
      } else {
        setSaving(false);
        setLoading(false);
        showPersistentError("Unable to save template : " + response.error);
      }
    } catch (e) {
      showPersistentError("Unable to save template: " + e);
    }
  };

  /**
   * Handles any content changes from the app
   */
  const onContentChange = (key, item, content) => {
    // Update the email state
    const newEmailContent = emailContent.map((el) => {
      if (el.key !== key) {
        return el;
      }
      // Create a new block so we can ammend the parameter that has been updated.
      const newBlock = _.cloneDeep(el);
      newBlock.editableContent[item] = content;
      return newBlock;
    });
    setEmailContent(newEmailContent);
  };

  const onClickWidget = (block) => {
    if (block.ComponentName === undefined) {
      return;
    }
    const key = random();
    // Add the widget
    const newBlock = _.cloneDeep(block);
    newBlock.key = key;
    newBlock.onContentChange = onContentChange;
    // TODO This line looks to be not necessary however i also feel that it should be here as it
    // TODO should be cloning rather than pushing but not sure.
    //const newEmailContent = _.cloneDeep([emailContent, newBlock])
    emailContent.push(newBlock);
    // Add the options
    const newOptions = { options: block.options, key }; // TODO Refactor so we dont need key anymore
    const newBlockOptions = _.cloneDeep(blockOptions);
    newBlockOptions[key] = _.cloneDeep(block.options);

    // Update the Hooks, needs to all be called and in this order! Any time a single hook is called.
    setEmailContent(emailContent);
    setBlockOptions(newBlockOptions);
    setSelectedBlock(newBlock);
    setSelectedBlockOptions(newOptions);
    setSelectedBlockKey(key);
    setShowModal(showModal);
  };

  const onDeleteBlock = (selectedBlockKey) => {
    const newEmailContent = emailContent.filter((block) => {
      if (block.key === selectedBlockKey) {
        return false;
      }
      return true;
    });
    delete blockOptions[selectedBlockKey];
    const newBlockOptions = blockOptions;

    setVisiblePane(PANE_BLOCKS);
    setEmailContent(newEmailContent);
    setBlockOptions(newBlockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(selectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);
    setShowModal(showModal);
  };

  const onBlockClick = (selectedBlockKey) => {
    const selectedBlock = emailContent.find((block) => {
      if (block.key === selectedBlockKey) {
        const newBlock = _.cloneDeep(block);
        return newBlock;
      }
      return false;
    });
    const newSelectedBlockOptions = {
      options: blockOptions[selectedBlockKey],
      key: selectedBlockKey,
    }; // TODO Refactor so we dont need key anymore
    setVisiblePane(PANE_OPTIONS);
    setEmailContent(emailContent);
    setBlockOptions(blockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(newSelectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);
    setShowModal(showModal);
  };

  const onOptionUpdate = (key, optionKey, group, value) => {
    // Render the new option with its value
    const newEmailContent = emailContent.map((block) => {
      // Find the option and clone their details
      if (block.key === key) {
        // Create the new block
        const newBlock = { ...block };
        newBlock.optionValues[optionKey] = value;
        return newBlock;
      }
      return block;
    });

    // Store the option in state so that we can show the selected value
    const newBlockOptions = _.cloneDeep(blockOptions);
    newBlockOptions[key][group][optionKey].value = value;
    const newSelectedBlockOptions = _.cloneDeep(selectedBlockOptions);
    newSelectedBlockOptions.options[group][optionKey].value = value;
    // Update the current block options to show on the right column
    // Store the new options in state
    setEmailContent(newEmailContent);
    setBlockOptions(newBlockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(newSelectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);
    setShowModal(showModal);
  };

  const onOrderChange = (emailContent) => {
    setEmailContent(emailContent);
    setBlockOptions(blockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(selectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);
    setShowModal(showModal);
    setShowSaveModal(showSaveModal);
  };

  const onShowSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const onCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const onShowModal = () => {
    setEmailContent(emailContent);
    setBlockOptions(blockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(selectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);

    const { css } = email;

    // Get the latest content for the modal
    let newModalContent = renderToString(
      <CanvasPane
        type={type}
        emailContent={emailContent}
        exportMode={true}
        brandGuidelines={brandGuidelines}
        blockPath={blockPath}
      />,
    );

    // FIND REPLACE THE COMMENTS
    newModalContent = newModalContent.replace(
      /<div class="aComment" style="display:none">AAAAAA /g,
      "<!--",
    );
    newModalContent = newModalContent.replace(/ ZZZZZZ<\/div>/g, "-->");
    newModalContent = newModalContent.replace(/contenteditable="true"/g, "");

    if (type !== undefined && type === "landingpage") {
      // Its a landing page
      newModalContent = `${headerLP}${newModalContent}`;
      newModalContent = `${newModalContent}${footerLP}`;

      // REPLACE THE PLACEHOLDERS
      // CSS
      newModalContent = newModalContent.replace(
        "[[[CSS]]]",
        `<link rel="stylesheet" href="${css}" />`,
      );
      // Title
      newModalContent = newModalContent.replace("[[[TITLE]]]", title);
      // Master Extra Header & Footer - Set at a user level
      newModalContent = newModalContent.replace(
        "[[[MASTEREXTRAHEADER]]]",
        masterExtraHeader !== undefined ? masterExtraHeader : "",
      );
      newModalContent = newModalContent.replace(
        "[[[MASTEREXTRAFOOTER]]]",
        masterExtraFooter !== undefined ? masterExtraFooter : "",
      );

      // Extra Header & Footer - Set at a template level
      newModalContent = newModalContent.replace(
        "[[[EXTRAHEADER]]]",
        extraHeader !== undefined ? extraHeader : "",
      );
      newModalContent = newModalContent.replace(
        "[[[EXTRAFOOTER]]]",
        extraFooter !== undefined ? extraFooter : "",
      );

      setModalContent(newModalContent);
      setShowModal(true);
    } else {
      // Its an email
      newModalContent = `${header}${newModalContent}`;
      newModalContent = `${newModalContent}${footer}`;

      // REPLACE THE PLACEHOLDERS
      // CSS
      newModalContent = newModalContent.replace(
        "[[[CSS]]]",
        `<link rel="stylesheet" href="${css}" />`,
      );
      // Title
      newModalContent = newModalContent.replace("[[[TITLE]]]", title);

      // Master Extra Header & Footer - Set at a user level
      newModalContent = newModalContent.replace(
        "[[[MASTEREXTRAHEADER]]]",
        masterExtraHeader !== undefined ? masterExtraHeader : "",
      );
      newModalContent = newModalContent.replace(
        "[[[MASTEREXTRAFOOTER]]]",
        masterExtraFooter !== undefined ? masterExtraFooter : "",
      );

      // Extra Header & Footer - Set at a template level
      newModalContent = newModalContent.replace(
        "[[[EXTRAHEADER]]]",
        extraHeader !== undefined ? extraHeader : "",
      );
      newModalContent = newModalContent.replace(
        "[[[EXTRAFOOTER]]]",
        extraFooter !== undefined ? extraFooter : "",
      );

      // Inline all the CSS
      inlineCss(newModalContent, {
        removeLinkTags: false,
        applyLinkTags: false,
        applyStyleTags: false,
        removeStyleTags: false,
        removeHtmlSelectors: false,
        url: "/",
      }).then(function (html) {
        setModalContent(html);
        setShowModal(true);
      });
    }
  };

  const onCloseModal = () => {
    setEmailContent(emailContent);
    setBlockOptions(blockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(selectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);
    setModalContent("");
    setShowModal(false);
    setShowSaveModal(false);
  };

  const onCloseSaveModal = () => {
    setEmailContent(emailContent);
    setBlockOptions(blockOptions);
    setSelectedBlock(selectedBlock);
    setSelectedBlockOptions(selectedBlockOptions);
    setSelectedBlockKey(selectedBlockKey);
    setModalContent("");
    setShowModal(false);
    setShowSaveModal(false);
  };

  const onVisiblePaneChange = (newVisiblePane) => {
    setVisiblePane(newVisiblePane);
  };

  const onClickTemplate = (template) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const block of template.blocks) {
      onClickWidget(block);
    }
  };

  if (loading) {
    return (
      <div className="cs-absolute cs-inset-0 cs-flex cs-h-full cs-w-full cs-items-center cs-justify-center">
        <div className="cs-spinner" role="status">
          <span className="cs-sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  console.log("extraHeader", extraHeader);
  console.log("masterExtraHeader", masterExtraHeader);
  return (
    <>
      <Helmet>
        <link href={css} rel="stylesheet" type="text/css" />
        {/* TODO THIS NEEDS MOVING INTO THE USER SETTINGS  */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap"
          rel="stylesheet"
        />
        <script src="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.js"></script>
        <script src="https://lp.aptean.com/rs/181-TRF-125/images/landing-page-swiper.js"></script>
        <script src="https://lp.aptean.com/rs/181-TRF-125/images/landing-page.js"></script>
        {/* {masterExtraHeader}
        {extraHeader} */}
      </Helmet>
      <BuilderTemplate
        type={type}
        navPane={
          <NavPane
            onShowModal={onShowModal}
            onSaveTemplate={save}
            onVisiblePaneChange={onVisiblePaneChange}
          />
        }
        brandGuidelines={brandGuidelines}
        onVisiblePaneChange={onVisiblePaneChange}
        blocksPane={
          <BlocksPane
            onClick={onClickWidget}
            onVisiblePaneChange={onVisiblePaneChange}
          />
        }
        templatesPane={<TemplatesPane onClick={onClickTemplate} />}
        canvasPane={
          <CanvasPane
            emailContent={emailContent}
            onContentChange={onContentChange}
            selectedBlockKey={selectedBlockKey}
            selectedBlock={selectedBlock}
            onBlockClick={onBlockClick}
            onOrderChange={onOrderChange}
            brandGuidelines={brandGuidelines}
            blockPath={blockPath}
            type={type}
          />
        }
        emailContent={emailContent}
        toolbars={<TextToolbar />}
        selectedBlock={selectedBlock}
        selectedBlockOptions={selectedBlockOptions}
        onOptionUpdate={onOptionUpdate}
        onDeleteBlock={onDeleteBlock}
        showModal={showModal}
        onShowModal={onShowModal}
        onSaveTemplate={save}
        onCloseModal={onCloseModal}
        modalContent={modalContent}
        visiblePane={visiblePane}
        showSaveModal={showSaveModal}
        onCloseSaveModal={onCloseSaveModal}
        showSettingsModal={showSettingsModal}
        onShowSettingsModal={onShowSettingsModal}
        onCloseSettingsModal={onCloseSettingsModal}
        saving={saving}
        name={name}
        updatedAt={updatedAt}
        title={title}
        favicon={favicon}
        extraFooter={extraFooter}
        extraHeader={extraHeader}
        setName={setName}
        setType={setType}
        setTitle={setTitle}
        setFavicon={setFavicon}
        setExtraHeader={setExtraHeader}
        setExtraFooter={setExtraFooter}
      />
    </>
  );
};

export default BuilderPage;
