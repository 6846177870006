import React from "react";

class TemplatesPane extends React.Component {
  renderTemplate = (template) => {
    const { onClick } = this.props;
    return <div onClick={() => onClick(template)}>{template.name}</div>;
  };

  render() {
    return (
      <div>
        <h2>Templates</h2>
      </div>
    );
  }
}

export default TemplatesPane;
