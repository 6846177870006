// https://github.com/diegohaz/arc/wiki/Atomic-Design test
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { apiRequest } from "../helper/api";
import useErrorHandler from "../hooks/errorHandler";
import { authContext } from "../contexts/AuthContext";

import { PageTemplate } from "../components";
import TitleBar from "../components/TitleBar";
import ErrorMessage from "../components/SystemMessages/ErrorMessage";

const NewTemplate = () => {
  const [, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("email");
  const [templateCss, setTemplateCss] = useState("");

  const auth = useContext(authContext);
  const user = auth.getAuthStatus();

  const { css } = user;

  const { error, showError, showPersistantError } = useErrorHandler(null);

  const history = useHistory();

  const submitForm = async (event) => {
    event.preventDefault();

    if (name === "") {
      showError("Please enter a name for this template.");
      return false;
    }

    try {
      setLoading(true);
      const payload = {
        name,
        userId: user._id,
        content: {},
        blockOptions: {},
        type,
        css: templateCss,
      };
      const response = await apiRequest("email", "POST", payload);
      if (response.success) {
        setLoading(false);
        setName("");
        history.push("/templates");
      } else {
        setLoading(false);
        const { errorCode } = response;
        if (errorCode === 401) {
          history.replace("/login");
        } else {
          showError("Unable to create new template: " + response.error);
        }
      }
    } catch (e) {
      showPersistantError(e);
      setLoading(false);
    }
  };

  const handleChangeInputName = async (event) => {
    const name = event.target.value;
    setName(name);
  };

  const handleChangeInputType = async (event) => {
    const type = event.target.value;
    setType(type);
    console.log("type", type);
  };

  return (
    <PageTemplate titleBar={<TitleBar title="New Template" />}>
      <div className="cs-card">
        <div className="cs-card-content">
          <form onSubmit={submitForm} className="cs-space-y-8">
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="templateName">Template Name:</label>
              </div>
              <div>
                <input
                  type="text"
                  name="templateName"
                  id="templateName"
                  value={name}
                  onChange={handleChangeInputName}
                  placeholder="Enter a name..."
                />
              </div>
            </div>
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="templateType">Template Type:</label>
              </div>
              <div className="cs-flex cs-flex-col cs-gap-2">
                <div className="cs-inline-flex cs-items-center cs-space-x-2">
                  <label
                    className="cs-relative cs-flex cs-cursor-pointer cs-items-center cs-rounded-full"
                    htmlFor="templateTypeEmail"
                  >
                    <input
                      type="radio"
                      name="templateType"
                      value="email"
                      onChange={handleChangeInputType}
                      id="templateTypeEmail"
                      className="cs-peer cs-relative cs-h-5 cs-w-5 cs-cursor-pointer cs-appearance-none cs-rounded-full cs-border cs-border-grey-50 cs-text-grey-100 cs-transition-all checked:cs-border-accent checked:before:cs-bg-accent"
                    />
                    <span className="cs-pointer-events-none cs-absolute cs-left-2/4 cs-top-2/4 -cs-translate-x-2/4 -cs-translate-y-2/4 cs-text-accent cs-opacity-0 cs-transition-opacity peer-checked:cs-opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-3.5 cs-w-3.5"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <circle
                          data-name="ellipse"
                          cx="8"
                          cy="8"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="cs-mt-px cs-cursor-pointer cs-select-none cs-text-grey-50"
                    htmlFor="templateTypeEmail"
                  >
                    Email
                  </label>
                </div>
                <div className="cs-inline-flex cs-items-center cs-space-x-2">
                  <label
                    className="cs-relative cs-flex cs-cursor-pointer cs-items-center cs-rounded-full"
                    htmlFor="templateTypeLandingPage"
                  >
                    <input
                      type="radio"
                      name="templateType"
                      value="landingpage"
                      onChange={handleChangeInputType}
                      id="templateTypeLandingPage"
                      className="cs-peer cs-relative cs-h-5 cs-w-5 cs-cursor-pointer cs-appearance-none cs-rounded-full cs-border cs-border-grey-50 cs-text-grey-100 cs-transition-all checked:cs-border-accent checked:before:cs-bg-accent"
                    />
                    <span className="cs-pointer-events-none cs-absolute cs-left-2/4 cs-top-2/4 -cs-translate-x-2/4 -cs-translate-y-2/4 cs-text-accent cs-opacity-0 cs-transition-opacity peer-checked:cs-opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-3.5 cs-w-3.5"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <circle
                          data-name="ellipse"
                          cx="8"
                          cy="8"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="cs-mt-px cs-cursor-pointer cs-select-none cs-text-grey-50"
                    htmlFor="templateTypeLandingPage"
                  >
                    Landing Page
                  </label>
                </div>
              </div>
            </div>
            {type === "landingpage" && (
              <div className="cs-space-y-2">
                <div>
                  <label htmlFor="templateTypeCss">Select a CSS File:</label>
                </div>
                <div className="cs-flex cs-flex-col cs-gap-2">
                  {css !== undefined && css.length > 0
                    ? css.map((c, idx) => {
                        const { name, value, description } = c;
                        return (
                          <div className="cs-inline-flex cs-items-center cs-space-x-2">
                            <label
                              className="cs-relative cs-flex cs-cursor-pointer cs-items-center cs-rounded-full"
                              htmlFor="templateTypeCss"
                            >
                              <input
                                type="radio"
                                name="templateTypeCss"
                                value={value}
                                onChange={(e) => {
                                  setTemplateCss(e.target.value);
                                }}
                                id="templateTypeCss"
                                className="cs-peer cs-relative cs-h-5 cs-w-5 cs-cursor-pointer cs-appearance-none cs-rounded-full cs-border cs-border-grey-50 cs-text-grey-100 cs-transition-all checked:cs-border-accent checked:before:cs-bg-accent"
                              />
                              <span className="cs-pointer-events-none cs-absolute cs-left-2/4 cs-top-2/4 -cs-translate-x-2/4 -cs-translate-y-2/4 cs-text-accent cs-opacity-0 cs-transition-opacity peer-checked:cs-opacity-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cs-h-3.5 cs-w-3.5"
                                  viewBox="0 0 16 16"
                                  fill="currentColor"
                                >
                                  <circle
                                    data-name="ellipse"
                                    cx="8"
                                    cy="8"
                                    r="8"
                                  ></circle>
                                </svg>
                              </span>
                            </label>
                            <label
                              className="cs-mt-px cs-cursor-pointer cs-select-none cs-text-grey-50"
                              htmlFor="templateTypeCss"
                            >
                              {name}{" "}
                              {description !== "" && (
                                <em> - ({description})</em>
                              )}
                            </label>
                          </div>
                        );
                      })
                    : false}
                </div>
              </div>
            )}
            {error ? <ErrorMessage errorMessage={error} /> : null}
            <div>
              <button type="submit">Create Template</button>
            </div>
          </form>
        </div>
      </div>
    </PageTemplate>
  );
};

export default NewTemplate;
