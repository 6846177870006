// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import BlockOptions from "../../modules/builder/BlockOptions";
import Modal from "../../elements/Modal";
import ModalSave from "../../elements/ModalSave";

import { Link } from "react-router-dom";

import { PANE_BLOCKS, PANE_OPTIONS } from "../../../Constants";
import ModalSettings from "../../elements/ModalSettings";

const BuilderTemplate = (props) => {
  const renderPane = (visiblePane) => {
    const {
      blocksPane,
      selectedBlock,
      selectedBlockOptions,
      onDeleteBlock,
      onOptionUpdate,
      onVisiblePaneChange,
    } = props;
    if (visiblePane === PANE_BLOCKS) {
      return (
        <div>
          <div className="cs-h-full cs-w-72 cs-border-r cs-border-grey-200 cs-bg-grey-300 xl:cs-w-96">
            <div className="cs-flex cs-h-16 cs-items-center cs-space-x-4 cs-border-b cs-border-grey-200 cs-p-4">
              <div className="cs-font-semibold cs-text-base cs-text-white">
                Module Library
              </div>
            </div>
            <div className="cs-flex cs-h-[calc(100vh-8rem)] cs-flex-col">
              <nav className="cs-flex cs-flex-col cs-overflow-y-auto">
                {blocksPane}
              </nav>
            </div>
          </div>
        </div>
      );
    } else if (visiblePane === PANE_OPTIONS) {
      return (
        <BlockOptions
          block={selectedBlock}
          selectedBlockOptions={selectedBlockOptions}
          onDeleteBlock={onDeleteBlock}
          onOptionUpdate={onOptionUpdate}
          onVisiblePaneChange={onVisiblePaneChange}
        />
      );
    }
    return null;
  };

  const {
    emailContent,
    canvasPane,
    showModal,
    onCloseModal,
    modalContent,
    visiblePane,
    onSaveTemplate,
    onShowModal,
    showSaveModal,
    showSettingsModal,
    onShowSettingsModal,
    onCloseSettingsModal,
    onCloseSaveModal,
    name,
    updatedAt,
    type,
    title,
    favicom,
    extraHeader,
    extraFooter,
    setName,
    setType,
    setTitle,
    setFavicon,
    setExtraHeader,
    setExtraFooter,
  } = props;

  const pageTypeCss =
    type === "landingpage"
      ? "cs-mx-auto cs-overflow-y-auto cs-w-full cs-h-full cs-bg-grey-500"
      : "cs-mx-auto cs-overflow-y-auto cs-w-[620px] cs-bg-grey-500 cs-border-l cs-border-r cs-border-t cs-border-grey-400 cs-p-4 cs-rounded-tl-xl cs-rounded-tr-xl";

  return (
    <React.Fragment>
      <div>
        <div className="cs-fixed cs-bottom-0 cs-left-0 cs-top-0 cs-w-26 cs-border-r cs-border-grey-200 cs-bg-grey-300">
          <nav className="cs-flex cs-h-full cs-flex-col cs-justify-between cs-space-y-4">
            <div className="cs-px-6">
              <div className="cs-flex cs-h-26 cs-items-center cs-justify-center cs-border-b cs-border-grey-200">
                <Link
                  to="/templates"
                  className="cs-flex cs-h-14 cs-w-14 cs-items-center cs-justify-center cs-rounded-xl cs-bg-accent cs-outline-none cs-transition-colors cs-duration-200 focus-visible:cs-bg-accent focus-visible:cs-ring-2 focus-visible:cs-ring-accent focus-visible:cs-ring-offset-2 focus-visible:cs-ring-offset-grey-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118.82 125.91"
                    className="cs-h-7 cs-w-7 cs-text-white"
                  >
                    <g>
                      <path
                        fill="currentColor"
                        d="M38.67,71.33,60,80V68.35L31,56.63l-8.63-3.5L40.06,46h0l4.63-1.88L60,17.64h0l14.6,28.29L90.93,52,63.19,1.94a3.77,3.77,0,0,0-6.56-.06L37.22,35.51l0,.09-34.81,14a3.77,3.77,0,0,0,0,7h0l29.1,11.78Z"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M113.72,69,86.4,57.93l-5.35-2.16L60,47.23V58.29L85.05,69l11.29,4.58-16,6.47-.05,0-5.42,2.2,0,.07-7.5,13-1.21,2.11-.34.58L60,108.08l-15.36-26L28.21,76l28.47,48.08a3.77,3.77,0,0,0,6.5,0L82.11,91.24l34.37-14.15a3.76,3.76,0,0,0,0-7Z"
                      ></path>
                    </g>
                  </svg>
                </Link>
              </div>
              <div className="cs-py-6">
                <ul className="cs-space-y-3">
                  <li>
                    <Link
                      to="/templates"
                      className="cs-flex cs-h-14 cs-w-14 cs-items-center cs-justify-center cs-rounded-xl cs-bg-grey-300 cs-text-grey-50 cs-outline-none cs-transition-colors cs-duration-200 hover:cs-bg-grey-200 hover:cs-text-white focus:cs-bg-grey-200 focus-visible:cs-bg-grey-100 focus-visible:cs-text-white focus-visible:cs-ring-2 focus-visible:cs-ring-grey-100 focus-visible:cs-ring-offset-2 focus-visible:cs-ring-offset-grey-300"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-7 cs-w-7 cs-text-white"
                      >
                        <path
                          d="M20.0001 8.00001L14.0001 2.74001C13.4501 2.24805 12.738 1.97607 12.0001 1.97607C11.2622 1.97607 10.5501 2.24805 10.0001 2.74001L4.00009 8.00001C3.68246 8.28408 3.42899 8.63256 3.25657 9.02225C3.08414 9.41194 2.99671 9.83389 3.00009 10.26V19C3.00009 19.7957 3.31617 20.5587 3.87877 21.1213C4.44138 21.6839 5.20445 22 6.00009 22H18.0001C18.7957 22 19.5588 21.6839 20.1214 21.1213C20.684 20.5587 21.0001 19.7957 21.0001 19V10.25C21.0021 9.82557 20.9139 9.40555 20.7416 9.01769C20.5692 8.62983 20.3165 8.28296 20.0001 8.00001ZM14.0001 20H10.0001V15C10.0001 14.7348 10.1055 14.4804 10.293 14.2929C10.4805 14.1054 10.7349 14 11.0001 14H13.0001C13.2653 14 13.5197 14.1054 13.7072 14.2929C13.8947 14.4804 14.0001 14.7348 14.0001 15V20ZM19.0001 19C19.0001 19.2652 18.8947 19.5196 18.7072 19.7071C18.5197 19.8946 18.2653 20 18.0001 20H16.0001V15C16.0001 14.2044 15.684 13.4413 15.1214 12.8787C14.5588 12.3161 13.7957 12 13.0001 12H11.0001C10.2044 12 9.44138 12.3161 8.87877 12.8787C8.31616 13.4413 8.00009 14.2044 8.00009 15V20H6.00009C5.73488 20 5.48052 19.8946 5.29299 19.7071C5.10545 19.5196 5.00009 19.2652 5.00009 19V10.25C5.00027 10.108 5.03069 9.9677 5.08931 9.83839C5.14794 9.70907 5.23343 9.59372 5.3401 9.50001L11.3401 4.25001C11.5226 4.08969 11.7572 4.00127 12.0001 4.00127C12.243 4.00127 12.4776 4.08969 12.6601 4.25001L18.6601 9.50001C18.7668 9.59372 18.8523 9.70907 18.9109 9.83839C18.9695 9.9677 18.9999 10.108 19.0001 10.25V19Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/account"
                      className="cs-flex cs-h-14 cs-w-14 cs-items-center cs-justify-center cs-rounded-xl cs-bg-grey-300 cs-text-grey-50 cs-outline-none cs-transition-colors cs-duration-200 hover:cs-bg-grey-200 hover:cs-text-white focus:cs-bg-grey-200 focus-visible:cs-bg-grey-100 focus-visible:cs-text-white focus-visible:cs-ring-2 focus-visible:cs-ring-grey-100 focus-visible:cs-ring-offset-2 focus-visible:cs-ring-offset-grey-300"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-7 cs-w-7"
                      >
                        <path
                          d="M15.71 12.71C16.6904 11.9387 17.406 10.8809 17.7572 9.68394C18.1085 8.48697 18.0779 7.21027 17.6698 6.03147C17.2617 4.85267 16.4963 3.83039 15.4801 3.10686C14.4639 2.38332 13.2474 1.99451 12 1.99451C10.7525 1.99451 9.53611 2.38332 8.51993 3.10686C7.50374 3.83039 6.73834 4.85267 6.33021 6.03147C5.92208 7.21027 5.89151 8.48697 6.24276 9.68394C6.59401 10.8809 7.3096 11.9387 8.29 12.71C6.61007 13.383 5.14428 14.4994 4.04889 15.9399C2.95349 17.3805 2.26956 19.0913 2.07 20.89C2.05555 21.0213 2.06711 21.1542 2.10402 21.2811C2.14093 21.4079 2.20246 21.5263 2.28511 21.6293C2.45202 21.8375 2.69478 21.9708 2.96 22C3.22521 22.0292 3.49116 21.9518 3.69932 21.7849C3.90749 21.618 4.04082 21.3752 4.07 21.11C4.28958 19.1552 5.22168 17.3498 6.68822 16.0388C8.15475 14.7278 10.0529 14.003 12.02 14.003C13.9871 14.003 15.8852 14.7278 17.3518 16.0388C18.8183 17.3498 19.7504 19.1552 19.97 21.11C19.9972 21.3557 20.1144 21.5827 20.2991 21.747C20.4838 21.9114 20.7228 22.0015 20.97 22H21.08C21.3421 21.9698 21.5817 21.8373 21.7466 21.6313C21.9114 21.4252 21.9881 21.1624 21.96 20.9C21.7595 19.0962 21.0719 17.381 19.9708 15.9382C18.8698 14.4954 17.3969 13.3795 15.71 12.71ZM12 12C11.2089 12 10.4355 11.7654 9.77772 11.3259C9.11992 10.8864 8.60723 10.2616 8.30448 9.53074C8.00173 8.79983 7.92251 7.99557 8.07686 7.21964C8.2312 6.44372 8.61216 5.73099 9.17157 5.17158C9.73098 4.61217 10.4437 4.2312 11.2196 4.07686C11.9956 3.92252 12.7998 4.00173 13.5307 4.30448C14.2616 4.60724 14.8863 5.11993 15.3259 5.77772C15.7654 6.43552 16 7.20888 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cs-px-6">
              <div className="cs-py-6">
                <ul className="cs-space-y-3">
                  <li>
                    <Link
                      to="/logout"
                      className="cs-flex cs-h-14 cs-w-14 cs-items-center cs-justify-center cs-rounded-xl cs-bg-grey-300 cs-text-grey-50 cs-outline-none cs-transition-colors cs-duration-200 hover:cs-bg-grey-200 hover:cs-text-white focus:cs-bg-grey-200 focus-visible:cs-bg-grey-100 focus-visible:cs-text-white focus-visible:cs-ring-2 focus-visible:cs-ring-grey-100 focus-visible:cs-ring-offset-2 focus-visible:cs-ring-offset-grey-300"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-7 cs-w-7"
                      >
                        <path
                          d="M4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H12.59L10.29 15.29C10.1963 15.383 10.1219 15.4936 10.0711 15.6154C10.0203 15.7373 9.9942 15.868 9.9942 16C9.9942 16.132 10.0203 16.2627 10.0711 16.3846C10.1219 16.5064 10.1963 16.617 10.29 16.71C10.383 16.8037 10.4936 16.8781 10.6154 16.9289C10.7373 16.9797 10.868 17.0058 11 17.0058C11.132 17.0058 11.2627 16.9797 11.3846 16.9289C11.5064 16.8781 11.617 16.8037 11.71 16.71L15.71 12.71C15.801 12.6149 15.8724 12.5028 15.92 12.38C16.02 12.1365 16.02 11.8635 15.92 11.62C15.8724 11.4972 15.801 11.3851 15.71 11.29L11.71 7.29C11.6168 7.19676 11.5061 7.1228 11.3842 7.07234C11.2624 7.02188 11.1319 6.99591 11 6.99591C10.8681 6.99591 10.7376 7.02188 10.6158 7.07234C10.4939 7.1228 10.3832 7.19676 10.29 7.29C10.1968 7.38324 10.1228 7.49393 10.0723 7.61575C10.0219 7.73757 9.99591 7.86814 9.99591 8C9.99591 8.13186 10.0219 8.26243 10.0723 8.38425C10.1228 8.50607 10.1968 8.61676 10.29 8.71L12.59 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12ZM17 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 4.73478 9 5 9C5.26522 9 5.51957 8.89464 5.70711 8.70711C5.89464 8.51957 6 8.26522 6 8V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V16C6 15.7348 5.89464 15.4804 5.70711 15.2929C5.51957 15.1054 5.26522 15 5 15C4.73478 15 4.48043 15.1054 4.29289 15.2929C4.10536 15.4804 4 15.7348 4 16V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="cs-absolute cs-bottom-0 cs-left-26 cs-right-0 cs-top-0 cs-overflow-y-hidden cs-bg-grey-500">
          <div className="cs-fixed cs-left-26 cs-right-0 cs-top-0 cs-flex cs-h-26 cs-flex-grow cs-flex-row cs-items-center cs-border-b cs-border-grey-200 cs-bg-grey-300">
            <div className="cs-flex cs-flex-grow cs-p-6">
              <div className="cs-flex cs-space-x-4">
                <div className="cs-flex cs-items-center cs-justify-center">
                  <Link
                    to="/templates"
                    className="cs-flex cs-h-12 cs-w-12 cs-items-center cs-justify-center cs-rounded-xl cs-bg-grey-400 cs-transition-colors cs-duration-200 hover:cs-bg-grey-400"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cs-h-8 cs-w-8 cs-text-white"
                    >
                      <path
                        d="M6.08 11.62C6.12759 11.4973 6.19896 11.3851 6.29 11.29L11.29 6.29C11.3832 6.19676 11.4939 6.1228 11.6158 6.07234C11.7376 6.02188 11.8681 5.99591 12 5.99591C12.2663 5.99591 12.5217 6.1017 12.71 6.29C12.8032 6.38324 12.8772 6.49393 12.9277 6.61575C12.9781 6.73758 13.0041 6.86814 13.0041 7C13.0041 7.2663 12.8983 7.5217 12.71 7.71L9.41 11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12C18 12.2652 17.8946 12.5196 17.7071 12.7071C17.5196 12.8946 17.2652 13 17 13H9.41L12.71 16.29C12.8037 16.383 12.8781 16.4936 12.9289 16.6154C12.9797 16.7373 13.0058 16.868 13.0058 17C13.0058 17.132 12.9797 17.2627 12.9289 17.3846C12.8781 17.5064 12.8037 17.617 12.71 17.71C12.617 17.8037 12.5064 17.8781 12.3846 17.9289C12.2627 17.9797 12.132 18.0058 12 18.0058C11.868 18.0058 11.7373 17.9797 11.6154 17.9289C11.4936 17.8781 11.383 17.8037 11.29 17.71L6.29 12.71C6.19896 12.6149 6.12759 12.5028 6.08 12.38C5.97998 12.1365 5.97998 11.8635 6.08 11.62Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="cs-flex cs-flex-col cs-items-start cs-justify-center">
                  <div className="cs-text-lg cs-font-medium cs-text-white">
                    {name}
                  </div>
                  <div className="cs-text-xs cs-text-grey-50">
                    {`Updated: ${moment(updatedAt).format("DD/MM/YYYY HH:mm")}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="cs-flex">
              <div className="cs-flex cs-flex-grow cs-items-center cs-justify-end cs-space-x-4 cs-p-4">
                <button
                  className="cs-btn-secondary"
                  onClick={() => onSaveTemplate()}
                >
                  Save
                </button>
                <button
                  className="cs-btn-primary"
                  onClick={() => onShowModal()}
                >
                  Export
                </button>
              </div>
            </div>
          </div>
          <div className="cs-flex cs-h-screen cs-pt-26">
            {renderPane(visiblePane)}
            <div className="cs-flex cs-flex-grow cs-flex-col cs-px-6 cs-pb-6 cs-pt-6">
              <div className={pageTypeCss} emailcontent={emailContent}>
                {canvasPane}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalSettings
        show={showSettingsModal}
        type={type}
        name={name}
        title={title}
        favicom={favicom}
        extraHeader={extraHeader}
        extraFooter={extraFooter}
        onCloseModal={onCloseSettingsModal}
        onSaveTemplate={onSaveTemplate}
        setName={setName}
        setType={setType}
        setTitle={setTitle}
        setFavicon={setFavicon}
        setExtraHeader={setExtraHeader}
        setExtraFooter={setExtraFooter}
      >
        {modalContent}
      </ModalSettings>
      <Modal show={showModal} onCloseModal={onCloseModal}>
        {modalContent}
      </Modal>
      <ModalSave show={showSaveModal} onCloseModal={onCloseSaveModal}>
        {modalContent}
      </ModalSave>
    </React.Fragment>
  );
};

BuilderTemplate.propTypes = {
  blocksPane: PropTypes.node.isRequired,
  templatesPane: PropTypes.node.isRequired,
  canvasPane: PropTypes.node.isRequired,
  selectedBlock: PropTypes.object,
  onOptionUpdate: PropTypes.func,
  onDeleteBlock: PropTypes.func,
  selectedBlockOptions: PropTypes.object,
  showModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  emailContent: PropTypes.array,
  onContentChange: PropTypes.func,
  modalContent: PropTypes.string,
  visiblePane: PropTypes.string,
  saving: PropTypes.bool,
};

export default BuilderTemplate;
