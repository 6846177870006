import React from "react";

const ErrorMessage = (props) => {
  const { errorMessage } = props;
  return (
    <div
      className="cs-bg-red-600 cs-flex cs-h-11 cs-items-center cs-justify-center cs-rounded-lg cs-px-4 cs-text-base cs-font-medium cs-text-grey-50"
      role="alert"
    >
      {errorMessage}
    </div>
  );
};

export default ErrorMessage;
