import React from "react";
import "../../../../components/themes/feedmeonline.css";

const Header1 = (props) => {
  const { ctaUrl, blockKey, brandGuidelines } = props;
  const { primaryLogo, primaryColours } = brandGuidelines;

  return (
    <>
      <header key={blockKey} className="bg-white py-6 lg:py-12">
        <div className="px-4 lg:container lg:mx-auto">
          <div>
            <a
              href={ctaUrl}
              onClick={(e) => e.preventDefault()}
              target="_blank"
            >
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="112"
                height="30"
                viewBox="0 0 715 129"
              >
                <g clip-path="url(#clip0_226_2)">
                  <path
                    d="M308.8 89.7C294.8 72.7 292.8 60.7 296.8 36.7C296.8 36.7 295.8 37.7 296 37.3C293.7 39.8 291.5 42.5 289.7 45.4C273.6 70.7 281 104.3 306.3 120.4C319 128.5 333.7 130.7 347.4 127.6C347 127.7 349.9 127 349 126.7C326.8 118.7 313.8 107.7 308.8 89.7Z"
                    fill="#F0E903"
                  ></path>
                  <path
                    d="M399.4 38.3C398.7 37.7 397.7 37.7 397.7 37.7C401.7 60.7 395.7 80.7 384.7 89.7C379.7 104.7 370.7 117.7 346.7 125.7C346.7 125.7 346.7 127.7 347.1 127.6C368.8 132.5 392.1 123.6 404.8 103.8C418.3 82.8 415.4 56 399.4 38.3Z"
                    fill="#DD03E3"
                  ></path>
                  <path
                    d="M346.8 23.7C364.8 18.7 386.8 27.7 399.8 40.7C400.3 41.2 399 37.7 399.5 38.3C395.8 26.4 388.1 15.7 376.8 8.5C351.5 -7.6 317.9 -0.199999 301.8 25.1C299.3 29 297.4 33.1 296 37.3C296.8 37.7 296.8 38.7 296.8 38.7C309.9 27 329.8 19.9 346.8 23.7Z"
                    fill="#03E0DA"
                  ></path>
                  <path
                    d="M381.2 103.9C383.7 100 385.6 95.9 387 91.7C386.8 91.8 385.8 90.8 385.8 90.8C366.8 112.8 329.8 111.8 308.8 90.8C308.8 90.8 307.8 89.8 307.2 90.8C310.9 102.7 318.6 113.4 329.9 120.6C335.4 124.1 341.3 126.5 347.2 127.8C360.7 124.7 373.1 116.5 381.2 103.9Z"
                    fill="#E30505"
                  ></path>
                  <path
                    d="M388.3 28.9C375.6 20.8 360.9 18.6 347.2 21.7C346.7 21.8 346.7 22.8 346.7 22.8C377.9 31.1 394.7 59.8 385.7 90.8C385.7 90.8 387.5 89.9 386.9 91.7C389.2 89.2 391.4 86.5 393.2 83.6C402.1 69.6 403.9 53.1 399.3 38.3C396.2 34.8 392.5 31.6 388.3 28.9Z"
                    fill="#0503E3"
                  ></path>
                  <path
                    d="M307.2 90.7C306.8 89.4 309.1 90.1 308.8 88.7C301.8 54.7 321.8 32.7 346.8 23.7C349 22.9 345 22.1 347.3 21.6C328.9 17.5 309.2 23.2 296 37.3C289.8 55.8 294.2 76.3 307.2 90.7Z"
                    fill="#21D95F"
                  ></path>
                  <path
                    d="M364.6 28.9C359.1 25.4 353.2 23 347.3 21.7C333.8 24.7 321.4 32.9 313.3 45.5C304.4 59.5 302.6 76 307.2 90.8C310.4 94.3 314.1 97.5 318.3 100.2C340.6 114.5 369.4 110.3 387 91.8C394.6 68.7 386 42.5 364.6 28.9Z"
                    fill="black"
                  ></path>
                  <path
                    d="M22.9 30.4V52.7H62.3V75.6H22.9V99.2H68.8V122H0V7.8H68.8V30.4H22.9Z"
                    fill="black"
                  ></path>
                  <path
                    d="M181.4 7.3V121.8H180.3L112.6 58V121.9H89.6V7.5H90.7L158.3 71.3V7.3H181.4Z"
                    fill="black"
                  ></path>
                  <path
                    d="M226.1 30.2V53.2H265.5V76.1H226.1V121.9H203.2V7.3H272V30.2H226.1Z"
                    fill="black"
                  ></path>
                  <path
                    d="M423.4 64.5C423.4 33.3 449.7 6.9 480.8 6.9C495.2 7.1 508 12.8 519.6 24L506.8 41.5C499.3 34.1 490.2 29.6 480.7 29.6C462.9 29.6 446 45.4 446 64.7C446 84.4 463.6 99.6 480.7 99.6C490.9 99.6 499.6 94.2 507 87L519.8 105C510.1 114.7 498.2 121.9 481.4 121.9C449.3 121.9 423.4 95.6 423.4 64.5Z"
                    fill="black"
                  ></path>
                  <path
                    d="M536 78.2V7.9H558.6V78.2C558.6 90.4 568.2 99.2 579 99.2C589.8 99.2 599.6 90.3 599.6 78.2V7.9H622.2V78.2C622.2 103.5 602.9 121.9 579.1 121.9C555.3 121.9 536 103.5 536 78.2Z"
                    fill="black"
                  ></path>
                  <path
                    d="M635.2 100.6L653 88.2C657.9 96 664.9 99.6 674.5 99.6C684.9 99.6 691.9 95.4 691.9 89.5C691.9 81.4 678.7 75 667.1 69.3C654.7 63.1 641.1 56.3 641.1 38.7C641.1 19.6 657.3 7 677.6 7C692.7 7 705.1 13.2 710.2 26.6L693.8 38C690.5 31.4 684.1 28.7 678 28.7C669.7 28.7 663.5 33.5 663.5 39.2C663.5 45.8 671.9 48 684.5 54C702.8 62.6 714.3 72 714.3 89C714.3 109.1 697.9 122 674.2 122C656 121.9 642.6 114.4 635.2 100.6Z"
                    fill="black"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_226_2">
                    <rect width="714.2" height="129" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header1;
