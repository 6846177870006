import React from "react";
import { Link } from "react-router-dom";

const TitleBarWithButton = (props) => {
  const { title, buttonLink, buttonText } = props;
  return (
    <>
      <div className="cs-px-6">
        <h1 className="cs-text-lg cs-font-medium cs-text-white">{title}</h1>
      </div>
      <div className="cs-flex cs-flex-grow">
        <div className="cs-flex cs-flex-grow cs-items-center cs-justify-end cs-p-6">
          <Link to={buttonLink} className="cs-btn-primary">
            {buttonText}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TitleBarWithButton;
