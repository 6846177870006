import React from "react";
import styled from "styled-components";
import { size } from "styled-theme";

import { Block } from "../../../../components";

const Wrapper = styled(Block)`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${size("maxWidth")};
  > :not(:first-child) {
    margin-left: 1rem;
  }
`;

class TextToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorLink: "",
    };
  }

  runCommand = (command) => {
    // TODO Add some checking for browser compatibility
    document.execCommand(command);
  };

  createLink = () => {
    const { anchorLink } = this.state;
    document.execCommand("createLink", null, anchorLink);
    this.setState({
      anchorLink: "",
    });
  };

  updateAnchorLinkValue(evt) {
    this.setState({
      anchorLink: evt.target.value,
    });
  }

  render() {
    const { anchorLink } = this.state;
    return (
      <Wrapper>
        <InnerWrapper>
          <button onClick={() => this.runCommand("bold")}>Bold</button>
          <button onClick={() => this.runCommand("italic")}>Italic</button>
          <button onClick={() => this.runCommand("underline")}>
            Underline
          </button>
          <button onClick={() => this.runCommand("undo")}>Undo</button>
          <input
            type="text"
            id="anchorLink"
            placeholder="Enter Url For Link"
            value={anchorLink}
            onChange={(evt) => this.updateAnchorLinkValue(evt)}
          />
          <button onClick={() => this.createLink()}>Create Link</button>
          <button onClick={() => this.runCommand("unlink")}>Remove Link</button>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default TextToolbar;
