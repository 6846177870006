import { DEFAULT_USER } from "../Constants";

const getStoredUserAuth = () => {
  const auth = window.localStorage.getItem("userAuth");
  if (auth) {
    return JSON.parse(auth);
  }
  return DEFAULT_USER;
};

export { getStoredUserAuth };
