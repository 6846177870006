import { API_URL } from "../Constants";

const apiRequest = async (url, method, bodyParams) => {
  const response = await fetch(API_URL + url, {
    method,
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: bodyParams ? JSON.stringify(bodyParams) : undefined,
  });
  // TODO add validation of authentication here then return the json with response.json()
  if (response.status === 200 || response.status === 201) {
    if (url !== "signin") {
      return await response.json();
    } else {
      return { status: true, errors: "" };
    }
  } else if (response.status === 401) {
    return { status: false, error: "You Are Not Logged In", errorCode: 401 };
  } else if (response.status === 404) {
    return { status: false, error: "Not Found", errorCode: 404 };
  } else {
    return { status: false, error: "An unknown server error occured" };
  }
};

export { apiRequest };
