import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Footer1 = (props) => {
  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange, brandGuidelines } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    blockKey,
    text,
    backgroundColor,
    socialLink1,
    socialLink2,
    socialLink3,
    socialLink4,
  } = props;

  return (
    <footer key={blockKey} className="cs-py-4 lg:cs-py-6 xl:cs-py-8">
      <div className="cs-px-4 md:cs-px-8 lg:cs-px-12 xl:cs-px-16">
        <div className="cs-flex cs-justify-between md:cs-grid md:cs-grid-cols-2">
          <div>
            <div className="cs-text-xs lg:cs-text-sm">
              <div
                className="mktoSnippet"
                id="module-footer--copyright-text"
                mktoname="Copyright Text"
              >
                <ContentEditable
                  html={text}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text", event);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="cs-flex cs-justify-start md:cs-justify-end">
            <div className="cs-text-xs lg:cs-text-sm">
              <div
                className="mktoSnippet"
                id="module-footer--privacy-text"
                mktoname="Privacy Text"
              >
                <a
                  href="https://www.aptean.com/en-US/privacy-statement"
                  target="_blank"
                >
                  Privacy Statement
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer1;
