import React, { Component } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prefer-stateless-function
export default class ModalSave extends Component {
  render() {
    const { show, onCloseModal } = this.props;
    const showHideClassName = show ? { display: "block" } : { display: "none" };

    const modalStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(43, 43, 43, 0.9)",
    };

    const modalMainStyle = {
      position: "fixed",
      background: "#2e343f",
      width: "50%",
      height: "500px",
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    };

    const modalCloseButtonStyle = {
      float: "left",
      marginRight: 15,
    };

    return (
      <div style={{ ...modalStyle, ...showHideClassName }}>
        <div style={modalMainStyle}>
          <div
            style={{
              backgroundColor: "#3a4858",
              color: "#ffffff",
              fontFamily: "Open Sans",
              fontWeight: "bold",
              paddingTop: 15,
              paddingBottom: 15,
              paddingLeft: 15,
            }}
          >
            Template Saved Successfully.
          </div>
          Your Email Has Been Saved Successfully.
          <div
            style={{
              backgroundColor: "#3a4858",
              color: "#ffffff",
              padding: 15,
              height: 66,
            }}
          >
            <button
              className="cs-btn-primary"
              style={modalCloseButtonStyle}
              type="button"
              onClick={() => onCloseModal()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ModalSave.propTypes = {
  show: PropTypes.bool,
  onCloseModal: PropTypes.func,
};
