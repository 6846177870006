import React from "react";

const TitleBar = (props) => {
  const { title } = props;
  return (
    <>
      <div className="cs-px-6">
        <h1 className="cs-text-lg cs-font-medium cs-text-white">{title}</h1>
      </div>
    </>
  );
};

export default TitleBar;
