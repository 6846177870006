import Navigation1 from "./navigation/navigation1/Navigation1";
import Header1 from "./header/header1/Header1";
import Header2 from "./header/header2/Header2";
import Header3 from "./header/header3/Header3";
import Text1 from "./text/text1/Text1";
import Text2 from "./text/text2/Text2";
import Text3 from "./text/text3/Text3";
import Text4 from "./text/text4/Text4";
import Text5 from "./text/text5/Text5";
import Image1 from "./image/image1/Image1";
import Image2 from "./image/image2/Image2";
import Image3 from "./image/image3/Image3";
import Image4 from "./image/image4/Image4";
import Image5 from "./image/image5/Image5";
import Image6 from "./image/image6/Image6";
import Feature1 from "./feature/feature1/Feature1";
import Feature2 from "./feature/feature2/Feature2";
import Feature3 from "./feature/feature3/Feature3";
import Feature4 from "./feature/feature4/Feature4";
import Feature5 from "./feature/feature5/Feature5";
import Feature6 from "./feature/feature6/Feature6";
import Feature7 from "./feature/feature7/Feature7";
import Feature8 from "./feature/feature8/Feature8";
import Cta1 from "./cta/cta1/Cta1";
import Footer1 from "./footer/footer1/Footer1";

const BlockLibrary = (brandGuidelines) => {
  const blockLibrary = {
    navigation: {
      name: "Navigation",
      blocks: {
        navigation1: {
          ComponentName: "Navigation1",
          id: "navigation1",
          title: "Navigation 1",
          category: "navigation",
          description: "A basic header",
          image: require("./navigation/navigation1/thumb.jpg"),
          component: Navigation1,
          optionValues: {
            ctaUrl: "https://aptean.com/",
          },
          options: {
            Logo: {
              ctaUrl: {
                label: "Link Address:",
                value: "https://aptean.com/",
              },
            },
          },
        },
      },
    },
    header: {
      name: "Header",
      blocks: {
        header1: {
          ComponentName: "Header1",
          id: "header1",
          title: "Header 1",
          category: "header",
          description: "A basic header",
          image: require("./header/header1/thumb.jpg"),
          component: Header1,
          editableContent: {
            title:
              "<h1>Ready to Tackle <strong>Fashion &amp; Apparel</strong></h1>",
            text: "<p>With labor constraints, rising costs, sustainability pressures and supply chain issues.</p>",
            formTitle: "<h3>Form Title Goes Here</h3>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            /* backgroundColor: brandGuidelines.primaryColours[1], */
            image: "https://placehold.co/448x568@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            /* "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[1],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.primaryColours[0],
                ],
              },
            }, */
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/448x568@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        header2: {
          ComponentName: "Header2",
          id: "header2",
          title: "Header 2",
          category: "header",
          description: "A basic header",
          image: require("./header/header2/thumb.jpg"),
          component: Header2,
          editableContent: {
            title1: "<h1>Ready to Tackle Fashion & Apparel Manufacturing</h1>",
            title2: "<h2>Welcome to Aptean's Annual Report</h2>",
            text1:
              "<p>With labor constraints, rising costs, sustainability pressures and supply chain issues.</p>",
            text2:
              "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
            formTitle: "<h3>Form Title Goes Here</h3>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image: "https://placehold.co/672x674@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/672x674@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        header3: {
          ComponentName: "Header3",
          id: "header3",
          title: "Header 3",
          category: "header",
          description: "A basic header",
          image: require("./header/header3/thumb.jpg"),
          component: Header3,
          editableContent: {
            title: "<h1>Ready to Tackle Fashion & Apparel Manufacturing</h1>",
            text: "<p>With labor constraints, rising costs, sustainability pressures and supply chain issues, 2023 is set to be a challenging time for fashion brands.</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image: "https://placehold.co/672x440@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/672x440@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
      },
    },
    text: {
      name: "Text",
      blocks: {
        text1: {
          ComponentName: "Text1",
          id: "text1",
          title: "Text 1",
          category: "text",
          description: "A basic text component",
          image: require("./text/text1/thumb.png"),
          component: Text1,
          editableContent: {
            title: "<h2>Welcome to Aptean's Annual Report</h2>",
            text: "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        text2: {
          ComponentName: "Text2",
          id: "text2",
          title: "Text 2",
          category: "text",
          description: "A basic text component",
          image: require("./text/text2/thumb.jpg"),
          component: Text2,
          editableContent: {
            title: "<h2>Welcome to Aptean's Annual Report</h2>",
            text: "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
            formTitle: "<h3>Form Title Goes Here</h3>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        text3: {
          ComponentName: "Text3",
          id: "text3",
          title: "Text 3",
          category: "text",
          description: "A basic text component",
          image: require("./text/text3/thumb.jpg"),
          component: Text3,
          editableContent: {
            title: "<h2>Welcome to Aptean's Annual Report</h2>",
            text: "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        text4: {
          ComponentName: "Text4",
          id: "text4",
          title: "Text 4",
          category: "text",
          description: "A basic text component",
          image: require("./text/text4/thumb.jpg"),
          component: Text4,
          editableContent: {
            title: "<h2>Welcome to Aptean's Annual Report</h2>",
            text: "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image: "https://placehold.co/580x560@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/580x560@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        text5: {
          ComponentName: "Text5",
          id: "text5",
          title: "Text 5",
          category: "text",
          description: "A basic text component",
          image: require("./text/text5/thumb.jpg"),
          component: Text5,
          editableContent: {
            title: "<h2>Welcome to Aptean's Annual Report</h2>",
            text: "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image: "https://placehold.co/580x560@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/580x560@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
      },
    },
    image: {
      name: "Image",
      blocks: {
        image1: {
          ComponentName: "Image1",
          id: "image1",
          title: "Image 1",
          category: "image",
          description: "A basic text component",
          image: require("./image/image1/thumb.jpg"),
          component: Image1,
          editableContent: {},
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image: "https://placehold.co/1536x384@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/1536x384@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        /* image2: {
          ComponentName: "Image2",
          id: "image2",
          title: "Image 2",
          category: "image",
          description: "A basic text component",
          image: require("./image/image2/thumb.jpg"),
          component: Image2,
          editableContent: {},
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image: "https://placehold.co/1536x384@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Image: {
              image: {
                label: "Image URL:",
                value: "https://placehold.co/1536x384@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        }, */
        image3: {
          ComponentName: "Image3",
          id: "image3",
          title: "Image 3",
          category: "image",
          description: "A basic text component",
          image: require("./image/image3/thumb.jpg"),
          component: Image3,
          editableContent: {},
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image1: "https://placehold.co/688x208@2x/EAEAEA/3A3A3A/png",
            image2: "https://placehold.co/688x208@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Images: {
              image1: {
                label: "Image 1 URL:",
                value: "https://placehold.co/688x208@2x/EAEAEA/3A3A3A/png",
              },
              image2: {
                label: "Image 2 URL:",
                value: "https://placehold.co/688x208@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        /* image4: {
          ComponentName: "Image4",
          id: "image4",
          title: "Image 4",
          category: "image",
          description: "A basic text component",
          image: require("./image/image4/thumb.jpg"),
          component: Image4,
          editableContent: {},
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        }, */
        image5: {
          ComponentName: "Image5",
          id: "image5",
          title: "Image 5",
          category: "image",
          description: "A basic text component",
          image: require("./image/image5/thumb.jpg"),
          component: Image5,
          editableContent: {},
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image1: "https://placehold.co/448x208@2x/EAEAEA/3A3A3A/png",
            image2: "https://placehold.co/448x208@2x/EAEAEA/3A3A3A/png",
            image3: "https://placehold.co/448x208@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Images: {
              image1: {
                label: "Image 1 URL:",
                value: "https://placehold.co/448x208@2x/EAEAEA/3A3A3A/png",
              },
              image2: {
                label: "Image 2 URL:",
                value: "https://placehold.co/448x208@2x/EAEAEA/3A3A3A/png",
              },
              image3: {
                label: "Image 3 URL:",
                value: "https://placehold.co/448x208@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        /*  image6: {
          ComponentName: "Image6",
          id: "image6",
          title: "Image 6",
          category: "image",
          description: "A basic text component",
          image: require("./image/image6/thumb.jpg"),
          component: Image6,
          editableContent: {},
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        }, */
      },
    },
    feature: {
      name: "Feature",
      blocks: {
        feature1: {
          ComponentName: "Feature1",
          id: "feature1",
          title: "Feature 1",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature1/thumb.jpg"),
          component: Feature1,
          editableContent: {
            title1: "<h3>Name</h3>",
            title2: "<h3>Name</h3>",
            title3: "<h3>Name</h3>",
            title4: "<h3>Name</h3>",
            text1: "<p>Title, Company</p>",
            text2: "<p>Title, Company</p>",
            text3: "<p>Title, Company</p>",
            text4: "<p>Title, Company</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image1: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
            image2: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
            image3: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
            image4: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Images: {
              image1: {
                label: "Image 1 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
              image2: {
                label: "Image 2 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
              image3: {
                label: "Image 3 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
              image4: {
                label: "Image 4 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        feature2: {
          ComponentName: "Feature2",
          id: "feature2",
          title: "Feature 2",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature2/thumb.jpg"),
          component: Feature2,
          editableContent: {
            title1: "<h3>Name</h3>",
            title2: "<h3>Name</h3>",
            title3: "<h3>Name</h3>",
            text1: "<p>Title, Company</p>",
            text2: "<p>Title, Company</p>",
            text3: "<p>Title, Company</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image1: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
            image2: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
            image3: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Images: {
              image1: {
                label: "Image 1 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
              image2: {
                label: "Image 2 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
              image3: {
                label: "Image 3 URL:",
                value: "https://placehold.co/328x328@2x/EAEAEA/3A3A3A/png",
              },
            },
          },
        },
        /* feature3: {
          ComponentName: "Feature3",
          id: "feature3",
          title: "Feature 3",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature3/thumb.jpg"),
          component: Feature3,
          editableContent: {
            title1: "<h3>Name</h3>",
            title2: "<h3>Name</h3>",
            title3: "<h3>Name</h3>",
            title4: "<h3>Name</h3>",
            title5: "<h3>Name</h3>",
            title6: "<h3>Name</h3>",
            text1: "<p>Title, Company</p>",
            text2: "<p>Title, Company</p>",
            text3: "<p>Title, Company</p>",
            text4: "<p>Title, Company</p>",
            text5: "<p>Title, Company</p>",
            text6: "<p>Title, Company</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        }, */
        feature4: {
          ComponentName: "Feature4",
          id: "feature4",
          title: "Feature 4",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature4/thumb.jpg"),
          component: Feature4,
          editableContent: {
            title1: "<h2>Additional Resources</h2>",
            title2: "<h3>Feature 1</h3>",
            title3: "<h3>Feature 2</h3>",
            title4: "<h3>Feature 3</h3>",
            text1:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text3:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text4:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        feature5: {
          ComponentName: "Feature5",
          id: "feature5",
          title: "Feature 5",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature5/thumb.jpg"),
          component: Feature5,
          editableContent: {
            title1: "<h2>Animated Flip Cards</h2>",
            title2: "<h3>Feature 1</h3>",
            title3: "<h3>Feature 2</h3>",
            title4: "<h3>Feature 3</h3>",
            title5: "<h3>Feature 1</h3>",
            title6: "<h3>Feature 2</h3>",
            title7: "<h3>Feature 3</h3>",
            text1:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text3:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text4:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text5:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text6:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text7:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        feature6: {
          ComponentName: "Feature6",
          id: "feature6",
          title: "Feature 6",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature6/thumb.jpg"),
          component: Feature6,
          editableContent: {
            title1: "<h2>Welcome to Aptean's Annual Report</h2>",
            title2: "<h3>Feature 1</h3>",
            title3: "<h3>Feature 2</h3>",
            title4: "<h3>Feature 3</h3>",
            text1:
              "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text3:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text4:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        feature7: {
          ComponentName: "Feature7",
          id: "feature7",
          title: "Feature 7",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature7/thumb.jpg"),
          component: Feature7,
          editableContent: {
            title1:
              "<h2>Welcome to Aptean's Annual Report - Animated Flip Cards</h2>",
            title2: "<h3>Feature 1</h3>",
            title3: "<h3>Feature 1</h3>",
            title4: "<h3>Feature 2</h3>",
            title5: "<h3>Feature 2</h3>",
            title6: "<h3>Feature 3</h3>",
            title7: "<h3>Feature 3</h3>",
            text1:
              "<p>2022 was a challenging year, with rising inflation.</p><p>But which of these factors are fashion companies most concerned by in 2023? And more importantly, how do manufacturers plan to address key challenges to continue growing their business?</p><p>This is your opportunity to find out what other fashion and apparel manufacturers are planning over the next year.</p><p>In Aptean's report, you'll discover the identified trends, including:</p><ul><li>Driving growth through digital advancement</li><li>Addressing supply chain woes</li><li>Controlling the cost of doing business</li><li>Using technology to solve the labor crisis</li></ul><p><strong>Download your free copy of the manufacturing forecast report to discover actionable insights that will shape your 2023 manufacturing strategy.</strong></p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text3:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text4:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text5:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text6:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text7:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
        feature8: {
          ComponentName: "Feature8",
          id: "feature8",
          title: "Feature 8",
          category: "feature",
          description: "A basic text component",
          image: require("./feature/feature8/thumb.jpg"),
          component: Feature8,
          editableContent: {
            title1: "<h2>Additional Resources</h2>",
            title2: "<h3>Aptean Distribution ERP Datasheet - Aptean Pay</h3>",
            title3: "<h3>Aptean Distribution ERP Datasheet - Aptean Pay</h3>",
            title4: "<h3>Aptean Distribution ERP Datasheet - Aptean Pay</h3>",
            text1:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text2: "<p>DATASHEET</p>",
            text3: "<p>Mar 31, 2021</p>",
            text4:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text5: "<p>DATASHEET</p>",
            text6: "<p>Mar 31, 2021</p>",
            text7:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
            text8: "<p>DATASHEET</p>",
            text9: "<p>Mar 31, 2021</p>",
            text10:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
            image1: "https://placehold.co/446x208@2x/3A3A3A/EAEAEA/png",
            image2: "https://placehold.co/446x208@2x/3A3A3A/EAEAEA/png",
            image3: "https://placehold.co/446x208@2x/3A3A3A/EAEAEA/png",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
            Images: {
              image1: {
                label: "Image 1 URL:",
                value: "https://placehold.co/446x208@2x/3A3A3A/EAEAEA/png",
              },
              image2: {
                label: "Image 2 URL:",
                value: "https://placehold.co/446x208@2x/3A3A3A/EAEAEA/png",
              },
              image3: {
                label: "Image 3 URL:",
                value: "https://placehold.co/446x208@2x/3A3A3A/EAEAEA/png",
              },
            },
          },
        },
      },
    },
    cta: {
      name: "Call To Action",
      blocks: {
        cta1: {
          ComponentName: "Cta1",
          id: "cta1",
          title: "CTA 1",
          category: "cta",
          description: "A basic footer component",
          image: require("./cta/cta1/thumb.jpg"),
          component: Cta1,
          editableContent: {
            title: "<h2>Excepteur sint ocaecat cupi non proident</h2>",
            text: "<p>Sed ut perspiciatis unde omnis iste natus error sit volup accusantium doloremque laudantium totam rem aperiam</p>",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
      },
    },
    footer: {
      name: "Footer",
      blocks: {
        footer1: {
          ComponentName: "Footer1",
          id: "footer1",
          title: "Footer 1",
          category: "footer",
          description: "A basic footer component",
          image: require("./footer/footer1/thumb.png"),
          component: Footer1,
          editableContent: {
            text: "© Aptean 2024. All rights reserved.",
          },
          optionValues: {
            topSpacer: "pt-8 lg:pt-12 xl:pt-16",
            bottomSpacer: "pb-8 lg:pb-12 xl:pb-16",
          },
          options: {
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["pt-0", "pt-8 lg:pt-12 xl:pt-16"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["pb-0", "pb-8 lg:pb-12 xl:pb-16"],
              },
            },
          },
        },
      },
    },
  };
  return blockLibrary;
};

export default BlockLibrary;
