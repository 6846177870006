import React from "react";
import ContentEditable from "react-contenteditable";

const Feature1 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const { blockKey, title, text, videoUrl, formTitle, formText } = props;

  return (
    <section key={blockKey} class="bg-white py-6 lg:py-12">
      <div class="relative px-4 lg:container lg:mx-auto lg:px-20">
        <div class="grid gap-y-12 md:grid-cols-2 md:gap-y-0 md:gap-x-8 lg:gap-x-12">
          <div>
            <div class="wysiwyg-title" pardot-region="Feature-Variant-1--Title">
              <ContentEditable
                html={title}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("title", event);
                }}
              />
            </div>
            <div class="mt-8 lg:mt-10">
              <div
                class="wysiwyg-text-base"
                pardot-region="Feature-Variant-1--Text"
              >
                <ContentEditable
                  html={text}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text", event);
                  }}
                />
              </div>
            </div>
            <div class="mt-8 lg:mt-12">
              <div pardot-region="Feature-Variant-1--Video">
                <div class="embed-container">
                  <iframe
                    src={videoUrl}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="rounded-2xl bg-[#F8F7F5] py-6 px-8 shadow-[4px_4px_5px_rgba(0,0,0,0.25)] lg:py-8 lg:px-10 xl:rounded-[57px] xl:py-10 xl:px-12">
              <div
                class="wysiwyg-title"
                pardot-region="Feature-Variant-1--Form-Title"
              >
                <ContentEditable
                  html={formTitle}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("formTitle", event);
                  }}
                />
              </div>
              <div class="mt-2 md:mt-4">
                <div
                  class="wysiwyg-text-base"
                  pardot-region="Feature-Variant-1--Form-Text"
                >
                  <ContentEditable
                    html={formText}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("formText", event);
                    }}
                  />
                </div>
              </div>
              <div class="mt-2 md:mt-4">%%content%%</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature1;
