import React from "react";

const TextOption = (props) => {
  const { value, onOptionUpdate, optionKey, blockKey, group } = props;
  return (
    <input
      type="text"
      value={value}
      key={`textInput-${blockKey}`}
      onChange={(event) =>
        onOptionUpdate(blockKey, optionKey, group, event.target.value)
      }
    />
  );
};

export default TextOption;
