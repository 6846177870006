export const headerLP = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      rel="icon"
      type="image/x-icon"
      href="[[[FAVICON]]]"
    />
    [[[MASTEREXTRAHEADER]]]
    <title>[[[TITLE]]]</title>
    [[[CSS]]]
    [[[EXTRAHEADER]]]
  </head>
  <body>
    <div class="container mx-auto">
`;

export const footerLP = `
    </div>
  [[[MASTEREXTRAFOOTER]]]
  [[[EXTRAFOOTER]]]
  </body>
</html>
`;
