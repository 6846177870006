import Header1 from "./header/header1/Header1";
import Header2 from "./header/header2/Header2";
import Header3 from "./header/header3/Header3";
import Header4 from "./header/header4/Header4";
import Header5 from "./header/header5/Header5";
import Header6 from "./header/header6/Header6";
import Hero1 from "./hero/hero1/Hero1";
import Hero2 from "./hero/hero2/Hero2";
import Title1 from "./title/title1/Title1";
import Title2 from "./title/title2/Title2";
import Title3 from "./title/title3/Title3";
import Title4 from "./title/title4/Title4";
import LeadText1 from "./leadText/leadText1/LeadText1";
import LeadText2 from "./leadText/leadText2/LeadText2";
import Text1 from "./text/text1/Text1";
import Content1 from "./content/content1/Content1";
import Content2 from "./content/content2/Content2";
import Content3 from "./content/content3/Content3";
import Content4 from "./content/content4/Content4";
import Content5 from "./content/content5/Content5";
import Content6 from "./content/content6/Content6";
import Cta1 from "./cta/cta1/Cta1";
import Cta2 from "./cta/cta2/Cta2";
import Cta3 from "./cta/cta3/Cta3";
import Cta4 from "./cta/cta4/Cta4";
import Footer1 from "./footer/footer1/Footer1";

const BlockLibraryFMO = (brandGuidelines) => {
  const blockLibrary = {
    header: {
      name: "Header",
      blocks: {
        header1: {
          ComponentName: "Header1",
          id: "header1",
          title: "Header 1",
          category: "header",
          description: "A basic header",
          image: require("./header/header1/thumb.jpg"),
          component: Header1,
          optionValues: {
            ctaUrl: "https://feedmeonline.co.uk",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        header2: {
          ComponentName: "Header2",
          id: "header2",
          title: "Header 2",
          category: "header",
          description: "A basic header",
          image: require("./header/header2/thumb.jpg"),
          component: Header2,
          optionValues: {
            ctaUrl: "https://feedmeonline.com",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        header3: {
          ComponentName: "Header3",
          id: "header3",
          title: "Header 3",
          category: "header",
          description: "A basic header",
          image: require("./header/header3/thumb.jpg"),
          component: Header3,
          optionValues: {
            ctaUrl: "https://feedmeonline.com",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        header4: {
          ComponentName: "Header4",
          id: "header4",
          title: "Header 4",
          category: "header",
          description: "A basic header",
          image: require("./header/header4/thumb.jpg"),
          component: Header4,
          optionValues: {
            ctaUrl: "https://feedmeonline.com",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        header5: {
          ComponentName: "Header5",
          id: "header5",
          title: "Header 5",
          category: "header",
          description: "A basic header",
          image: require("./header/header5/thumb.jpg"),
          component: Header5,
          optionValues: {
            ctaUrl: "https://feedmeonline.com",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        header6: {
          ComponentName: "Header6",
          id: "header6",
          title: "Header 6",
          category: "header",
          description: "A basic header",
          image: require("./header/header6/thumb.jpg"),
          component: Header6,
          optionValues: {
            ctaUrl: "https://feedmeonline.com",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
      },
    },
    hero: {
      name: "Hero",
      blocks: {
        hero1: {
          ComponentName: "Hero1",
          id: "hero1",
          title: "Hero 1",
          category: "hero",
          description: "A basic hero",
          image: require("./hero/hero1/thumb.png"),
          component: Hero1,
          optionValues: {
            imageUrl:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/hero/food-placeholder-1.jpg",
            ctaUrl: "https://feedmeonline.co.uk",
            backgroundColor: brandGuidelines.greyscaleColours[0],
          },
          options: {
            "Hero 1": {
              backgroundColor: {
                label: "Background Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
              imageUrl: {
                label: "Image Address:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/hero/food-placeholder-1.jpg",
              },
            },
          },
        },
        hero2: {
          ComponentName: "Hero2",
          id: "hero2",
          title: "Hero 2",
          category: "hero",
          description: "A basic hero",
          image: require("./hero/hero2/thumb.png"),
          component: Hero2,
          optionValues: {
            imageUrl:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/hero/food-placeholder-1.jpg",
            ctaUrl: "https://feedmeonline.co.uk",
            backgroundColor: brandGuidelines.greyscaleColours[0],
          },
          options: {
            "Hero 2": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://feedmeonline.co.uk",
              },
              imageUrl: {
                label: "Image Address:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/hero/food-placeholder-1.jpg",
              },
            },
          },
        },
      },
    },
    title: {
      name: "Title",
      blocks: {
        title1: {
          ComponentName: "Title1",
          id: "title1",
          title: "Title 1",
          category: "title",
          description: "A basic title component",
          image: require("./title/title1/thumb.jpg"),
          component: Title1,
          editableContent: {
            subTitle: "Nulla facilisis risus nec",
            title: "Morbi lorem nisl, consequat quis ex at",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0], // Pop so the last one is always the BG colour
            subTitleColor: brandGuidelines.primaryColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              subTitleColor: {
                label: "Sub-title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
        title2: {
          ComponentName: "Title2",
          id: "title2",
          title: "Title 2",
          category: "title",
          description: "A basic title component",
          image: require("./title/title2/thumb.jpg"),
          component: Title2,
          editableContent: {
            subTitle: "Nulla facilisis risus nec",
            title: "Morbi lorem nisl, consequat quis ex at",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0], // Pop so the last one is always the BG colour
            subTitleColor: brandGuidelines.primaryColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              subTitleColor: {
                label: "Sub-title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
        title3: {
          ComponentName: "Title3",
          id: "title3",
          title: "Title 3",
          category: "title",
          description: "A basic title component",
          image: require("./title/title3/thumb.jpg"),
          component: Title3,
          editableContent: {
            title: "Morbi lorem nisl, consequat quis ex at",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
        title4: {
          ComponentName: "Title4",
          id: "title4",
          title: "Title 4",
          category: "title",
          description: "A basic title component",
          image: require("./title/title4/thumb.jpg"),
          component: Title4,
          editableContent: {
            title: "Morbi lorem nisl, consequat quis ex at",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
      },
    },
    leadText: {
      name: "Lead Text",
      blocks: {
        leadText1: {
          ComponentName: "LeadText1",
          id: "leadText1",
          title: "Lead Text 1",
          category: "leadText",
          description: "A basic lead text component",
          image: require("./leadText/leadText1/thumb.jpg"),
          component: LeadText1,
          editableContent: {
            text: "Vivamus eu tortor ac ipsum scelerisque dignissim id ut velit. Praesent vehicula, ante at maximus porta, ante est porttitor mauris, quis placerat lacus tellus ut erat. Maecenas tempor nulla ac scelerisque efficitur.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0], // Pop so the last one is always the BG colour
            textColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
        leadText2: {
          ComponentName: "LeadText2",
          id: "leadText2",
          title: "Lead Text 2",
          category: "leadText",
          description: "A basic lead text component",
          image: require("./leadText/leadText2/thumb.jpg"),
          component: LeadText2,
          editableContent: {
            text: "Vivamus eu tortor ac ipsum scelerisque dignissim id ut velit. Praesent vehicula, ante at maximus porta, ante est porttitor mauris, quis placerat lacus tellus ut erat. Maecenas tempor nulla ac scelerisque efficitur.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0], // Pop so the last one is always the BG colour
            textColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
      },
    },
    text: {
      name: "Text",
      blocks: {
        text1: {
          ComponentName: "Text1",
          id: "text1",
          title: "Text 1",
          category: "text",
          description: "A basic text component",
          image: require("./text/text1/thumb.jpg"),
          component: Text1,
          editableContent: {
            text: "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Quisque ut nisl orci. Duis dictum eu dolor a auctor. Nunc maximus vitae ligula quis posuere. Duis pharetra a arcu et volutpat. Nam eget nisi dapibus, luctus sapien egestas, consectetur nunc. Phasellus aliquet leo ultrices massa venenatis dictum.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0], // Pop so the last one is always the BG colour
            textColor: brandGuidelines.greyscaleColours[3],
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
      },
    },
    content: {
      name: "Content",
      blocks: {
        content1: {
          ComponentName: "Content1",
          id: "content1",
          title: "Content 1",
          category: "content",
          description: "A basic content component",
          image: require("./content/content1/thumb.jpg"),
          component: Content1,
          editableContent: {
            subTitle1: "9 November",
            title1: "Aen ean rutrum velit ne",
            text1:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
            subTitle2: "9 November",
            title2: "Aen ean rutrum velit ne",
            text2:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            subTitleColor: brandGuidelines.primaryColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaUrl1: "https://feedmeonline.co.uk",
            ctaUrl2: "https://feedmeonline.co.uk",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              subTitleColor: {
                label: "Sub-title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Images: {
              image1: {
                label: "Image 1:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
              },
              image2: {
                label: "Image 2:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
              },
            },
            Links: {
              ctaUrl1: {
                label: "Item 1:",
                value: "https://feedmeonline.co.uk",
              },
              ctaUrl2: {
                label: "Item 2:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        content2: {
          ComponentName: "Content2",
          id: "content2",
          title: "Content 2",
          category: "content",
          description: "A basic content component",
          image: require("./content/content2/thumb.jpg"),
          component: Content2,
          editableContent: {
            subTitle1: "9 November",
            title1: "Aen ean rutrum velit ne",
            text1:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
            subTitle2: "9 November",
            title2: "Aen ean rutrum velit ne",
            text2:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            subTitleColor: brandGuidelines.primaryColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaUrl1: "https://feedmeonline.co.uk",
            ctaUrl2: "https://feedmeonline.co.uk",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              subTitleColor: {
                label: "Sub-title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Images: {
              image1: {
                label: "Image 1:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
              },
              image2: {
                label: "Image 2:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
              },
            },
            Links: {
              ctaUrl1: {
                label: "Item 1:",
                value: "https://feedmeonline.co.uk",
              },
              ctaUrl2: {
                label: "Item 2:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        content3: {
          ComponentName: "Content3",
          id: "content3",
          title: "Content 3",
          category: "content",
          description: "A basic content component",
          image: require("./content/content3/thumb.jpg"),
          component: Content3,
          editableContent: {
            title: "Featured takeaways in LS12",
            text: "Donec ornare eget elit ut accumsan. Aenean eu nibh est. Etiam tempor velit at ligula vulputate elementum.",
            item1Title: "Shalamar",
            item1SubTitle: "Indian ...",
            item2Title: "Chicko's",
            item2SubTitle: "Burgers, Fried Chicken, Halal ...",
            item3Title: "Luciano's",
            item3SubTitle: "Italian, Pizza ...",
            item4Title: "Kebabish",
            item4SubTitle: "Burgers, Fried Chicken, Indian ...",
            item1Text: "Order Now",
            item2Text: "Order Now",
            item3Text: "Order Now",
            item4Text: "Order Now",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0], // Pop so the last one is always the BG colour
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaButtonColor: brandGuidelines.primaryColours[0],
            ctaTextColor: brandGuidelines.greyscaleColours[0],
            ctaUrl1:
              "https://www.feedmeonline.co.uk/takeaways/Leeds/LS12/shalamar/menu",
            ctaUrl2:
              "https://www.feedmeonline.co.uk/takeaways/Leeds/LS12/chickos/menu",
            ctaUrl3:
              "https://www.feedmeonline.co.uk/takeaways/Leeds/LS12/lucianos-pizza-and-noodle-bar/menu",
            ctaUrl4:
              "https://www.feedmeonline.co.uk/takeaways/Leeds/LS11/kebabish/menu",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-1.png",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-2.png",
            image3:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-3.png",
            image4:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-4.png",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Text",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Button Colours": {
              ctaButtonColor: {
                label: "Button Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              ctaTextColor: {
                label: "Text Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Images: {
              image1: {
                label: "Image 1:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-1.png",
              },
              image2: {
                label: "Image 2:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-2.png",
              },
              image3: {
                label: "Image 3:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-3.png",
              },
              image4: {
                label: "Image 4:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/takeaway/takeaway-4.png",
              },
            },
            Links: {
              ctaUrl1: {
                label: "Item 1:",
                value:
                  "https://www.feedmeonline.co.uk/takeaways/Leeds/LS12/shalamar/menu",
              },
              ctaUrl2: {
                label: "Item 2:",
                value:
                  "https://www.feedmeonline.co.uk/takeaways/Leeds/LS12/chickos/menu",
              },
              ctaUrl3: {
                label: "Item 3:",
                value:
                  "https://www.feedmeonline.co.uk/takeaways/Leeds/LS12/lucianos-pizza-and-noodle-bar/menu",
              },
              ctaUrl4: {
                label: "Item 4:",
                value:
                  "https://www.feedmeonline.co.uk/takeaways/Leeds/LS11/kebabish/menu",
              },
            },
          },
        },
        content4: {
          ComponentName: "Content4",
          id: "content4",
          title: "Content 4",
          category: "content",
          description: "A basic content component",
          image: require("./content/content4/thumb.jpg"),
          component: Content4,
          editableContent: {
            title1: "Aen ean rutrum velit ne",
            text1:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
            title2: "Aen ean rutrum velit ne",
            text2:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaUrl1: "https://feedmeonline.co.uk",
            ctaUrl2: "https://feedmeonline.co.uk",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Images: {
              image1: {
                label: "Image 1:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
              },
              image2: {
                label: "Image 2:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
              },
            },
            Links: {
              ctaUrl1: {
                label: "Item 1:",
                value: "https://feedmeonline.co.uk",
              },
              ctaUrl2: {
                label: "Item 2:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        content5: {
          ComponentName: "Content5",
          id: "content5",
          title: "Content 5",
          category: "content",
          description: "A basic content component",
          image: require("./content/content5/thumb.jpg"),
          component: Content5,
          editableContent: {
            title1: "Aen ean rutrum velit ne",
            text1:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
            title2: "Aen ean rutrum velit ne",
            text2:
              "Aenean rutrum velit nec vulputate tincidunt. In hac habitasse platea dictumst. Nulla lacinia mauris eu sapien.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaUrl1: "https://feedmeonline.co.uk",
            ctaUrl2: "https://feedmeonline.co.uk",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Images: {
              image1: {
                label: "Image 1:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-3.jpg",
              },
              image2: {
                label: "Image 2:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-2.jpg",
              },
            },
            Links: {
              ctaUrl1: {
                label: "Item 1:",
                value: "https://feedmeonline.co.uk",
              },
              ctaUrl2: {
                label: "Item 2:",
                value: "https://feedmeonline.co.uk",
              },
            },
          },
        },
        content6: {
          ComponentName: "Content6",
          id: "content6",
          title: "Content 6",
          category: "content",
          description: "A basic content component",
          image: require("./content/content6/thumb.jpg"),
          component: Content6,
          editableContent: {
            title: "Save with every order!",
            text: "Vivamus eu tortor ac ipsum scelerisque dignissim id ut velit.<br />Praesent vehicula, ante at maximus.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.primaryColours[0],
            titleColor: brandGuidelines.greyscaleColours[0],
            textColor: brandGuidelines.greyscaleColours[0],
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-5.jpg",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-6.jpg",
            image3:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-5.jpg",
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Colour",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Images: {
              image1: {
                label: "Image 1:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-5.jpg",
              },
              image2: {
                label: "Image 2:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-6.jpg",
              },
              image3: {
                label: "Image 3:",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/content/food-placeholder-5.jpg",
              },
            },
            Spacing: {
              topSpacer: {
                label: "Top",
                values: ["0", "15", "30"],
              },
              bottomSpacer: {
                label: "Bottom",
                values: ["0", "15", "30"],
              },
            },
          },
        },
      },
    },
    cta: {
      name: "CTA",
      blocks: {
        cta1: {
          ComponentName: "Cta1",
          id: "cta1",
          title: "CTA 1",
          category: "cta",
          description: "A basic cta component",
          image: require("./cta/cta1/thumb.jpg"),
          component: Cta1,
          editableContent: {
            title: "App is available for iOS and Android",
            text: "Vivamus eu tortor ac ipsum scelerisque dignissim id ut velit.<br />Praesent vehicula, ante at maximus.",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaUrl1: "https://feedmeonline.co.uk/",
            ctaUrl2:
              "https://apps.apple.com/gb/app/feed-me-online-takeaway-app/id979965241",
            ctaUrl3:
              "https://play.google.com/store/apps/details?id=com.actionprompt.fmo",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/logo-feedmeonline-primary.png",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-app-store-dark.png",
            image3:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-google-play-dark.png",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Logo: {
              image1: {
                label: "Logo Image Address",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/logo-feedmeonline-primary.png",
              },
              ctaUrl1: {
                label: "Logo Link Address",
                value: "https://feedmeonline.co.uk/",
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Text",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "App Store Buttons": {
              image2: {
                label: "Apple Image Address",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-app-store-dark.png",
              },
              ctaUrl2: {
                label: "Apple App Link Address",
                value:
                  "https://apps.apple.com/gb/app/feed-me-online-takeaway-app/id979965241",
              },
              image3: {
                label: "Android Image Address",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-google-play-dark.png",
              },
              ctaUrl3: {
                label: "Android App Link Address",
                value:
                  "https://play.google.com/store/apps/details?id=com.actionprompt.fmo",
              },
            },
          },
        },
        cta2: {
          ComponentName: "Cta2",
          id: "cta2",
          title: "CTA 2",
          category: "cta",
          description: "A basic cta component",
          image: require("./cta/cta2/thumb.jpg"),
          component: Cta2,
          editableContent: {
            title: "App is available for iOS and Android",
            text: "Vivamus eu tortor ac ipsum scelerisque dignissim id ut velit.<br />Praesent vehicula, ante at maximus.",
            ctaButtonText: "Order Online",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaButtonColor: brandGuidelines.primaryColours[0],
            ctaUrl1: "https://feedmeonline.co.uk/",
            ctaUrl2:
              "https://apps.apple.com/gb/app/feed-me-online-takeaway-app/id979965241",
            ctaUrl3:
              "https://play.google.com/store/apps/details?id=com.actionprompt.fmo",
            ctaUrl4: "https://feedmeonline.co.uk/",
            image1:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/logo-feedmeonline-primary.png",
            image2:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-app-store-dark.png",
            image3:
              "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-google-play-dark.png",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            Logo: {
              image1: {
                label: "Logo Image Address",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/logo-feedmeonline-primary.png",
              },
              ctaUrl1: {
                label: "Logo Link Address",
                value: "https://feedmeonline.co.uk/",
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Text",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Button Colours": {
              ctaButtonColor: {
                label: "Button Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              ctaUrl4: {
                label: "Button Link Address",
                value: "https://feedmeonline.co.uk/",
              },
            },
            "App Store Buttons": {
              image2: {
                label: "Apple Image Address",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-app-store-dark.png",
              },
              ctaUrl2: {
                label: "Apple App Link Address",
                value:
                  "https://apps.apple.com/gb/app/feed-me-online-takeaway-app/id979965241",
              },
              image3: {
                label: "Android Image Address",
                value:
                  "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/app/button-google-play-dark.png",
              },
              ctaUrl3: {
                label: "Android App Link Address",
                value:
                  "https://play.google.com/store/apps/details?id=com.actionprompt.fmo",
              },
            },
          },
        },
        cta3: {
          ComponentName: "Cta3",
          id: "cta3",
          title: "CTA 3",
          category: "cta",
          description: "A basic cta component",
          image: require("./cta/cta3/thumb.jpg"),
          component: Cta3,
          editableContent: {
            title: "Order online now",
            text: "Vivamus eu tortor ac ipsum scelerisque dignissim id ut velit.<br />Praesent vehicula, ante at maximus.",
            ctaButtonText: "Order Online",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            titleColor: brandGuidelines.greyscaleColours[3],
            textColor: brandGuidelines.greyscaleColours[3],
            ctaButtonColor: brandGuidelines.primaryColours[0],
            ctaUrl1: "https://feedmeonline.co.uk/",
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Text Colours": {
              titleColor: {
                label: "Title",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              textColor: {
                label: "Text",
                values: [
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Button Colours": {
              ctaButtonColor: {
                label: "Button Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              ctaUrl1: {
                label: "Button Link Address",
                value: "https://feedmeonline.co.uk/",
              },
            },
          },
        },
        cta4: {
          ComponentName: "Cta4",
          id: "cta4",
          title: "CTA 4",
          category: "cta",
          description: "A basic cta component",
          image: require("./cta/cta4/thumb.jpg"),
          component: Cta4,
          editableContent: {
            ctaButtonText: "Order Online",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[0],
            ctaButtonColor: brandGuidelines.primaryColours[0],
            ctaUrl1: "https://feedmeonline.co.uk/",
            topSpacer: 30,
            bottomSpacer: 30,
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[1],
                  brandGuidelines.greyscaleColours[2],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
            },
            "Button Colours": {
              ctaButtonColor: {
                label: "Button Colour",
                values: [
                  brandGuidelines.primaryColours[0],
                  brandGuidelines.greyscaleColours[0],
                  brandGuidelines.greyscaleColours[3],
                ],
              },
              ctaUrl1: {
                label: "Button Link Address",
                value: "https://feedmeonline.co.uk/",
              },
            },
          },
        },
      },
    },
    footer: {
      name: "Footer",
      blocks: {
        footer1: {
          ComponentName: "Footer1",
          id: "footer1",
          title: "Footer 1",
          category: "footer",
          description: "A basic footer component",
          image: require("./footer/footer1/thumb.jpg"),
          component: Footer1,
          editableContent: {
            text1:
              "Feed Me Online, Action Prompt Ltd.<br />Office 28, Burley Hill Business Centre, Leeds LS4 2PX",
            text2: "Unsubscribe",
          },
          optionValues: {
            backgroundColor: brandGuidelines.greyscaleColours[3],
            socialLink1: "https://www.facebook.com/feedmeonlineuk/",
            socialLink2: "https://www.instagram.com/feedmeonline.co.uk/",
            socialLink3: "https://www.pinterest.co.uk/feedmeonlineuk/",
            socialLink4: "https://twitter.com/feedmeonline",
          },
          options: {
            "Background Colour": {
              backgroundColor: {
                label: "Colour",
                values: [brandGuidelines.greyscaleColours[3]],
              },
            },
            "Social Links": {
              socialLink1: {
                label: "Facebook URL:",
                value: "https://www.facebook.com/feedmeonlineuk/",
              },
              socialLink2: {
                label: "Instagram URL:",
                value: "https://www.instagram.com/feedmeonline.co.uk/",
              },
              socialLink3: {
                label: "Pinterest URL:",
                value: "https://www.pinterest.co.uk/feedmeonlineuk/",
              },
              socialLink4: {
                label: "Twitter URL:",
                value: "https://twitter.com/feedmeonline",
              },
            },
          },
        },
      },
    },
  };
  return blockLibrary;
};

export default BlockLibraryFMO;
