import { useState } from "react";

const useErrorHandler = (initialState) => {
  const [error, setError] = useState(initialState);
  const showError = (errorMessage) => {
    setError(errorMessage);
    window.setTimeout(() => {
      setError(null);
    }, 3000);
  };
  const showPersistentError = (errorMessage) => {
    setError(errorMessage);
  };
  const clearErrorMessage = () => {
    setError(null);
  };
  return { error, showError, showPersistentError, clearErrorMessage };
};

export default useErrorHandler;
