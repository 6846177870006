import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Text4 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    titleColor,
    textColor,
    title,
    text,
    image,
  } = props;

  return (
    <section
      key={blockKey}
      className={`feature-variant-1 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-16 lg:gap-x-12 xl:gap-x-16">
          <div className="order-2 lg:order-1 col-span-9 flex items-center">
            <div>
              <div className="lg:pr-12 xl:pr-16">
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-feature-variant-1--title"
                    mktoname="Title"
                  >
                    <ContentEditable
                      html={title}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-feature-variant-1--text"
                    mktoname="Text"
                  >
                    <ContentEditable
                      html={text}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 lg:mt-6 xl:mt-8 btn-secondary ${module-feature-variant-1--button-toggle}">
                <div
                  className="mktoText"
                  id="module-feature-variant-1--button"
                  mktoname="Button"
                >
                  <a href="https://www.aptean.com/en-US/">Download</a>
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 lg:order-2 col-span-7 flex flex-col">
            <div className="image h-140">
              <div
                className="mktoText"
                id="module-feature-variant-1--image"
                mktoname="Image"
              >
                <img src={`${image}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Text4;
