import React from "react";
import ContentEditable from "react-contenteditable";

const Header2 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    title1,
    title2,
    text1,
    text2,
    formTitle,
    image,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`header-variant-2 bg-dark-grey ${topSpacer} ${bottomSpacer}`}
    >
      <div class="space-y-8 px-4 md:px-8 lg:space-y-12 lg:px-12 xl:space-y-16 xl:px-16">
        <div class="space-y-8 lg:grid lg:grid-cols-2 lg:gap-x-12 lg:space-y-0 xl:gap-x-16">
          <div class="flex items-center">
            <div>
              <div class="lg:pr-12 xl:pr-16">
                <div className="editor">
                  <div
                    class="mktoText"
                    id="module-header-variant-2--title"
                    mktoName="Title"
                  >
                    <ContentEditable
                      html={title1}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title1", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="lg:w-9/12 xl:w-8/12">
                  <div className="editor">
                    <div
                      class="mktoText"
                      id="module-header-variant-2--lead-text"
                      mktoName="Lead Text"
                    >
                      <ContentEditable
                        html={text1}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("text1", event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 lg:mt-12 xl:mt-16">
                <img
                  src="https://lp.aptean.com/rs/181-TRF-125/images/chevron-arrows-primary.svg"
                  alt=""
                  class="h-12 lg:h-16 xl:h-20"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="bg-light-grey min-h-64 p-4 md:p-6 xl:p-8">
              <div class="editor text-center text-black">
                <div
                  class="mktoText"
                  id="module-header-variant-1--form-title"
                  mktoname="Form Title"
                >
                  <ContentEditable
                    html={formTitle}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("formTitle", event);
                    }}
                  />
                </div>
              </div>
              <div class="mt-4 lg:mt-6 xl:mt-8">
                <div
                  class="mktoForm"
                  id="module-header-variant-2--form"
                  mktoName="Form"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="space-y-8 lg:grid lg:grid-cols-2 lg:gap-x-12 lg:space-y-0 xl:gap-x-16">
          <div>
            <div class="lg:pr-12 xl:pr-16">
              <div className="editor">
                <div
                  class="mktoText"
                  id="module-header-variant-2--sub-title"
                  mktoName="Sub-Title"
                >
                  <ContentEditable
                    html={title2}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title2", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div className="editor">
                <div
                  class="mktoText"
                  id="module-header-variant-2--text"
                  mktoName="Text"
                >
                  <ContentEditable
                    html={text2}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text2", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="btn-primary mt-6">
              <a href="https://www.aptean.com/en-US/" target="_blank">
                Download
              </a>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="image relative h-full">
              <div
                class="mktoText h-full"
                id="module-header-variant-2--image"
                mktoName="Image"
              >
                <img src={`${image}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header2;
