import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Text1 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    backgroundColor,
    titleColor,
    textColor,
    title,
    text,
    topSpacer,
    bottomSpacer,
    blockKey,
  } = props;

  return (
    <section
      key={blockKey}
      className={`richtext-variant-2 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div class="px-4 md:px-8 lg:px-12 xl:px-16">
        <div>
          <div class="lg:pr-12 xl:pr-16">
            <div className="editor">
              <div
                class="mktoText"
                id="module-richtext-variant-2--title"
                mktoName="Title"
              >
                <ContentEditable
                  html={title}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("title", event);
                  }}
                />
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div className="editor">
              <div
                class="mktoText"
                id="module-richtext-variant-2--text"
                mktoName="Text"
              >
                <ContentEditable
                  html={text}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text", event);
                  }}
                />
              </div>
            </div>
          </div>
          <div class="btn-secondary mt-4 lg:mt-6 xl:mt-8">
            <div
              class="mktoText"
              id="module-richtext-variant-2--button"
              mktoName="Button"
            >
              <a href="https://www.aptean.com/en-US/">Download</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Text1;
