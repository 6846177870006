import React from "react";
import "../../../../components/themes/feedmeonline.css";

const Hero2 = (props) => {
  const { imageUrl, ctaUrl, blockKey } = props;

  return (
    <table
      key={blockKey}
      border="0"
      cellpadding="0"
      cellspacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td class="hero2Wrapper" width="100%" valign="top">
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td valign="top">
                    <a
                      href={ctaUrl}
                      class="hero2ImageLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`${imageUrl}?x-timestamp=${Date.now()}`}
                        width="620"
                        alt=""
                        class="hero2Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Hero2;
