import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import { apiRequest } from "../helper/api";
import useErrorHandler from "../hooks/errorHandler";

import { TemplatesTemplate } from "../components";
import TitleBarWithButton from "../components/TitleBarWithButton";
import NoEmailsMessage from "../components/SystemMessages/NoEmailsMessage";

import noScreenshotPlaceholder from "../images/placeholders/no-screenshot-placeholder.png";

import ErrorMessage from "../components/SystemMessages/ErrorMessage";

const EmailList = (props) => {
  const { emails } = props;
  if (emails.length < 1) {
    return <NoEmailsMessage />;
  }
  const renderSnapshotImage = (snapshot, name) => {
    return (
      <img
        src={snapshot}
        alt={name}
        className="cs-h-72 cs-w-full cs-rounded-tl-xl cs-rounded-tr-xl cs-object-cover cs-object-top"
      />
    );
  };
  const renderPlaceholderImage = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 118.82 125.91"
        class="cs-h-10 cs-w-10 cs-text-grey-400"
      >
        <g>
          <path
            fill="currentColor"
            d="M38.67,71.33,60,80V68.35L31,56.63l-8.63-3.5L40.06,46h0l4.63-1.88L60,17.64h0l14.6,28.29L90.93,52,63.19,1.94a3.77,3.77,0,0,0-6.56-.06L37.22,35.51l0,.09-34.81,14a3.77,3.77,0,0,0,0,7h0l29.1,11.78Z"
          ></path>
          <path
            fill="currentColor"
            d="M113.72,69,86.4,57.93l-5.35-2.16L60,47.23V58.29L85.05,69l11.29,4.58-16,6.47-.05,0-5.42,2.2,0,.07-7.5,13-1.21,2.11-.34.58L60,108.08l-15.36-26L28.21,76l28.47,48.08a3.77,3.77,0,0,0,6.5,0L82.11,91.24l34.37-14.15a3.76,3.76,0,0,0,0-7Z"
          ></path>
        </g>
      </svg>
    );
  };

  const listItems = emails.map((email, index) => {
    const { _id, name, createdAt, updatedAt, snapshot } = email;
    const image =
      snapshot !== undefined
        ? renderSnapshotImage(snapshot, name)
        : renderPlaceholderImage();
    return (
      <div className="cs-card" key={index}>
        <div className="cs-flex cs-h-72 cs-items-center cs-justify-center cs-rounded-tl-xl cs-rounded-tr-xl cs-border-b cs-border-grey-200 cs-bg-grey-200">
          {image}
        </div>
        <div className="cs-p-6">
          <h2 className="cs-font-semibold cs-leading-none cs-text-white">
            {name}
          </h2>
          <div className="cs-mt-6 cs-text-xs cs-text-grey-50">{`Updated: ${moment(
            updatedAt,
          ).format("DD/MM/YYYY HH:mm")}`}</div>
          <div className="cs-mt-0.5 cs-text-xs cs-text-grey-50">{`Created: ${moment(
            createdAt,
          ).format("DD/MM/YYYY HH:mm")}`}</div>
          <div className="cs-mt-6">
            <Link
              to={{ pathname: `/builder/${_id}`, state: { email } }}
              className="cs-btn-secondary sm"
            >
              Edit Template
            </Link>
          </div>
        </div>
      </div>
    );
  });
  return <>{listItems}</>;
};

const Templates = () => {
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);

  const { error, showPersistentError } = useErrorHandler(null);

  const history = useHistory();

  // Load the templates
  useEffect(() => {
    getEmails();
  }, []);

  const getEmails = async () => {
    try {
      setLoading(true);
      const response = await apiRequest("email", "GET");
      if (response.success) {
        setLoading(false);
        setEmails(response.data);
      } else {
        setLoading(false);
        const { errorCode } = response;
        if (errorCode === 401) {
          history.replace("/login");
        } else if (errorCode === 404) {
          // Set to an empty array so it will show the no emails dialog
          setEmails([]);
        } else {
          showPersistentError("Unable to load saved emails: " + response.error);
        }
      }
    } catch (e) {
      setLoading(false);
      showPersistentError("Unable to load saved emails." + e);
    }
  };

  return (
    <TemplatesTemplate
      titleBar={
        <TitleBarWithButton
          title="Templates"
          buttonText="New Template"
          buttonLink="/new-template"
        />
      }
    >
      {error ? <ErrorMessage errorMessage={error} /> : null}
      {loading ? (
        <div className="cs-absolute cs-inset-0 cs-flex cs-h-full cs-w-full cs-items-center cs-justify-center">
          <div className="cs-spinner" role="status">
            <span className="cs-sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <EmailList emails={emails} />
      )}
    </TemplatesTemplate>
  );
};

export default Templates;
