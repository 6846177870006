import React, { Component } from "react";
import styled from "styled-components";
import { size } from "styled-theme";
import PropTypes from "prop-types";

import { Block } from "../../../../components";
import BuilderNavigation from "../../../../components/modules/builder/BuilderNavigation";

const Wrapper = styled(Block)`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${size("maxWidth")};
  > :not(:first-child) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
const ExportButton = styled.button`
  background-color: #2ca5ef;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  border: 0;
  padding: 15px;
  margin-top: auto;
  margin-bottom: 15px;
  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class NavPane extends Component {
  render() {
    const { onShowModal, onVisiblePaneChange, onSaveTemplate } = this.props;
    return (
      <Wrapper reverse {...this.props}>
        <InnerWrapper>
          <BuilderNavigation
            onShowModal={onShowModal}
            onVisiblePaneChange={onVisiblePaneChange}
            reverse
          />
          <ExportButton type="button" onClick={() => onSaveTemplate()}>
            SAVE TEMPLATE
          </ExportButton>
          <ExportButton type="button" onClick={() => onShowModal()}>
            EXPORT TEMPLATE
          </ExportButton>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

NavPane.propTypes = {
  onShowModal: PropTypes.func,
  onVisiblePaneChange: PropTypes.func,
  onSaveTemplate: PropTypes.func,
};
