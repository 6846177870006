import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Text1 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    backgroundColor,
    titleColor,
    textColor,
    title,
    text,
    formTitle,
    topSpacer,
    bottomSpacer,
    blockKey,
  } = props;

  return (
    <section
      key={blockKey}
      className={`richtext-variant-1 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-16 lg:gap-x-12 xl:gap-x-16">
          <div className="col-span-9">
            <div className="lg:pr-12 xl:pr-16">
              <div className="editor">
                <div
                  className="mktoText"
                  id="module-richtext-variant-1--title"
                  mktoname="Title"
                >
                  <ContentEditable
                    html={title}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="editor">
                <div
                  className="mktoText"
                  id="module-richtext-variant-1--text"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 lg:mt-6 xl:mt-8 btn-secondary">
              <div
                className="mktoText"
                id="module-richtext-variant-1--button"
                mktoname="Button"
              >
                <a href="https://www.aptean.com/en-US/">Download</a>
              </div>
            </div>
          </div>
          <div className="col-span-7">
            <div className="bg-light-grey p-4 md:p-6 xl:p-8 min-h-64">
              <div className="editor text-center text-black">
                <div
                  className="mktoText"
                  id="module-richtext-variant-1--form-title"
                  mktoname="Form Title"
                >
                  <ContentEditable
                    html={formTitle}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("formTitle", event);
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 lg:mt-6 xl:mt-8">
                <div
                  className="mktoForm"
                  id="module-richtext-variant-1--form"
                  mktoname="Form"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Text1;
