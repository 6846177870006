import React from "react";
import ContentEditable from "react-contenteditable";
import ReactComment from "../../../ReactComment";
import "../../../../components/themes/feedmeonline.css";

const Content2 = (props) => {
  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    brandGuidelines,
    backgroundColor,
    subTitleColor,
    titleColor,
    textColor,
    image1,
    image2,
    subTitle1,
    subTitle2,
    title1,
    title2,
    text1,
    text2,
    cta1Url,
    cta2Url,
    blockKey,
  } = props;

  const comment1 =
    '[if (gte mso 9)|(IE)]><table width="100%" border="0" cellSpacing="0" cellPadding="0" role="presentation"><tr><td width="50%" valign="top"><![endif]';
  const comment2 =
    '[if (gte mso 9)|(IE)]></td><td width="50%" valign="top"><![endif]';
  const comment3 = "[if (gte mso 9)|(IE)]></td></tr></table><![endif]";

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            className="content2Wrapper"
            bgcolor={backgroundColor}
            style={{ backgroundColor: backgroundColor }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td valign="top">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      role="presentation"
                    >
                      <tbody>
                        <tr>
                          <td
                            className="cs-sm-ta-center content2ColumnContainer"
                            valign="top"
                          >
                            <ReactComment text={comment1} />
                            <div className="cs-sm-mw-100pc content2Column">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="content2ContentContainer"
                                      valign="top"
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="content2ImageContainer"
                                              valign="top"
                                            >
                                              <table
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td valign="top">
                                                      <a
                                                        href={cta1Url}
                                                        className="content2ImageLink"
                                                        onClick={(e) =>
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <img
                                                          src={`${image1}?x-timestamp=${Date.now()}`}
                                                          width="250"
                                                          height="200"
                                                          alt=""
                                                          className="cs-sm-m-0-auto content2Image"
                                                          crossOrigin="anonymous"
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="15"
                                              className="content2Spacer"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="cs-fb-font"
                                              valign="top"
                                              style={{
                                                color: subTitleColor,
                                                fontFamily:
                                                  brandGuidelines.fontStack
                                                    .primary,
                                                fontSize:
                                                  brandGuidelines.p.size,
                                                lineHeight:
                                                  brandGuidelines.p.lineHeight,
                                                fontWeight:
                                                  brandGuidelines.p.fontWeight,
                                              }}
                                            >
                                              <ContentEditable
                                                html={subTitle1}
                                                disabled={false}
                                                onChange={(event) => {
                                                  handleChangeFunction(
                                                    "subTitle1",
                                                    event,
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="10"
                                              className="content2Spacer"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="cs-fb-font"
                                              valign="top"
                                              style={{
                                                color: titleColor,
                                                fontFamily:
                                                  brandGuidelines.fontStack
                                                    .primary,
                                                fontSize:
                                                  brandGuidelines.h2.size,
                                                lineHeight:
                                                  brandGuidelines.h2.lineHeight,
                                                fontWeight:
                                                  brandGuidelines.h2.fontWeight,
                                              }}
                                            >
                                              <a
                                                href={cta1Url}
                                                className="content2TitleLink"
                                                style={{
                                                  color: titleColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.h2.size,
                                                  lineHeight:
                                                    brandGuidelines.h2
                                                      .lineHeight,
                                                  fontWeight:
                                                    brandGuidelines.h2
                                                      .fontWeight,
                                                  textDecoration: "none",
                                                }}
                                              >
                                                <ContentEditable
                                                  html={title1}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "title1",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="10"
                                              className="content2Spacer"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="cs-fb-font"
                                              valign="top"
                                              style={{
                                                color: textColor,
                                                fontFamily:
                                                  brandGuidelines.fontStack
                                                    .primary,
                                                fontSize:
                                                  brandGuidelines.pLead.size,
                                                lineHeight:
                                                  brandGuidelines.pLead
                                                    .lineHeight,
                                                fontWeight:
                                                  brandGuidelines.pLead
                                                    .fontWeight,
                                              }}
                                            >
                                              <a
                                                href={cta1Url}
                                                style={{
                                                  color: textColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.pLead.size,
                                                  lineHeight:
                                                    brandGuidelines.pLead
                                                      .lineHeight,
                                                  fontWeight:
                                                    brandGuidelines.pLead
                                                      .fontWeight,
                                                  textDecoration: "none",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  html={text1}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "text1",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <ReactComment text={comment2} />
                            <div className="cs-sm-mw-100pc content2Column">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="content2ContentContainer"
                                      valign="top"
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="content2ImageContainer"
                                              valign="top"
                                            >
                                              <table
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td valign="top">
                                                      <a
                                                        href={cta2Url}
                                                        className="content2ImageLink"
                                                        onClick={(e) =>
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <img
                                                          src={`${image2}?x-timestamp=${Date.now()}`}
                                                          width="250"
                                                          height="200"
                                                          alt=""
                                                          className="cs-sm-m-0-auto content2Image"
                                                          crossOrigin="anonymous"
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="15"
                                              className="content2Spacer"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="cs-fb-font content2SubTitle"
                                              valign="top"
                                              style={{
                                                color: subTitleColor,
                                                fontFamily:
                                                  brandGuidelines.fontStack
                                                    .primary,
                                                fontSize:
                                                  brandGuidelines.p.size,
                                                lineHeight:
                                                  brandGuidelines.p.lineHeight,
                                                fontWeight:
                                                  brandGuidelines.p.fontWeight,
                                              }}
                                            >
                                              <ContentEditable
                                                html={subTitle2}
                                                disabled={false}
                                                onChange={(event) => {
                                                  handleChangeFunction(
                                                    "subTitle2",
                                                    event,
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="10"
                                              className="content2Spacer"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="cs-fb-font"
                                              valign="top"
                                              style={{
                                                color: titleColor,
                                                fontFamily:
                                                  brandGuidelines.fontStack
                                                    .primary,
                                                fontSize:
                                                  brandGuidelines.h2.size,
                                                lineHeight:
                                                  brandGuidelines.h2.lineHeight,
                                                fontWeight:
                                                  brandGuidelines.h2.fontWeight,
                                              }}
                                            >
                                              <a
                                                href={cta2Url}
                                                style={{
                                                  color: titleColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.h2.size,
                                                  lineHeight:
                                                    brandGuidelines.h2
                                                      .lineHeight,
                                                  fontWeight:
                                                    brandGuidelines.h2
                                                      .fontWeight,
                                                  textDecoration: "none",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  html={title2}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "title2",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="10"
                                              className="content2Spacer"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="cs-fb-font"
                                              valign="top"
                                              style={{
                                                color: textColor,
                                                fontFamily:
                                                  brandGuidelines.fontStack
                                                    .primary,
                                                fontSize:
                                                  brandGuidelines.pLead.size,
                                                lineHeight:
                                                  brandGuidelines.pLead
                                                    .lineHeight,
                                                fontWeight:
                                                  brandGuidelines.pLead
                                                    .fontWeight,
                                              }}
                                            >
                                              <a
                                                href={cta2Url}
                                                style={{
                                                  color: textColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.pLead.size,
                                                  lineHeight:
                                                    brandGuidelines.pLead
                                                      .lineHeight,
                                                  fontWeight:
                                                    brandGuidelines.pLead
                                                      .fontWeight,
                                                  textDecoration: "none",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  html={text2}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "text2",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <ReactComment text={comment3} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Content2;
