import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Text1 = (props) => {
  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    brandGuidelines,
    backgroundColor,
    textColor,
    text,
    topSpacer,
    bottomSpacer,
    blockKey,
  } = props;

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            className="cs-sm-p-20 text1Wrapper"
            bgcolor={backgroundColor}
            style={{
              backgroundColor: backgroundColor,
              paddingTop: `${topSpacer}px`,
              paddingBottom: `${bottomSpacer}px`,
            }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    valign="top"
                    align="left"
                    style={{
                      color: textColor,
                    }}
                  >
                    <ContentEditable
                      html={text}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text", event);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Text1;
