import React from "react";
import ContentEditable from "react-contenteditable";

const Cta1 = (props) => {
  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange, brandGuidelines } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { blockKey, topSpacer, bottomSpacer, title, text } = props;
  console.log(props);

  return (
    <section
      key={blockKey}
      className={`call-to-action bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-16 lg:gap-x-6 xl:gap-x-8 bg-dark-grey">
          <div className="col-span-7 flex items-center">
            <div className="pt-8 px-4 lg:p-6 xl:p-8">
              <div className="editor" style={{ color: "#ffffff" }}>
                <div
                  className="mktoText"
                  id="module-call-to-action--title"
                  mktoname="Title "
                >
                  <ContentEditable
                    html={title}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title", event);
                    }}
                  />
                </div>
              </div>
              <div
                className="editor mt-4 text-white"
                style={{ color: "#ffffff" }}
              >
                <div
                  className="mktoText"
                  id="module-call-to-action--text"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text", event);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 px-4 lg:p-0 hidden lg:block">
            <div className="h-full">
              <div
                className="mktoText h-full"
                id="module-call-to-action--image"
                mktoname="Image"
              >
                <img
                  src="https://lp.aptean.com/rs/181-TRF-125/images/chevron-arrows-primary-large.png"
                  alt=""
                  className="h-12 lg:h-16 xl:h-20"
                />
              </div>
            </div>
          </div>
          <div className="col-span-5 flex items-center justify-start lg:justify-end pb-8 px-4 lg:p-6 xl:p-8">
            <div className="btn-primary large">
              <div
                className="mktoText"
                id="module-call-to-action--button"
                mktoname="Button"
              >
                <a href="https://www.aptean.com/en-US/">Ready to learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta1;
