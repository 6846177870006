import React, { Component } from "react";
import PropTypes from "prop-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Scrollbars } from "react-custom-scrollbars";
import { sunburst } from "react-syntax-highlighter/dist/esm/styles/hljs";
import pretty from "pretty";

const COPY_TEXT = "Copy To Clipboard";
const COPIED_TEXT = "Copied!";

// eslint-disable-next-line react/prefer-stateless-function
export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { copyText: COPY_TEXT };
  }

  render() {
    const { show, children, onCloseModal } = this.props;
    const { copyText } = this.state;
    const showHideClassName = show ? { display: "block" } : { display: "none" };

    const modalStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(43, 43, 43, 0.8)",
    };

    const modalMainStyle = {
      position: "fixed",
      width: "50%",
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    };

    const copyToClipboard = (content) => {
      const temp = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(temp);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      temp.value = content;
      temp.select();
      document.execCommand("copy");
      document.body.removeChild(temp);
      this.setState({ copyText: COPIED_TEXT });
      setInterval(() => this.setState({ copyText: COPY_TEXT }), 3000);
    };

    return (
      <div style={{ ...modalStyle, ...showHideClassName }}>
        <div className="cs-card" style={modalMainStyle}>
          <div className="cs-font-semibold cs-flex cs-h-16 cs-items-center cs-space-x-2 cs-rounded-tl-xl cs-rounded-tr-xl cs-bg-grey-400 cs-px-4 cs-text-base cs-text-grey-50">
            Export Template
          </div>
          <div className="cs-h-96 cs-space-y-8 cs-overflow-y-auto">
            <SyntaxHighlighter
              language="xml"
              style={sunburst}
              wrapLines="true"
              customStyle={{ overflowX: "hidden", margin: 0, padding: 15 }}
            >
              {pretty(children)}
            </SyntaxHighlighter>
          </div>
          <div className="cs-flex cs-justify-between cs-rounded-b-lg cs-bg-grey-300 cs-p-4 lg:cs-rounded-b-xl">
            <button
              className="cs-btn-secondary"
              type="button"
              onClick={() => onCloseModal()}
            >
              Close
            </button>
            <button
              className="cs-btn-primary"
              type="button"
              onClick={() => copyToClipboard(pretty(children))}
            >
              {copyText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool,
  onCloseModal: PropTypes.func,
};
