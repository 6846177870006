import React from "react";

const SuccessMessage = (props) => {
  const { successMessage } = props;
  return (
    <div
      className="cs-flex cs-h-11 cs-items-center cs-justify-center cs-rounded-lg cs-bg-green-600 cs-px-4 cs-text-base cs-font-medium cs-text-grey-200"
      role="alert"
    >
      {successMessage}
    </div>
  );
};

export default SuccessMessage;
