import React from "react";
import ContentEditable from "react-contenteditable";

const Header3 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    title,
    text,
    image,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`header-variant-3 bg-dark-grey ${topSpacer} ${bottomSpacer}`}
    >
      <div class="px-4 md:px-8 lg:px-12 xl:px-16">
        <div class="space-y-8 lg:grid lg:grid-cols-2 lg:gap-x-12 lg:space-y-0 xl:gap-x-16">
          <div class="flex items-center">
            <div>
              <div class="lg:pr-12 xl:pr-16">
                <div className="editor">
                  <div
                    class="mktoText"
                    id="module-header-variant-3--title"
                    mktoName="Title"
                  >
                    <ContentEditable
                      html={title}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <div class="lg:w-10/12 xl:w-8/12">
                  <div className="editor">
                    <div
                      class="mktoText"
                      id="module-header-variant-3--lead-text"
                      mktoName="Lead Text"
                    >
                      <ContentEditable
                        html={text}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("text", event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="image h-110 relative">
              <div
                class="mktoText h-full"
                id="module-header-variant-3--image"
                mktoName="Image"
              >
                <img src={`${image}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header3;
