import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import iconTemplateBuilder from "../../../../images/icons/icon-drag-and-drop.png";
import iconBrandGuidelines from "../../../../images/icons/icon-brand-guidelines.png";
import iconPreview from "../../../../images/icons/icon-preview.png";
import iconSendSample from "../../../../images/icons/icon-send-sample.png";
import iconLocked from "../../../../images/icons/icon-locked.png";

const Nav = styled.div`
  display: flex;
  flex-direction: column;
`;
const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 0.875rem;
`;
const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
`;
const NavIcon = styled.div`
  display: flex;
  flex-direction: row;
  width: 35px;
`;
const NavIconImg = styled.img``;
const NavText = styled.div`
  color: #ffffff;
  font-size: 12px
  font-weight: bold;
`;
const ItemLockedImg = styled.img`
  float: right;
  width: 14px;
  margin-top: 5px;
`;
const SubNav = styled.div`
  display: flex;
  flex-direction: column;
`;
const SubNavItem = styled.div`
  padding-left: 35px;
  margin-top: 12px;
  a {
    font-size: 12px;
    color: #9aa0a9;
    font-weight: 600;
    text-decoration: none;
    &.active {
      color: #ffffff;
    }
  }
`;

const BuilderNavigation = (props) => {
  const { onVisiblePaneChange } = props;
  return (
    <Nav>
      <NavItem {...props}>
        <NavItemWrapper>
          <NavIcon>
            <NavIconImg width="24" src={iconTemplateBuilder} />
          </NavIcon>
          <NavText>TEMPLATE BUILDER</NavText>
        </NavItemWrapper>
        <SubNav>
          <SubNavItem>
            <button type="button" onClick={() => onVisiblePaneChange("Blocks")}>
              Building Blocks
            </button>
          </SubNavItem>
          <SubNavItem>
            <button
              type="button"
              onClick={() => onVisiblePaneChange("Templates")}
            >
              Templates
            </button>
          </SubNavItem>
          <SubNavItem>
            <a href="https://">Meta Data</a>
          </SubNavItem>
        </SubNav>
      </NavItem>
      <NavItem {...props}>
        <NavItemWrapper>
          <NavIcon>
            <NavIconImg width="20" src={iconBrandGuidelines} />
          </NavIcon>
          <NavText>BRAND GUIDELINES</NavText>
        </NavItemWrapper>
        <SubNav>
          <SubNavItem>
            <a href="https://">Colour Palette</a>
            <ItemLockedImg src={iconLocked} />
          </SubNavItem>
          <SubNavItem>
            <a href="https://">Typography</a>
            <ItemLockedImg src={iconLocked} />
          </SubNavItem>
          <SubNavItem>
            <a href="https://">Design Options</a>
            <ItemLockedImg src={iconLocked} />
          </SubNavItem>
        </SubNav>
      </NavItem>
      <NavItem>
        <NavItemWrapper>
          <NavIcon>
            <NavIconImg width="20" src={iconPreview} />
          </NavIcon>
          <NavText>PREVIEW</NavText>
        </NavItemWrapper>
      </NavItem>
      <NavItem>
        <NavItemWrapper>
          <NavIcon>
            <NavIconImg width="18" src={iconSendSample} />
          </NavIcon>
          <NavText>SEND SAMPLE</NavText>
        </NavItemWrapper>
      </NavItem>
    </Nav>
  );
};

BuilderNavigation.propTypes = {
  reverse: PropTypes.bool,
  onShowModal: PropTypes.func,
  onVisiblePaneChange: PropTypes.func,
};

export default BuilderNavigation;
