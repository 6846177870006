import React from "react";
import PropTypes from "prop-types";

const ReactComment = (props) => {
  const { text } = props;
  const content = `AAAAAA ${text} ZZZZZZ`;
  return (
    // eslint-disable-next-line react/no-danger
    <div
      className="aComment"
      style={{ display: "none" }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

ReactComment.propTypes = {
  text: PropTypes.string,
};

export default ReactComment;
