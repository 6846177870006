import React, { useContext } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { authContext } from "./contexts/AuthContext";

import Login from "./pages/Login";
import Changelog from "./pages/Changelog";
import Account from "./pages/Account";
import BuilderPage from "./pages/BuilderPage";
import Templates from "./pages/Templates";
import NewTemplate from "./pages/NewTemplate";
import BrandGuidelines from "./pages/BrandGuidelines";
import Error404 from "./pages/Error404";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/account" component={Account} />
        <Route path="/changelog" component={Changelog} />
        <Route path="/new-template" component={NewTemplate} />
        <Route path="/brand-guidelines" component={BrandGuidelines} />
        <Route path="/404" component={Error404} />
        <PrivateRoute path="/templates">
          <Route path="/templates" component={Templates} />
        </PrivateRoute>
        <PrivateRoute path="/builder/:id">
          <Route component={BuilderPage} />
        </PrivateRoute>
        <Route path="/" component={Login} />
        <Route path="/login" component={Login} />
      </Switch>
    </Router>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const auth = useContext(authContext);
  const user = auth.getAuthStatus();
  console.log("auth", auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
