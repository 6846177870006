import React from "react";
import ContentEditable from "react-contenteditable";

const Header1 = (props) => {
  // const {brandGuidelines} = props;
  // const { backgroundColours } = brandGuidelines;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    title,
    text,
    formTitle,
    image,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`header-variant-1 bg-dark-grey ${topSpacer} ${bottomSpacer}`}
      /* style={{ backgroundColor: backgroundColor }} */
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6 xl:gap-x-8">
          <div className="flex items-center">
            <div className="lg:pr-6 xl:pr-8">
              <div>
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-header-variant-1--title"
                    mktoname="Title"
                  >
                    <ContentEditable
                      html={title}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-header-variant-1--lead-text"
                    mktoname="Lead Text"
                  >
                    <ContentEditable
                      html={text}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 lg:mt-6 xl:mt-8 btn-primary">
                <div
                  className="mktoText"
                  id="module-header-variant-1--text"
                  mktoname="Button"
                >
                  <a href="https://www.aptean.com/en-US/" target="_blank">
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-light-grey p-4 md:p-6 xl:p-8 w-full min-h-64">
              <div className="editor text-center font-bold h2">
                <div
                  className="mktoText"
                  id="module-header-variant-1--form-title"
                  mktoname="Form Title"
                >
                  <ContentEditable
                    html={formTitle}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("formTitle", event);
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 lg:mt-6 xl:mt-8">
                <div
                  className="mktoForm"
                  id="module-header-variant-1--form"
                  mktoname="Form"
                ></div>
              </div>
            </div>
          </div>
          <div className="image relative">
            <div
              className="mktoText h-full"
              id="module-header-variant-1--image"
              mktoname="Image"
            >
              <img src={`${image}?x-timestamp=${Date.now()}`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header1;
