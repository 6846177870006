import React from "react";
import ContentEditable from "react-contenteditable";
import ReactComment from "../../../ReactComment";
import "../../../../components/themes/feedmeonline.css";

const Content6 = (props) => {
  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    brandGuidelines,
    backgroundColor,
    topSpacer,
    bottomSpacer,
    title,
    text,
    titleColor,
    textColor,
    image1,
    image2,
    image3,
    blockKey,
  } = props;

  const comment1 =
    '[if (gte mso 9)|(IE)]><table width="100%" border="0" cellSpacing="0" cellPadding="0" role="presentation"><tr><td width="33%" valign="top"><![endif]';
  const comment2 =
    '[if (gte mso 9)|(IE)]></td><td width="33%" valign="top"><![endif]';
  const comment3 = "[if (gte mso 9)|(IE)]></td></tr></table><![endif]";

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            bgcolor={backgroundColor}
            style={{
              backgroundColor: backgroundColor,
              padding: `${topSpacer}px 30px ${bottomSpacer}px 30px`,
            }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td valign="top">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      role="presentation"
                    >
                      <tbody>
                        <tr>
                          <td
                            className="cs-fb-font"
                            style={{
                              color: titleColor,
                              fontFamily: brandGuidelines.fontStack.primary,
                              fontSize: brandGuidelines.h2.size,
                              lineHeight: brandGuidelines.h2.lineHeight,
                              fontWeight: brandGuidelines.h2.fontWeight,
                              textAlign: "center",
                            }}
                            valign="top"
                          >
                            <ContentEditable
                              html={title}
                              disabled={false}
                              onChange={(event) => {
                                handleChangeFunction("title", event);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td height="10" className="cta3Spacer">
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td
                            className="cs-fb-font"
                            style={{
                              color: textColor,
                              fontFamily: brandGuidelines.fontStack.primary,
                              fontSize: brandGuidelines.pLead.size,
                              lineHeight: brandGuidelines.pLead.lineHeight,
                              fontWeight: brandGuidelines.pLead.fontWeight,
                              textAlign: "center",
                            }}
                            valign="top"
                          >
                            <ContentEditable
                              html={text}
                              disabled={false}
                              onChange={(event) => {
                                handleChangeFunction("text", event);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td height="10" className="cta3Spacer">
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      role="presentation"
                    >
                      <tbody>
                        <tr>
                          <td
                            className="cs-sm-ta-center content6ColumnContainer"
                            valign="top"
                            align="center"
                          >
                            <ReactComment text={comment1} />
                            <div className="cs-sm-mw-100pc content6Column">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="content6ContentContainer"
                                      valign="top"
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="content6ImageContainer"
                                              valign="top"
                                            >
                                              <table
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td valign="top">
                                                      <img
                                                        src={`${image1}?x-timestamp=${Date.now()}`}
                                                        width="250"
                                                        alt=""
                                                        className="cs-sm-m-0-auto content6Image"
                                                        crossOrigin="anonymous"
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <ReactComment text={comment2} />
                            <div className="cs-sm-mw-100pc content6Column">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="content6ContentContainer"
                                      valign="top"
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="content6ImageContainer"
                                              valign="top"
                                            >
                                              <table
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td valign="top">
                                                      <img
                                                        src={`${image2}?x-timestamp=${Date.now()}`}
                                                        width="250"
                                                        alt=""
                                                        className="cs-sm-m-0-auto content6Image"
                                                        crossOrigin="anonymous"
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <ReactComment text={comment2} />
                            <div className="cs-sm-mw-100pc content6Column">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="content6ContentContainer"
                                      valign="top"
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="content6ImageContainer"
                                              valign="top"
                                            >
                                              <table
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td valign="top">
                                                      <img
                                                        src={`${image3}?x-timestamp=${Date.now()}`}
                                                        width="250"
                                                        alt=""
                                                        className="cs-sm-m-0-auto content6Image"
                                                        crossOrigin="anonymous"
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <ReactComment text={comment3} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Content6;
