import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import COMPANY_BLOCKS from "../../../../blocks/";
import styled from "styled-components";

const TemplateWrapper = styled.div`
  width: 100%;
  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  [contenteditable]:hover {
    cursor: text;
  }
  img:hover {
    cursor: pointer;
  }
`;
const BlockWrapper = styled.div``;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const CanvasPane = (props) => {
  const {
    emailContent,
    onBlockClick,
    selectedBlockKey,
    onContentChange,
    exportMode,
    brandGuidelines,
    blockPath,
    type,
  } = props;

  console.log("type CP", type);

  // const auth = useContext(authContext);
  // console.log('auth', auth);
  // const brandGuidelines = auth.getBrandGuidelines();
  // const blockPath = auth.getBlockPath();
  console.log("brandGuidelines", brandGuidelines);
  console.log("blockPath", blockPath);
  // console.log('COMPANY_BLOCKS', COMPANY_BLOCKS);
  const BlockLibrary = COMPANY_BLOCKS[blockPath](brandGuidelines); // Inject the brandGuidelines into the chosen company blocks

  const onDragEnd = (result) => {
    const { emailContent, onOrderChange } = props;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      emailContent,
      result.source.index,
      result.destination.index,
    );
    onOrderChange(items);
  };

  /**
   * Function to export the email without the DnD and wrapper divs.
   */
  const renderForExport = (emailContent) => {
    const { brandGuidelines } = props;
    const listItems = emailContent.map((block, index) => {
      // Clone the element with its key
      const { id, category } = block;
      const ComponentTag = BlockLibrary[category].blocks[id].component;
      const propsToPass = {
        ...block.optionValues,
        ...block.editableContent,
        ...block.functions,
        blockKey: block.key,
      };
      return (
        <ComponentTag
          {...propsToPass}
          key={block.key}
          brandGuidelines={brandGuidelines}
        />
      );
    });

    return <React.Fragment>{listItems}</React.Fragment>;
  };

  // Check to see if it is in exportMode
  if (exportMode) {
    return renderForExport(emailContent);
  }

  let listItems = null;
  if (emailContent !== undefined) {
    listItems = emailContent.map((block, index) => {
      return (
        <Draggable key={block.key} draggableId={block.key} index={index}>
          {(provided) => {
            // Clone the element with its key
            const { id, category } = block;
            const ComponentTag = BlockLibrary[category].blocks[id].component;

            const propsToPass = {
              ...block.optionValues,
              ...block.editableContent,
              ...block.functions,
              blockKey: block.key,
              onContentChange,
            };

            if (selectedBlockKey !== block.key) {
              return (
                <BlockWrapper
                  key={block.key}
                  data-id={block.key}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onClick={() => {
                    onBlockClick(block.key);
                  }}
                >
                  <ComponentTag
                    {...propsToPass}
                    brandGuidelines={brandGuidelines}
                  />
                </BlockWrapper>
              );
            }
            return (
              <div
                className="cs-selected-block"
                key={block.key}
                data-id={block.key}
                id={block.key}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                onClick={() => {
                  onBlockClick(block.key);
                }}
              >
                <div
                  className="cs-selected-indicator"
                  data-html2canvas-ignore="false"
                >
                  <ComponentTag
                    {...propsToPass}
                    brandGuidelines={brandGuidelines}
                  />
                </div>
              </div>
            );
          }}
        </Draggable>
      );
    });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <TemplateWrapper
              id="emailCanvasPane"
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? "#27272a"
                  : "#27272a",
              }}
            >
              {listItems}
            </TemplateWrapper>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

CanvasPane.propTypes = {
  emailContent: PropTypes.array,
  onOrderChange: PropTypes.func,
  onBlockClick: PropTypes.func,
  selectedBlockKey: PropTypes.string,
  onContentChange: PropTypes.func,
  type: PropTypes.string,
};

export default CanvasPane;
