import React from "react";
import ContentEditable from "react-contenteditable";

const Image5 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    image1,
    image2,
    image3,
    backgroundColor,
    title1,
    title2,
    text,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`image-variant-7 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:gap-y-6 xl:gap-y-8 xl:gap-x-8">
          <div>
            <div className="h-52">
              <div
                className="mktoText"
                id="module-image-variant-7--image-1"
                mktoname="Image"
              >
                <img
                  src={`${image1}?x-timestamp=${Date.now()}`}
                  alt=""
                  className="w-full h-52 object-cover"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="h-52">
              <div
                className="mktoText"
                id="module-image-variant-7--image-2"
                mktoname="Image"
              >
                <img
                  src={`${image2}?x-timestamp=${Date.now()}`}
                  alt=""
                  className="w-full h-52 object-cover"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="h-52">
              <div
                className="mktoText"
                id="module-image-variant-7--image-3"
                mktoname="Image"
              >
                <img
                  src={`${image3}?x-timestamp=${Date.now()}`}
                  alt=""
                  className="w-full h-52 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Image5;
