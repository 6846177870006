import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <footer className="footer-short">
      <div className="container">
        <hr />
        <nav className="row justify-content-between align-items-center">
          <div className="col-auto">
            <ul className="list-inline text-small">
              <li className="list-inline-item">
                <Link to="/account">My Account</Link>
              </li>
              <li className="list-inline-item">
                <Link to="mailto:support@codestar.com" target="_blank">
                  Submit Ticket
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/logout">Log Out</Link>
              </li>
            </ul>
          </div>
          <div className="col-auto text-sm-right">
            <ul className="list-inline text-small">
              <li className="list-inline-item">
                <Link to="/changelog">Changelog</Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className="row">
          <div className="col">
            <small>&copy; 2022 CodeStar. All Rights Reserved.</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
