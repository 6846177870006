import React from "react";
import "../../../../components/themes/feedmeonline.css";

const Header2 = (props) => {
  const { ctaUrl, blockKey, brandGuidelines } = props;
  const { primaryLogo, greyscaleColours } = brandGuidelines;

  return (
    <table
      key={blockKey}
      width="100%"
      border="0"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            class="cs-sm-p-20 cs-xs-p-10 header2Wrapper"
            bgcolor={greyscaleColours[0]}
            valign="top"
            style={{ backgroundColor: greyscaleColours[0] }}
          >
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <a
                      href={ctaUrl}
                      className="header2IconLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`${primaryLogo}?x-timestamp=${Date.now()}`}
                        width="134"
                        alt=""
                        className="header2LogoImage"
                        crossOrigin="anonymous"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Header2;
