import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AuthContext from "./contexts/AuthContext";

require("dotenv").config();

window.addEventListener("paste", function (e) {
  // cancel paste
  e.preventDefault();

  // get text representation of clipboard
  var text = (e.originalEvent || e).clipboardData.getData("text/plain");

  // insert text manually
  document.execCommand("insertHTML", false, text);
});

ReactDOM.render(
  <AuthContext>
    <App />
  </AuthContext>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
