import React from "react";
import { Link } from "react-router-dom";

const NoEmailsMessage = () => {
  return (
    <section className="height-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="card card-lg text-center">
              <div className="card-body">
                <i className="icon-add-to-list display-4 opacity-20"></i>
                <h5>You haven't built any templates yet</h5>
                <p className="mb-3">
                  Click the button below to create your very first email
                  template using the CodeStar drag and drop builder.
                </p>
                <Link to="/new-template" className="btn btn-secondary">
                  New Template
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoEmailsMessage;
