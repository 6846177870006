import FMO from "./fmo";
import Enfocus from "./enfocus";
import Aptean from "./aptean";

const COMPANY_BLOCKS = {
  enfocus: Enfocus,
  fmo: FMO,
  aptean: Aptean,
};

export default COMPANY_BLOCKS;
