import React, { Component } from "react";

// eslint-disable-next-line react/prefer-stateless-function
export default class ModalSettings extends Component {
  render() {
    const {
      show,
      onCloseModal,
      onSaveTemplate,
      name,
      type,
      favicon,
      title,
      extraHeader,
      extraFooter,
      setName,
      setType,
      setTitle,
      setFavicon,
      setExtraHeader,
      setExtraFooter,
    } = this.props;

    const showHideClassName = show ? { display: "block" } : { display: "none" };

    const modalStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(43, 43, 43, 0.8)",
    };

    const modalMainStyle = {
      position: "fixed",
      width: "50%",
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    };

    return (
      <div style={{ ...modalStyle, ...showHideClassName }}>
        <div className="cs-card" style={{ ...modalMainStyle }}>
          <div className="cs-flex cs-h-16 cs-items-center cs-space-x-2 cs-rounded-tl-xl cs-rounded-tr-xl cs-bg-grey-400 cs-px-4 cs-text-base cs-font-semibold cs-text-grey-50">
            Template Settings
          </div>
          <div className="cs-h-96 cs-space-y-8 cs-overflow-y-auto cs-p-4">
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="name">Template Name:</label>
              </div>
              <div>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  id="name"
                />
              </div>
            </div>
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="templateType">Template Type:</label>
              </div>
              <div className="cs-flex cs-gap-10">
                <div className="cs-inline-flex cs-items-center cs-space-x-2">
                  <label
                    className="cs-relative cs-flex cs-cursor-pointer cs-items-center cs-rounded-full"
                    htmlFor="templateTypeEmail"
                  >
                    <input
                      type="radio"
                      name="templateType"
                      value="email"
                      onChange={() => setType("email")}
                      checked={type !== "landingpage" ? "checked" : ""}
                      id="email"
                      className="cs-peer cs-relative cs-h-5 cs-w-5 cs-cursor-pointer cs-appearance-none cs-rounded-full cs-border cs-border-grey-300 cs-text-grey-400 cs-transition-all checked:cs-border-accent checked:before:cs-bg-accent"
                    />
                    <span className="cs-pointer-events-none cs-absolute cs-left-2/4 cs-top-2/4 -cs-translate-x-2/4 -cs-translate-y-2/4 cs-text-accent cs-opacity-0 cs-transition-opacity peer-checked:cs-opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-3.5 cs-w-3.5"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <circle
                          data-name="ellipse"
                          cx="8"
                          cy="8"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="cs-mt-px cs-cursor-pointer cs-select-none cs-text-grey-200"
                    htmlFor="templateTypeEmail"
                  >
                    Email
                  </label>
                </div>
                <div className="cs-inline-flex cs-items-center cs-space-x-2">
                  <label
                    className="cs-relative cs-flex cs-cursor-pointer cs-items-center cs-rounded-full"
                    htmlFor="templateTypeLandingPage"
                  >
                    <input
                      type="radio"
                      name="templateType"
                      value="landingpage"
                      onChange={() => setType("landingpage")}
                      checked={type === "landingpage" ? "checked" : ""}
                      id="landingpage"
                      className="cs-peer cs-relative cs-h-5 cs-w-5 cs-cursor-pointer cs-appearance-none cs-rounded-full cs-border cs-border-grey-300 cs-text-grey-400 cs-transition-all checked:cs-border-accent checked:before:cs-bg-accent"
                    />
                    <span className="cs-pointer-events-none cs-absolute cs-left-2/4 cs-top-2/4 -cs-translate-x-2/4 -cs-translate-y-2/4 cs-text-accent cs-opacity-0 cs-transition-opacity peer-checked:cs-opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs-h-3.5 cs-w-3.5"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <circle
                          data-name="ellipse"
                          cx="8"
                          cy="8"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                  </label>
                  <label
                    className="cs-mt-px cs-cursor-pointer cs-select-none cs-text-grey-200"
                    htmlFor="templateTypeLandingPage"
                  >
                    Landing Page
                  </label>
                </div>
              </div>
            </div>
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="title">Page Title:</label>
              </div>
              <div>
                <input
                  type="text"
                  name="title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  value={title}
                  id="title"
                />
              </div>
            </div>
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="favicon">Fav Icon Url:</label>
              </div>
              <div>
                <input
                  type="text"
                  name="favicon"
                  value={favicon}
                  onChange={(e) => {
                    setFavicon(e.target.value);
                  }}
                  id="favicon"
                />
              </div>
            </div>
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="extraHeader">Extra Header Content:</label>
              </div>
              <div>
                <textarea
                  type="text"
                  name="extraHeader"
                  onChange={(e) => {
                    setExtraHeader(e.target.value);
                  }}
                  id="extraHeader"
                >
                  {extraHeader}
                </textarea>
              </div>
            </div>
            <div className="cs-space-y-2">
              <div>
                <label htmlFor="extraFooter">Extra Footer Content:</label>
              </div>
              <div>
                <textarea
                  type="text"
                  name="extraFooter"
                  onChange={(e) => {
                    setExtraFooter(e.target.value);
                  }}
                  id="extraFooter"
                >
                  {extraFooter}
                </textarea>
              </div>
            </div>
          </div>
          <div className="cs-flex cs-justify-between cs-rounded-b-lg cs-bg-grey-400 cs-p-4 lg:cs-rounded-b-xl">
            <button
              className="cs-btn-outline"
              type="button"
              onClick={() => onCloseModal()}
            >
              Close
            </button>
            <button
              className="cs-btn-primary"
              type="button"
              onClick={() => onSaveTemplate()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}
