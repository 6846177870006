import random from "random-key-generator";

const API_URL = process.env.REACT_APP_API_URL;
const DEFAULT_USER = {
  _id: 0,
  email: "",
  firstname: "",
  lastname: "",
  company: "",
  authenticated: 0,
};
const BRAND_GUIDELINES = {
  primaryLogo:
    "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/logo-feedmeonline-primary.png",
  secondaryLogo:
    "https://builderassets.s3.eu-west-1.amazonaws.com/feedmeonline/logo/logo-feedmeonline-primary.png",
  primaryColours: ["#EC0024"],
  greyscaleColours: ["#FFFFFF", "#e7e7e7", "#818181", "#424242"],
  fontStack: {
    primary: "'Open Sans', Helvetica, Arial, sans-serif",
  },
  h1: {
    size: "36px",
    lineHeight: "40px",
    fontWeight: 700,
  },
  h2: {
    size: "28px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  h3: {
    size: "22px",
    lineHeight: "26px",
    fontWeight: 600,
  },
  pLead: {
    size: "18px",
    lineHeight: "28px",
    fontWeight: 400,
  },
  p: {
    size: "14px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  buttonMedium: {
    size: "15px",
    fontWeight: 700,
  },
};

const DEFAULT_BLOCKS = [
  {
    id: random(),
    title: "Header 1",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 2",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 3",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 4",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 5",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 6",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Hero 1",
    category: "Hero",
    description: "A basic hero",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Hero 2",
    category: "Hero",
    description: "A basic hero",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Hero 3",
    category: "Hero",
    description: "A basic hero",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 1",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 2",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 3",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 4",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Lead Text 1",
    category: "Lead Text",
    description: "A lead text block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Lead Text 2",
    category: "Lead Text",
    description: "A lead text block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Text 1",
    category: "Text",
    description: "A text block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 2",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 3",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 4",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 5",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 6",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 1",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 2",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 3",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 4",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 5",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 6 poo",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
];

const FMO_BLOCKS = [
  {
    id: random(),
    title: "Header 1",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 2",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 3",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Header 4",
    category: "Header",
    description: "A basic header",
    image: "myheader1.png",
  },
  // {
  //   id: random(),
  //   title: "Header 5",
  //   category: "Header",
  //   description: "A basic header",
  //   image: "myheader1.png",
  // },
  // {
  //   id: random(),
  //   title: "Header 6",
  //   category: "Header",
  //   description: "A basic header",
  //   image: "myheader1.png",
  // },
  {
    id: random(),
    title: "Hero 1",
    category: "Hero",
    description: "A basic hero",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Hero 2",
    category: "Hero",
    description: "A basic hero",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Hero 3",
    category: "Hero",
    description: "A basic hero",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 1",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 2",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 3",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Title 4",
    category: "Title",
    description: "A title block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Lead Text 1",
    category: "Lead Text",
    description: "A lead text block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Lead Text 2",
    category: "Lead Text",
    description: "A lead text block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Text 1",
    category: "Text",
    description: "A text block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 2",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 3",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 4",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 5",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "Content 6",
    category: "Content",
    description: "A content block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 1",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 2",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 3",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 4",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 5",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
  {
    id: random(),
    title: "CTA 6 poo",
    category: "CTA",
    description: "A cta block",
    image: "myheader1.png",
  },
];

const DEFAULT_BLOCK_PATH = "default";

const PANE_BLOCKS = "PANE_BLOCKS";
const PANE_OPTIONS = "PANE_OPTIONS";

export {
  API_URL,
  DEFAULT_USER,
  BRAND_GUIDELINES,
  DEFAULT_BLOCKS,
  PANE_BLOCKS,
  PANE_OPTIONS,
  FMO_BLOCKS,
  DEFAULT_BLOCK_PATH,
};
