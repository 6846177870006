import React from "react";
import styled from "styled-components";
import { font } from "styled-theme";
import random from "random-key-generator";
import TextOption from "../../../../components/elements/Options/TextOption";
import { PANE_BLOCKS } from "../../../../Constants";

const OptionButtonWithText = styled.button`
  border-radius: 4px;
  width: 100%;
  height: 24px;
  margin-right: 5px;
  background-color: #404040;
  color: #ffffff;
  font-family: ${font("primary")};
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  :last-child {
    margin-right: 0;
  }
  border: 0px;
  :focus {
    outline: 0px solid transparent;
    border: 0px;
  }
  :hover {
    cursor: pointer;
  }
`;

const BlockOptions = (props) => {
  const renderOption = (optionKey, group, option) => {
    const { block, onOptionUpdate, selectedBlockOptions } = props;
    // Get the current option value from the block
    const blockKey = block.key;
    const currentValue = selectedBlockOptions.options[group][optionKey];
    const { value } = currentValue;
    if (
      optionKey === "backgroundColor" ||
      optionKey === "buttonColor" ||
      optionKey === "textLinkColor" ||
      optionKey === "textColor" ||
      optionKey === "headerColor" ||
      optionKey === "subTitleColor" ||
      optionKey === "titleColor" ||
      optionKey === "text1Color" ||
      optionKey === "text2Color" ||
      optionKey === "ctaButtonColor" ||
      optionKey === "ctaTextColor" ||
      optionKey === "buttonTextColor" ||
      optionKey === "dividerColor"
    ) {
      if (Array.isArray(option.values)) {
        return option.values.map((value) => {
          // See if there is a selected value
          const selected = currentValue.value === value;
          const style = {
            backgroundColor: value,
          };
          if (selected) {
            //style.border = "1px solid #009AFF";
          }
          return (
            <button
              key={value}
              onClick={() => onOptionUpdate(block.key, optionKey, group, value)}
              className="cs-aspect-square cs-h-full cs-w-full cs-rounded-lg cs-outline-none cs-ring-2 cs-ring-grey-500 cs-ring-offset-2 cs-ring-offset-grey-300"
              style={style}
            ></button>
          );
        });
      }
    } else if (
      optionKey === "buttonUrl" ||
      optionKey === "image" ||
      optionKey === "image1" ||
      optionKey === "image2" ||
      optionKey === "image3" ||
      optionKey === "image4" ||
      optionKey === "backgroundImage" ||
      optionKey === "imageUrl" ||
      optionKey === "ctaUrl" ||
      optionKey === "ctaUrl1" ||
      optionKey === "ctaUrl2" ||
      optionKey === "ctaUrl3" ||
      optionKey === "ctaUrl4" ||
      optionKey === "videoUrl" ||
      optionKey === "socialLink1" ||
      optionKey === "socialLink2" ||
      optionKey === "socialLink3" ||
      optionKey === "socialLink4" ||
      optionKey === "textLink1" ||
      optionKey === "textLink2"
    ) {
      return (
        // TextOption({
        //   key: blockKey,
        //   blockKey,
        //   onOptionUpdate: onOptionUpdate,
        //   group: group,
        //   optionKey: optionKey,
        //   value: value,
        // })
        <TextOption
          key={block.key}
          blockKey={block.key}
          onOptionUpdate={onOptionUpdate}
          group={group}
          optionKey={optionKey}
          value={currentValue.value}
        />
      );
    } else if (
      optionKey === "topSpacer" ||
      optionKey === "bottomSpacer" ||
      optionKey === "blockPadding"
    ) {
      if (Array.isArray(option.values)) {
        return option.values.map((value) => {
          // See if there is a selected value
          const selected = currentValue.value === value;
          const style = {};
          if (selected) {
            style.backgroundColor = "#009AFF";
          }
          return (
            <OptionButtonWithText
              key={value}
              style={style}
              onClick={() => onOptionUpdate(block.key, optionKey, group, value)}
            >
              {value}
            </OptionButtonWithText>
          );
        });
      }
    }
    return null;
  };

  const renderDelete = () => {
    const { block, onDeleteBlock } = props;
    if (block === null) {
      return false;
    }
    return (
      <div key="delete" className="cs-p-4">
        <button
          className="cs-btn-secondary sm"
          key="deleteButton"
          onClick={() => onDeleteBlock(block.key)}
        >
          Delete Module
        </button>
      </div>
    );
  };

  const renderOptions = (id, options) => {
    const renderedOptions = [];
    Object.keys(options).forEach((group) => {
      renderedOptions.push(
        <div key={group} className="cs-border-b cs-border-grey-200">
          <div className="cs-flex cs-h-12.5 cs-items-center cs-justify-between cs-space-x-2 cs-px-4">
            <div>
              <h3 class="cs-font-semibold cs-text-white">{group}</h3>
            </div>
          </div>
        </div>,
      );
      const groupOptions = [];
      Object.keys(options[group]).forEach((optionKey) => {
        const opt = options[group][optionKey];
        groupOptions.push(
          <>
            <div
              key={`options-${id}`}
              className="cs-flex cs-flex-col cs-space-y-2 cs-border-b cs-border-grey-200 cs-p-4"
            >
              <div className="cs-text-xs cs-text-grey-50">{opt.label}</div>
              {optionKey === "backgroundColor" ||
              optionKey === "buttonColor" ||
              optionKey === "buttonTextColor" ||
              optionKey === "textLinkColor" ||
              optionKey === "textColor" ||
              optionKey === "headerColor" ||
              optionKey === "subTitleColor" ||
              optionKey === "titleColor" ||
              optionKey === "text1Color" ||
              optionKey === "text2Color" ||
              optionKey === "ctaButtonColor" ||
              optionKey === "ctaTextColor" ||
              optionKey === "dividerColor" ? (
                <div class="cs-grid cs-h-12 cs-w-full cs-grid-cols-6 cs-items-center cs-gap-3 cs-rounded-lg cs-border cs-border-grey-200 cs-bg-grey-400 cs-p-2 cs-text-base cs-font-medium cs-text-grey-200 cs-placeholder-grey-400 cs-outline-none cs-transition-all cs-duration-300 hover:cs-border-grey-200 focus-visible:cs-border focus-visible:cs-border-grey-200 focus-visible:cs-bg-grey-400">
                  {renderOption(optionKey, group, opt)}
                </div>
              ) : (
                renderOption(optionKey, group, opt)
              )}
            </div>
          </>,
        );
      });
      renderedOptions.push(groupOptions); // Add the group of options to the header ready to embed in a div
    });
    return renderedOptions;
  };

  const { block, onVisiblePaneChange } = props;
  const { id } = block;
  let options = null;
  if (
    block !== null &&
    block.options !== undefined &&
    Object.keys(block.options).length > 0
  ) {
    // Render the options
    options = renderOptions(id, block.options);
  }

  if (block === null) {
    return false;
  }

  return (
    <div>
      <div className="cs-relative cs-h-full cs-w-72 cs-border-r cs-border-grey-200 cs-bg-grey-300 xl:cs-w-96">
        <div className="cs-flex cs-h-16 cs-items-center cs-justify-between cs-space-x-4 cs-border-b cs-border-grey-200 cs-p-4">
          <div className="cs-font-semibold cs-text-base cs-text-white">
            Module Options
          </div>
          <button
            onClick={() => {
              onVisiblePaneChange(PANE_BLOCKS);
            }}
            className="cs-flex cs-h-10 cs-w-10 cs-items-center cs-justify-center cs-rounded-xl cs-bg-grey-400 cs-transition-colors cs-duration-200 hover:cs-bg-grey-400"
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cs-h-5 cs-w-5 cs-text-white"
            >
              <path
                d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <div className="cs-absolute cs-bottom-0 cs-left-0 cs-right-0 cs-top-16 cs-h-[calc(100vh-10.5rem)] cs-overflow-y-auto">
          <div className="cs-flex cs-flex-col">
            {options}
            {renderDelete()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockOptions;
