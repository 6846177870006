import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { authContext } from "../contexts/AuthContext";

const PreHeader = (props) => {
  const auth = useContext(authContext);
  const user = auth.getAuthStatus();

  const displayWelcome = () => {
    const { firstname, lastname } = user;
    return (
      <span>
        {firstname} {lastname}
      </span>
    );
  };

  const displayHeaderLogo = () => {
    const { companyLogo } = user;
    return companyLogo ? (
      <img src={companyLogo} alt="Feed Me Online Logo" />
    ) : null;
  };

  return (
    <div className="pre-header d-flex align-items-center bg-dark navbar-dark">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  id="myAccountDropdown"
                  data-toggle="dropdown"
                >
                  {user.isAuthenticated ? displayWelcome() : null}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="myAccountDropdown"
                >
                  <Link to="/account" className="dropdown-item">
                    My Account
                  </Link>
                  <Link to="/logout" className="dropdown-item">
                    Log Out
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link
                  to="mailto:support@codestar.com"
                  className="nav-link"
                  target="_blank"
                >
                  Submit Ticket
                </Link>
              </li>
            </ul>
          </div>
          {displayHeaderLogo()}
        </nav>
      </div>
    </div>
  );
};

export default PreHeader;
