import React from "react";
import ContentEditable from "react-contenteditable";

const Feature6 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    title1,
    title2,
    title3,
    title4,
    text1,
    text2,
    text3,
    text4,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`feature-variant-7 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-16 lg:gap-x-12 xl:gap-x-16">
          <div className="col-span-9">
            <div className="lg:pr-12 xl:pr-16">
              <div className="editor">
                <div
                  className="mktoText"
                  id="module-feature-variant-7--title"
                  mktoname="Title"
                >
                  <ContentEditable
                    html={title1}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title1", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="editor">
                <div
                  className="mktoText"
                  id="module-feature-variant-7--text"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text1}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text1", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 lg:mt-6 xl:mt-8 btn-secondary ${module-feature-variant-7--button-toggle}">
              <div
                className="mktoText"
                id="module-feature-variant-7--button"
                mktoname="Button"
              >
                <a href="https://www.aptean.com/en-US/">Download</a>
              </div>
            </div>
          </div>
          <div className="col-span-7 flex flex-col space-y-4">
            <div className="relative p-4 lg:p-6 xl:p-8 border border-light-grey">
              <div className="w-9/12">
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-feature-variant-7--title-1"
                    mktoname="Feature Title"
                  >
                    <ContentEditable
                      html={title2}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title2", event);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4 lg:mt-6 xl:mt-8">
                  <div className="editor">
                    <div
                      className="mktoText"
                      id="module-feature-variant-7--text-1"
                      mktoname="Feature Text"
                    >
                      <ContentEditable
                        html={text2}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("text2", event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-20 overflow-hidden inline-block absolute top-0 right-0">
                <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                  <div
                    className="mktoText"
                    id="module-feature-variant-7--button-1"
                    mktoname="Button"
                  >
                    <a href="https://www.aptean.com/en-US/" target="_blank">
                      <img
                        src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative p-4 lg:p-6 xl:p-8 border border-light-grey">
              <div className="w-9/12">
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-feature-variant-7--title-2"
                    mktoname="Feature Title"
                  >
                    <ContentEditable
                      html={title3}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title3", event);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4 lg:mt-6 xl:mt-8">
                  <div className="editor">
                    <div
                      className="mktoText"
                      id="module-feature-variant-7--text-2"
                      mktoname="Feature Text"
                    >
                      <ContentEditable
                        html={text3}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("text3", event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-20 overflow-hidden inline-block absolute top-0 right-0">
                <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                  <div
                    className="mktoText"
                    id="module-feature-variant-7--button-2"
                    mktoname="Button"
                  >
                    <a href="https://www.aptean.com/en-US/" target="_blank">
                      <img
                        src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative p-4 lg:p-6 xl:p-8 border border-light-grey">
              <div className="w-9/12">
                <div className="editor">
                  <div
                    className="mktoText"
                    id="module-feature-variant-7--title-3"
                    mktoname="Feature Title"
                  >
                    <ContentEditable
                      html={title4}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title4", event);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4 lg:mt-6 xl:mt-8">
                  <div className="editor">
                    <div
                      className="mktoText"
                      id="module-feature-variant-7--text-3"
                      mktoname="Feature Text"
                    >
                      <ContentEditable
                        html={text4}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("text4", event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-20 overflow-hidden inline-block absolute top-0 right-0">
                <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                  <div
                    className="mktoText"
                    id="module-feature-variant-7--button-3"
                    mktoname="Button"
                  >
                    <a href="https://www.aptean.com/en-US/" target="_blank">
                      <img
                        src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature6;
