// Main
import React from "react";

// Components to make up the page
import { PageTemplate } from "../components";

const Error404 = () => {
  return (
    <PageTemplate>
      <section className="space-sm">
        <div className="container align-self-start">
          <div className="row mb-5">
            <div className="col text-center">
              <img
                alt="CodeStar Logo"
                src="https://codestar-assets.s3.eu-west-2.amazonaws.com/img/logos/CodeStar/logo-gray.svg"
                width="45"
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7">
              <div className="card card-lg text-center">
                <div className="card-body">
                  <h1 className="h2 mb-2">Page Not Found (404)</h1>
                  <span>The page you requested could not be found.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};

export default Error404;
