import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Text3 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    backgroundColor,
    titleColor,
    textColor,
    title,
    text,
    topSpacer,
    bottomSpacer,
    blockKey,
  } = props;

  return (
    <section
      key={blockKey}
      className={`richtext-variant-3 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div>
          <div className="editor text-center">
            <div
              className="mktoText"
              id="module-richtext-variant-3--title"
              mktoname="Title"
            >
              <ContentEditable
                html={title}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("title", event);
                }}
              />
            </div>
          </div>
          <div className="editor mt-4 text-center">
            <div
              className="mktoText"
              id="module-richtext-variant-3--text"
              mktoname="Text"
            >
              <ContentEditable
                html={text}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("text", event);
                }}
              />
            </div>
          </div>
          <div className="mt-4 lg:mt-6 xl:mt-8 text-center btn-secondary ${module-richtext-variant-3--button-toggle}">
            <div
              className="mktoText"
              id="module-richtext-variant-3--button"
              mktoname="Button"
            >
              <a href="https://www.aptean.com/en-US/">Download</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Text3;
