import React from "react";
import "../../../../components/themes/feedmeonline.css";

const Header1 = (props) => {
  const { ctaUrl, blockKey, brandGuidelines } = props;
  const { primaryLogo, primaryColours } = brandGuidelines;

  return (
    <table
      key={blockKey}
      width="100%"
      border="0"
      cellPadding="0"
      cellSpacing="0"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            class="cs-sm-p-20 cs-xs-p-10 header1Wrapper"
            bgcolor={primaryColours[0]}
            style={{ backgroundColor: primaryColours[0] }}
            valign="top"
          >
            <table
              width="100%"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <a
                      href={ctaUrl}
                      className="header1IconLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`${primaryLogo}?x-timestamp=${Date.now()}`}
                        width="134"
                        alt=""
                        className="header1LogoImage"
                        crossOrigin="anonymous"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Header1;
