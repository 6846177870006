import Header1 from "./header/header1/Header1";
import Feature1 from "./feature/feature1/Feature1";
import Feature2 from "./feature/feature2/Feature2";
import Feature3 from "./feature/feature3/Feature3";
import Feature4 from "./feature/feature4/Feature4";
import Feature5 from "./feature/feature5/Feature5";
import Feature6 from "./feature/feature6/Feature6";
import Feature7 from "./feature/feature7/Feature7";
import Feature8 from "./feature/feature8/Feature8";
import Feature9 from "./feature/feature9/Feature9";

const BlockLibrary = (brandGuidelines) => {
  const blockLibrary = {
    header: {
      name: "Header",
      blocks: {
        header1: {
          ComponentName: "Header1",
          id: "header1",
          title: "Header 1",
          category: "header",
          description: "A basic header",
          image: require("./header/header1/thumb.png"),
          component: Header1,
          optionValues: {
            ctaUrl: "https://www.enfocus.com/en",
          },
          options: {
            "Header Logo": {
              ctaUrl: {
                label: "Link Address:",
                value: "https://www.enfocus.com/en",
              },
            },
          },
        },
      },
    },
    feature: {
      name: "Features",
      blocks: {
        feature1: {
          ComponentName: "Feature1",
          id: "feature1",
          title: "Feature 1",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature1/thumb.png"),
          component: Feature1,
          editableContent: {
            title: "<h1>Title</h1>",
            text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p>",
            formTitle: "<h2>Title</h2>",
            formText:
              "<p>Leave us your contact details, and a certified Enfocus partner will contact you to discuss your automation journey.</p>",
          },
          optionValues: {
            backgroundColor: brandGuidelines.primaryColours[0],
            videoUrl: "https://www.youtube.com/embed/niS3F67VvRI",
          },
          options: {
            backgroundColor: {
              label: "Background Colour",
              values: [brandGuidelines.primaryColours[0]],
            },
            Video: {
              videoUrl: {
                label: "Video URL:",
                value: "https://www.youtube.com/embed/niS3F67VvRI",
              },
            },
          },
        },
        feature2: {
          ComponentName: "Feature2",
          id: "feature2",
          title: "Feature 2",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature2/thumb.png"),
          component: Feature2,
          editableContent: {
            title: "<h1>Title</h1>",
            subTitle: "<h3>Subtitle</h3>",
            text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p><p>In eu mi placerat, blandit ipsum nec, condimentum massa. Duis gravida et nisi sed interdum. Mauris lacinia sapien interdum eros fringilla bibendum.</p>",
            formTitle: "<h2>Title</h2>",
            formText:
              "<p>Leave us your contact details, and a certified Enfocus partner will contact you to discuss your automation journey.</p>",
          },
          optionValues: {},
          options: {},
        },
        feature3: {
          ComponentName: "Feature3",
          id: "feature3",
          title: "Feature 3",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature3/thumb.png"),
          component: Feature3,
          editableContent: {
            title: "<h2>Title</h2>",
            text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p>",
          },
          optionValues: {
            image1: "https://placehold.co/664x332/F8F7F5/000000",
          },
          options: {
            Image: {
              image1: {
                label: "Image URL:",
                value: "https://placehold.co/664x332/F8F7F5/000000",
              },
            },
          },
        },
        feature4: {
          ComponentName: "Feature4",
          id: "feature4",
          title: "Feature 4",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature4/thumb.png"),
          component: Feature4,
          editableContent: {
            title: "<h2>Title</h2>",
            text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p>",
          },
          optionValues: {
            image1: "https://placehold.co/664x332/F8F7F5/000000",
          },
          options: {
            Image: {
              image1: {
                label: "Image URL:",
                value: "https://placehold.co/664x332/F8F7F5/000000",
              },
            },
          },
        },
        feature5: {
          ComponentName: "Feature5",
          id: "feature5",
          title: "Feature 5",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature5/thumb.png"),
          component: Feature5,
          editableContent: {
            title1: "<h2>Title</h2>",
            title2: "<h2>Title</h2>",
            subTitle1: "<h3>Subtitle</h3>",
            subTitle2: "<h3>Subtitle</h3>",
            text1:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p>",
            ctaButtonText: "Get a demo",
          },
          optionValues: {
            image1: "https://placehold.co/664x332/F8F7F5/000000",
            image2: "https://placehold.co/664x332/F8F7F5/000000",
          },
          options: {
            Images: {
              image1: {
                label: "Image 1 URL:",
                value: "https://placehold.co/664x332/F8F7F5/000000",
              },
              image2: {
                label: "Image 2 URL:",
                value: "https://placehold.co/664x332/F8F7F5/000000",
              },
            },
            Button: {
              buttonUrl: {
                label: "Button Link Address",
                value: "https://www.enfocus.com/en",
              },
            },
          },
        },
        feature6: {
          ComponentName: "Feature6",
          id: "feature6",
          title: "Feature 6",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature6/thumb.png"),
          component: Feature6,
          editableContent: {
            title1: "<h2>Title</h2>",
            title2: "<h2>Title</h2>",
            subTitle1: "<h3>Subtitle</h3>",
            subTitle2: "<h3>Subtitle</h3>",
            text1:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p>",
            ctaButtonText: "Get a demo",
          },
          optionValues: {},
          options: {
            Button: {
              buttonUrl: {
                label: "Button Link Address",
                value: "https://www.enfocus.com/en",
              },
            },
          },
        },
        feature7: {
          ComponentName: "Feature7",
          id: "feature7",
          title: "Feature 7",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature7/thumb.png"),
          component: Feature7,
          editableContent: {
            title1: "<h2>Title</h2>",
            title2: "<h2>Title</h2>",
            subTitle1: "<h3>Subtitle</h3>",
            subTitle2: "<h3>Subtitle</h3>",
            text1:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p>",
            text2:
              "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p><p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur neque lectus, porttitor id ipsum in, lobortis pulvinar lacus. Praesent lacinia orci a ligula sagittis feugiat.</p>",
          },
          optionValues: {},
          options: {},
        },
        feature8: {
          ComponentName: "Feature8",
          id: "feature8",
          title: "Feature 8",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature8/thumb.png"),
          component: Feature8,
          editableContent: {
            title: "<h2>Title</h2>",
            subTitle: "<h3>Subtitle</h3>",
            text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec tellus quis dolor tincidunt scelerisque. Etiam eget metus in odio dictum ultricies. Praesent luctus urna dui, vel placerat tortor viverra vel.</p>",
            ctaButtonText: "Get a demo",
          },
          optionValues: {},
          options: {
            Button: {
              buttonUrl: {
                label: "Button Link Address",
                value: "https://www.enfocus.com/en",
              },
            },
          },
        },
        feature9: {
          ComponentName: "Feature9",
          id: "feature9",
          title: "Feature 9",
          category: "feature",
          description: "A basic header",
          image: require("./feature/feature9/thumb.png"),
          component: Feature9,
          editableContent: {
            formTitle: "<h2>Title</h2>",
            formText:
              "<p>Leave us your contact details, and a certified Enfocus partner will contact you to discuss your automation journey.</p>",
          },
          optionValues: {},
          options: {},
        },
      },
    },
  };
  return blockLibrary;
};

export default BlockLibrary;
