import React, { Component } from "react";
import PropTypes from "prop-types";
import random from "random-key-generator";
import iconArrowRight from "../../../images/icons/icon-arrow-right.png";
import iconArrowDown from "../../../images/icons/icon-arrow-down.png";

import "../../../styles.css";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;
    const { children } = this.props;
    return (
      <div
        key={random()}
        onClick={onClick}
        className="cs-cursor-pointer cs-border-b cs-border-grey-300"
      >
        <div className="cs-flex cs-h-12.5 cs-items-center cs-justify-between cs-space-x-2 cs-px-4">
          <div>
            <h3 className="cs-font-semibold cs-text-white">{label}</h3>
          </div>
          <div>
            <button
              onClick={onClick}
              className="cs-flex cs-h-8 cs-w-8 cs-items-center cs-justify-center cs-rounded-lg cs-bg-grey-400 cs-transition-colors cs-duration-200 hover:cs-bg-grey-400 focus-visible:cs-outline-none"
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`cs-h-7 cs-w-7 cs-text-grey-50 ${
                  isOpen ? "cs-rotate-180" : ""
                }`}
              >
                <path
                  d="M16.9999 9.17C16.8126 8.98375 16.5591 8.87921 16.2949 8.87921C16.0308 8.87921 15.7773 8.98375 15.5899 9.17L11.9999 12.71L8.45995 9.17C8.27259 8.98375 8.01913 8.87921 7.75495 8.87921C7.49076 8.87921 7.23731 8.98375 7.04995 9.17C6.95622 9.26297 6.88183 9.37357 6.83106 9.49543C6.78029 9.61729 6.75415 9.74799 6.75415 9.88C6.75415 10.012 6.78029 10.1427 6.83106 10.2646C6.88183 10.3864 6.95622 10.497 7.04995 10.59L11.2899 14.83C11.3829 14.9237 11.4935 14.9981 11.6154 15.0489C11.7372 15.0997 11.8679 15.1258 11.9999 15.1258C12.132 15.1258 12.2627 15.0997 12.3845 15.0489C12.5064 14.9981 12.617 14.9237 12.7099 14.83L16.9999 10.59C17.0937 10.497 17.1681 10.3864 17.2188 10.2646C17.2696 10.1427 17.2957 10.012 17.2957 9.88C17.2957 9.74799 17.2696 9.61729 17.2188 9.49543C17.1681 9.37357 17.0937 9.26297 16.9999 9.17Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          className={
            isOpen ? "cs-block cs-space-y-4 cs-px-4 cs-pb-4" : "cs-hidden"
          }
        >
          {children}
        </div>
      </div>
    );
  }
}

export default AccordionSection;
