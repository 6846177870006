import { useState } from "react";

import {
  DEFAULT_USER,
  BRAND_GUIDELINES,
  DEFAULT_BLOCKS,
  DEFAULT_BLOCK_PATH,
} from "../Constants";

const useAuthHandler = (initialState) => {
  const [auth, setAuth] = useState(initialState);

  const setAuthStatus = (userAuth) => {
    window.localStorage.setItem("userAuth", JSON.stringify(userAuth));
    setAuth(userAuth);
  };

  const setUnauthStatus = () => {
    window.localStorage.clear();
    setAuth(DEFAULT_USER);
  };

  const getAuthStatus = () => {
    return auth;
  };

  const getBrandGuidelines = () => {
    const { brandGuidelines } = auth;
    console.log('brandGuidelines', brandGuidelines);
    if (brandGuidelines) {
      return brandGuidelines;
    } else {
      return BRAND_GUIDELINES;
    }
  };

  const getBlockPermissions = () => {
    const { blocks } = auth;
    if (blocks.length > 0) {
      return blocks;
    } else {
      return DEFAULT_BLOCKS;
    }
  };

  const getBlockPath = () => {
    const { blockPath } = auth;
    console.log("gettingBlockPath", blockPath);
    if (blockPath.length > 0) {
      return blockPath;
    } else {
      return DEFAULT_BLOCK_PATH;
    }
  };

  return {
    auth,
    setAuthStatus,
    setUnauthStatus,
    getAuthStatus,
    getBlockPath,
    getBrandGuidelines,
    getBlockPermissions,
  };
};

export default useAuthHandler;
