import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/feedmeonline.css";

const Cta3 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    backgroundColor,
    titleColor,
    textColor,
    title,
    text,
    ctaUrl1,
    ctaButtonColor,
    ctaButtonText,
    topSpacer,
    bottomSpacer,
    blockKey,
  } = props;

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            bgcolor={backgroundColor}
            style={{
              backgroundColor: backgroundColor,
              padding: `${topSpacer}px 20px ${bottomSpacer}px 20px`,
            }}
            valign="top"
            align="center"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: titleColor,
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.h2.size,
                      lineHeight: brandGuidelines.h2.lineHeight,
                      fontWeight: brandGuidelines.h2.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={title}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="10" className="cta3Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: textColor,
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.pLead.size,
                      lineHeight: brandGuidelines.pLead.lineHeight,
                      fontWeight: brandGuidelines.pLead.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={text}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="30" className="cta3Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    bgcolor={ctaButtonColor}
                    style={{
                      backgroundColor: ctaButtonColor,
                      padding: "15px 17px",
                      borderRadius: "6px",
                    }}
                    valign="top"
                    align="center"
                  >
                    <a
                      href={ctaUrl1}
                      style={{
                        color: "#ffffff",
                        fontFamily: brandGuidelines.fontStack.primary,
                        fontSize: brandGuidelines.buttonMedium.size,
                        fontWeight: brandGuidelines.buttonMedium.fontWeight,
                        lineHeight: "1.5",
                        textDecoration: "none",
                        wordBreak: "break-word",
                        display: "block",
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <ContentEditable
                        tagName="span"
                        html={ctaButtonText}
                        disabled={false}
                        onChange={(event) => {
                          handleChangeFunction("ctaButtonText", event);
                        }}
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Cta3;
