// https://github.com/diegohaz/arc/wiki/Atomic-Design test
import React, { useContext } from "react";
import { PageTemplate } from "../components";
import PreHeader from "../components/PreHeader";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";

import { authContext } from "../contexts/AuthContext";

const BrandGuidelines = () => {
  const auth = useContext(authContext);
  const brandGuidelines = auth.getBrandGuidelines();

  const renderColour = (colour) => {
    return (
      <li class="col-sm-6 col-md-3 col-lg-2">
        <div
          class="colour-item rounded mb-3"
          style={{ backgroundColor: colour }}
        ></div>
        <span>{colour.toUpperCase()}</span>
      </li>
    );
  };

  return (
    <PageTemplate
      preHeader={<PreHeader />}
      header={<Header />}
      titleBar={<TitleBar title="Brand Guidelines" />}
      footer={<Footer />}
    >
      <section class="flush-with-above space-sm pt-5">
        <div class="container">
          <div class="row justify-content-start mb-3">
            <div class="col-auto">
              <h4>Primary Palette</h4>
            </div>
          </div>
          <div class="row no-gutters justify-content-start">
            <div class="col-12 col-lg-12">
              <ul class="feature-list row justify-content-start">
                {brandGuidelines.primaryColours.map((colour) => {
                  return renderColour(colour);
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="flush-with-above space-sm">
        <div class="container">
          <div class="row justify-content-start mb-3">
            <div class="col-auto">
              <h4>Greyscale Palette</h4>
            </div>
          </div>
          <div class="row no-gutters justify-content-start">
            <div class="col-12 col-lg-12">
              <ul class="feature-list row justify-content-start">
                {brandGuidelines.greyscaleColours.map((colour) => {
                  return renderColour(colour);
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="flush-with-above space-sm">
        <div class="container">
          <div class="row justify-content-start mb-3">
            <div class="col-auto">
              <h4>Background Colours</h4>
            </div>
          </div>
          <div class="row no-gutters justify-content-start">
            <div class="col-12 col-lg-12">
              <ul class="feature-list row justify-content-start">
                {brandGuidelines.backgroundColours.map((colour) => {
                  return renderColour(colour);
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};

export default BrandGuidelines;
