import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/aptean/styles-aptean.css";

const Feature8 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const { blockKey, subTitle, text, ctaButtonText, buttonUrl } = props;

  return (
    <section key={blockKey} class="bg-white py-6 lg:py-12">
      <div class="relative px-4 lg:container lg:mx-auto lg:px-20">
        <div class="grid md:grid-cols-12 md:gap-x-8 lg:gap-x-12">
          <div class="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <div>
              <div
                class="wysiwyg-subtitle"
                pardot-region="Feature-Variant-8--Subtitle"
              >
                <ContentEditable
                  html={subTitle}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("subTitle", event);
                  }}
                />
              </div>
            </div>
            <div class="mt-6 lg:mt-8">
              <div
                class="wysiwyg-text-base"
                pardot-region="Feature-Variant-8--Text"
              >
                <ContentEditable
                  html={text}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text", event);
                  }}
                />
              </div>
            </div>
            <div class="mt-8 lg:mt-10">
              <div
                class="wysiwyg-button"
                pardot-region="Feature-Variant-8--Button"
              >
                <a href={buttonUrl} target="_blank">
                  <ContentEditable
                    html={ctaButtonText}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("ctaButtonText", event);
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature8;
