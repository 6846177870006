import React from "react";
import ContentEditable from "react-contenteditable";
import Text5 from "../../text/text5/Text5";

const Feature8 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    image1,
    image2,
    image3,
    title1,
    title2,
    title3,
    title4,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9,
    text10,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`feature-variant-9 bg-light-grey ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div>
          <div className="editor text-center">
            <div
              className="mktoText"
              id="module-feature-variant-9--title"
              mktoname="Title"
            >
              <ContentEditable
                html={title1}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("title1", event);
                }}
              />
            </div>
          </div>
          <div className="mt-4 text-center text-black">
            <div className="editor">
              <div
                className="mktoText"
                id="module-feature-variant-9--text"
                mktoname="Text"
              >
                <ContentEditable
                  html={text1}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text1", event);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 lg:mt-6 xl:mt-8 space-y-4 md:space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:gap-y-6 xl:gap-y-8 xl:gap-x-8">
          <div className="bg-white border border-dark-grey/20">
            <div className="h-52">
              <div
                className="mktoText"
                id="module-feature-variant-9--image-1"
                mktoname="Image"
              >
                <img src={`${image1}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
            <div className="p-4 lg:p-6 xl:p-8">
              <div className="flex space-x-4">
                <div className="editor text-black text-xs">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--sub-title-1"
                    mktoname="Sub-Title"
                  >
                    <ContentEditable
                      html={text2}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text2", event);
                      }}
                    />
                  </div>
                </div>
                <div className="editor text-black text-xs">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--sub-date-1"
                    mktoname="Date"
                  >
                    <ContentEditable
                      html={text3}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text3", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="editor mt-2 text-black">
                <div
                  className="mktoText"
                  id="module-feature-variant-9--title-1"
                  mktoname="Feature Title"
                >
                  <ContentEditable
                    html={title2}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title2", event);
                    }}
                  />
                </div>
              </div>
              <div className="editor mt-4 text-black text-sm">
                <div
                  className="mktoText"
                  id="module-feature-variant-9--text-1"
                  mktoname="Feature Text"
                >
                  <ContentEditable
                    html={text4}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text4", event);
                    }}
                  />
                </div>
              </div>
              <div className="mt-12 lg:mt-16 xl:mt-20">
                <div className="btn-download">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--button-1"
                    mktoname="Button"
                  >
                    <a href="https://www.aptean.com/en-US/">Download</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white border border-dark-grey/20">
            <div className="h-52">
              <div
                className="mktoText"
                id="module-feature-variant-9--image-2"
                mktoname="Image"
              >
                <img src={`${image2}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
            <div className="p-4 lg:p-6 xl:p-8">
              <div className="flex space-x-4">
                <div className="editor text-black text-xs">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--sub-title-2"
                    mktoname="Sub-Title"
                  >
                    <ContentEditable
                      html={text5}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text5", event);
                      }}
                    />
                  </div>
                </div>
                <div className="editor text-black text-xs">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--sub-date-2"
                    mktoname="Date"
                  >
                    <ContentEditable
                      html={text6}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text6", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="editor mt-2 text-black">
                <div
                  className="mktoText"
                  id="module-feature-variant-9--title-2"
                  mktoname="Feature Title"
                >
                  <ContentEditable
                    html={title3}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title3", event);
                    }}
                  />
                </div>
              </div>
              <div className="editor mt-4 text-black text-sm">
                <div
                  className="mktoText"
                  id="module-feature-variant-9--text-2"
                  mktoname="Feature Text"
                >
                  <ContentEditable
                    html={text7}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text7", event);
                    }}
                  />
                </div>
              </div>
              <div className="mt-12 lg:mt-16 xl:mt-20">
                <div className="btn-download">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--button-2"
                    mktoname="Button"
                  >
                    <a href="https://www.aptean.com/en-US/">Download</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white border border-dark-grey/20">
            <div className="h-52">
              <div
                className="mktoText"
                id="module-feature-variant-9--image-3"
                mktoname="Image"
              >
                <img src={`${image3}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
            <div className="p-4 lg:p-6 xl:p-8">
              <div className="flex space-x-4">
                <div className="editor text-black text-xs">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--sub-title-3"
                    mktoname="Sub-Title"
                  >
                    <ContentEditable
                      html={text8}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text8", event);
                      }}
                    />
                  </div>
                </div>
                <div className="editor text-black text-xs">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--sub-date-3"
                    mktoname="Date"
                  >
                    <ContentEditable
                      html={text9}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text9", event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="editor mt-2 text-black">
                <div
                  className="mktoText"
                  id="module-feature-variant-9--title-3"
                  mktoname="Feature Title"
                >
                  <ContentEditable
                    html={title4}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title4", event);
                    }}
                  />
                </div>
              </div>
              <div className="editor mt-4 text-black text-sm">
                <div
                  className="mktoText"
                  id="module-feature-variant-9--text-3"
                  mktoname="Feature Text"
                >
                  <ContentEditable
                    html={text10}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text10", event);
                    }}
                  />
                </div>
              </div>
              <div className="mt-12 lg:mt-16 xl:mt-20">
                <div className="btn-download">
                  <div
                    className="mktoText"
                    id="module-feature-variant-9--button-3"
                    mktoname="Button"
                  >
                    <a href="https://www.aptean.com/en-US/">Download</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature8;
