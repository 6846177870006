import React from "react";
import ContentEditable from "react-contenteditable";

const Feature7 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    title7,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`feature-variant-8 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-16 lg:gap-x-12 xl:gap-x-16">
          <div className="col-span-9">
            <div className="lg:pr-12 xl:pr-16">
              <div className="editor">
                <div
                  className="mktoText"
                  id="module-feature-variant-8--title"
                  mktoname="Title"
                >
                  <ContentEditable
                    html={title1}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title1", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="editor">
                <div
                  className="mktoText"
                  id="module-feature-variant-8--text"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text1}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text1", event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 lg:mt-6 xl:mt-8 btn-secondary ${module-feature-variant-8--button-toggle}">
              <a href="https://www.aptean.com/en-US/">Download</a>
            </div>
          </div>
          <div className="col-span-7 flex flex-col space-y-4">
            <div className="group [perspective:1000px]">
              <div className="h-full relative border border-dark-grey/20 w-full p-4 lg:p-6 xl:p-8 transition-all duration-500 bg-white [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="overflow-hidden">
                  <div className="w-11/12">
                    <div className="editor">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--title-1"
                        mktoname="Title"
                      >
                        <ContentEditable
                          html={title2}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("title2", event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 xl:mt-8 text-black">
                      <div className="editor">
                        <div
                          className="mktoText"
                          id="module-feature-variant-8--text-1"
                          mktoname="Text"
                        >
                          <ContentEditable
                            html={text2}
                            disabled={false}
                            onChange={(event) => {
                              handleChangeFunction("text2", event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-20 overflow-hidden absolute top-0 right-0 group-hover:opacity-0 duration-500 transition-opacity">
                    <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                      <img
                        src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute shadow-lg inset-0 h-full w-full bg-primary p-4 lg:p-6 xl:p-8 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                  <div className="w-11/12">
                    <div className="editor">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--flipped-title-1"
                        mktoname="Flipped Title"
                      >
                        <ContentEditable
                          html={title3}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("title3", event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 xl:mt-8 text-black">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--flipped-text-1"
                        mktoname="Flipped Text"
                      >
                        <ContentEditable
                          html={text3}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("text3", event);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-20 overflow-hidden inline-block absolute top-0 right-0">
                    <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--button-1"
                        mktoname="Button"
                      >
                        <a href="https://www.aptean.com/en-US/" target="_blank">
                          <img
                            src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group [perspective:1000px]">
              <div className="h-full relative border border-dark-grey/20 w-full p-4 lg:p-6 xl:p-8 transition-all duration-500 bg-white [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="overflow-hidden">
                  <div className="w-11/12">
                    <div className="editor">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--title-2"
                        mktoname="Title"
                      >
                        <ContentEditable
                          html={title4}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("title4", event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 xl:mt-8 text-black">
                      <div className="editor">
                        <div
                          className="mktoText"
                          id="module-feature-variant-8--text-2"
                          mktoname="Text"
                        >
                          <ContentEditable
                            html={text4}
                            disabled={false}
                            onChange={(event) => {
                              handleChangeFunction("text4", event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-20 overflow-hidden absolute top-0 right-0 group-hover:opacity-0 duration-500 transition-opacity">
                    <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                      <img
                        src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute shadow-lg inset-0 h-full w-full bg-primary p-4 lg:p-6 xl:p-8 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                  <div className="w-11/12">
                    <div className="editor">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--flipped-title-2"
                        mktoname="Flipped Title"
                      >
                        <ContentEditable
                          html={title5}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("title5", event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 xl:mt-8 text-black">
                      <div className="editor">
                        <div
                          className="mktoText"
                          id="module-feature-variant-8--flipped-text-2"
                          mktoname="Flipped Text"
                        >
                          <ContentEditable
                            html={text5}
                            disabled={false}
                            onChange={(event) => {
                              handleChangeFunction("text5", event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-20 overflow-hidden inline-block absolute top-0 right-0">
                    <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--button-2"
                        mktoname="Button"
                      >
                        <a href="https://www.aptean.com/en-US/" target="_blank">
                          <img
                            src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group [perspective:1000px]">
              <div className="h-full relative border border-dark-grey/20 w-full p-4 lg:p-6 xl:p-8 transition-all duration-500 bg-white [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="overflow-hidden">
                  <div className="w-11/12">
                    <div className="editor">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--title-3"
                        mktoname="Title"
                      >
                        <ContentEditable
                          html={title6}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("title6", event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 xl:mt-8 text-black">
                      <div className="editor">
                        <div
                          className="mktoText"
                          id="module-feature-variant-8--text-3"
                          mktoname="Text"
                        >
                          <ContentEditable
                            html={text6}
                            disabled={false}
                            onChange={(event) => {
                              handleChangeFunction("text6", event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-20 overflow-hidden absolute top-0 right-0 group-hover:opacity-0 duration-500 transition-opacity">
                    <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                      <img
                        src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute shadow-lg inset-0 h-full w-full bg-primary p-4 lg:p-6 xl:p-8 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                  <div className="w-11/12">
                    <div className="editor">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--flipped-title-3"
                        mktoname="Flipped Title"
                      >
                        <ContentEditable
                          html={title7}
                          disabled={false}
                          onChange={(event) => {
                            handleChangeFunction("title7", event);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6 xl:mt-8 text-black">
                      <div className="editor">
                        <div
                          className="mktoText"
                          id="module-feature-variant-8--flipped-text-3"
                          mktoname="Flipped Text"
                        >
                          <ContentEditable
                            html={text7}
                            disabled={false}
                            onChange={(event) => {
                              handleChangeFunction("text7", event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-20 overflow-hidden inline-block absolute top-0 right-0">
                    <div className="relative h-32 bg-black -rotate-45 transform origin-top-left">
                      <div
                        className="mktoText"
                        id="module-feature-variant-8--button-3"
                        mktoname="Button"
                      >
                        <a href="https://www.aptean.com/en-US/" target="_blank">
                          <img
                            src="https://lp.aptean.com/rs/181-TRF-125/images/icon-arrows-primary.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature7;
