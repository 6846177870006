import React from "react";
import ContentEditable from "react-contenteditable";
import ReactComment from "../../../ReactComment";
import "../../../../components/themes/feedmeonline.css";

const Content3 = (props) => {
  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    brandGuidelines,
    backgroundColor,
    titleColor,
    textColor,
    ctaButtonColor,
    ctaTextColor,
    title,
    text,
    cta1Url,
    cta2Url,
    cta3Url,
    cta4Url,
    image1,
    image2,
    image3,
    image4,
    item1Title,
    item2Title,
    item3Title,
    item4Title,
    item1SubTitle,
    item2SubTitle,
    item3SubTitle,
    item4SubTitle,
    item1Text,
    item2Text,
    item3Text,
    item4Text,
    blockKey,
  } = props;

  const comment1 =
    '[if (gte mso 9)|(IE)]><table width="100%" border="0" cellSpacing="0" cellPadding="0" role="presentation"><tr><td width="50%" valign="top"><![endif]';
  const comment2 =
    '[if (gte mso 9)|(IE)]></td><td width="50%" valign="top"><![endif]';
  const comment3 = "[if (gte mso 9)|(IE)]></td></tr></table><![endif]";

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            className="content3Wrapper"
            bgcolor={backgroundColor}
            style={{ backgroundColor: backgroundColor }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: titleColor,
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.h2.size,
                      lineHeight: brandGuidelines.h2.lineHeight,
                      fontWeight: brandGuidelines.h2.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={title}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="10" className="content3Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: textColor,
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.pLead.size,
                      lineHeight: brandGuidelines.pLead.lineHeight,
                      fontWeight: brandGuidelines.pLead.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={text}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="20" className="content3Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td className="content3ColumnContainer" valign="top">
                    <ReactComment text={comment1} />
                    <div className="cs-sm-mw-50pc cs-xs-mw-100pc content3Column">
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="content3ContentContainer"
                              valign="top"
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <a
                                        href={cta1Url}
                                        className="content3ImageLink"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img
                                          src={`${image1}?x-timestamp=${Date.now()}`}
                                          width="100"
                                          height=""
                                          alt=""
                                          className="content3Image"
                                          crossOrigin="anonymous"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="13" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: titleColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.h3.size,
                                        lineHeight:
                                          brandGuidelines.h3.lineHeight,
                                        fontWeight:
                                          brandGuidelines.h3.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item1Title}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item1Title",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="6" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: textColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.p.size,
                                        lineHeight:
                                          brandGuidelines.p.lineHeight,
                                        fontWeight:
                                          brandGuidelines.p.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item1SubTitle}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item1SubTitle",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="12" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              bgcolor={ctaButtonColor}
                                              style={{
                                                backgroundColor: ctaButtonColor,
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                              }}
                                              valign="top"
                                              align="center"
                                            >
                                              <a
                                                href={cta1Url}
                                                style={{
                                                  color: ctaTextColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.p.size,
                                                  fontWeight:
                                                    brandGuidelines.p
                                                      .fontWeight,
                                                  lineHeight: "1.5",
                                                  textDecoration: "none",
                                                  wordBreak: "break-word",
                                                  display: "block",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  tagName="span"
                                                  html={item1Text}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "item1Text",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ReactComment text={comment2} />
                    <div className="cs-sm-mw-50pc cs-xs-mw-100pc content3Column">
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="content3ContentContainer"
                              valign="top"
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <a
                                        href={cta2Url}
                                        className="content3ImageLink"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img
                                          src={`${image2}?x-timestamp=${Date.now()}`}
                                          width="100"
                                          height=""
                                          alt=""
                                          className="content3Image"
                                          crossOrigin="anonymous"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="13" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: titleColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.h3.size,
                                        lineHeight:
                                          brandGuidelines.h3.lineHeight,
                                        fontWeight:
                                          brandGuidelines.h3.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item2Title}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item2Title",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="6" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: textColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.p.size,
                                        lineHeight:
                                          brandGuidelines.p.lineHeight,
                                        fontWeight:
                                          brandGuidelines.p.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item2SubTitle}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item2SubTitle",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="12" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              bgcolor={ctaButtonColor}
                                              style={{
                                                backgroundColor: ctaButtonColor,
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                              }}
                                              valign="top"
                                              align="center"
                                            >
                                              <a
                                                href={cta2Url}
                                                style={{
                                                  color: ctaTextColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.p.size,
                                                  fontWeight:
                                                    brandGuidelines.p
                                                      .fontWeight,
                                                  lineHeight: "1.5",
                                                  textDecoration: "none",
                                                  wordBreak: "break-word",
                                                  display: "block",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  tagName="span"
                                                  html={item2Text}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "item2Text",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ReactComment text={comment3} />
                  </td>
                </tr>
                <tr>
                  <td className="content3ColumnContainer" valign="top">
                    <ReactComment text={comment1} />
                    <div className="cs-sm-mw-50pc cs-xs-mw-100pc content3Column">
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="content3ContentContainer"
                              valign="top"
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <a
                                        href={cta3Url}
                                        className="content3ImageLink"
                                      >
                                        <img
                                          src={image3}
                                          width="100"
                                          height=""
                                          alt=""
                                          className="content3Image"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="13" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: titleColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.h3.size,
                                        lineHeight:
                                          brandGuidelines.h3.lineHeight,
                                        fontWeight:
                                          brandGuidelines.h3.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item3Title}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item3Title",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="6" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: textColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.p.size,
                                        lineHeight:
                                          brandGuidelines.p.lineHeight,
                                        fontWeight:
                                          brandGuidelines.p.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item3SubTitle}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item3SubTitle",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="12" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              bgcolor={ctaButtonColor}
                                              style={{
                                                backgroundColor: ctaButtonColor,
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                              }}
                                              valign="top"
                                              align="center"
                                            >
                                              <a
                                                href={cta3Url}
                                                style={{
                                                  color: ctaTextColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.p.size,
                                                  fontWeight:
                                                    brandGuidelines.p
                                                      .fontWeight,
                                                  lineHeight: "1.5",
                                                  textDecoration: "none",
                                                  wordBreak: "break-word",
                                                  display: "block",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  tagName="span"
                                                  html={item3Text}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "item3Text",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ReactComment text={comment2} />
                    <div className="cs-sm-mw-50pc cs-xs-mw-100pc content3Column">
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="content3ContentContainer"
                              valign="top"
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                role="presentation"
                              >
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <a
                                        href={cta4Url}
                                        className="content3ImageLink"
                                      >
                                        <img
                                          src={image4}
                                          width="100"
                                          height=""
                                          alt=""
                                          className="content3Image"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="13" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: titleColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.h3.size,
                                        lineHeight:
                                          brandGuidelines.h3.lineHeight,
                                        fontWeight:
                                          brandGuidelines.h3.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item4Title}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item4Title",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="6" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td
                                      className="cs-fb-font"
                                      style={{
                                        color: textColor,
                                        fontFamily:
                                          brandGuidelines.fontStack.primary,
                                        fontSize: brandGuidelines.p.size,
                                        lineHeight:
                                          brandGuidelines.p.lineHeight,
                                        fontWeight:
                                          brandGuidelines.p.fontWeight,
                                        textAlign: "center",
                                      }}
                                      valign="top"
                                      align="center"
                                    >
                                      <ContentEditable
                                        html={item4SubTitle}
                                        disabled={false}
                                        onChange={(event) => {
                                          handleChangeFunction(
                                            "item4SubTitle",
                                            event,
                                          );
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td height="12" className="content3Spacer">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td valign="top" align="center">
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              bgcolor={ctaButtonColor}
                                              style={{
                                                backgroundColor: ctaButtonColor,
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                              }}
                                              valign="top"
                                              align="center"
                                            >
                                              <a
                                                href={cta4Url}
                                                style={{
                                                  color: ctaTextColor,
                                                  fontFamily:
                                                    brandGuidelines.fontStack
                                                      .primary,
                                                  fontSize:
                                                    brandGuidelines.p.size,
                                                  fontWeight:
                                                    brandGuidelines.p
                                                      .fontWeight,
                                                  lineHeight: "1.5",
                                                  textDecoration: "none",
                                                  wordBreak: "break-word",
                                                  display: "block",
                                                }}
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <ContentEditable
                                                  tagName="span"
                                                  html={item4Text}
                                                  disabled={false}
                                                  onChange={(event) => {
                                                    handleChangeFunction(
                                                      "item4Text",
                                                      event,
                                                    );
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ReactComment text={comment3} />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default Content3;
