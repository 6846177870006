// https://github.com/diegohaz/arc/wiki/Atomic-Design test
import React from "react";

import { PageTemplate } from "../components";
import PreHeader from "../components/PreHeader";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";

const Changelog = () => {
  return (
    <PageTemplate
      preHeader={<PreHeader />}
      header={<Header />}
      titleBar={<TitleBar title="Changelog" />}
      footer={<Footer />}
    >
      <section>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-2">
              Changelog <span class="badge badge-secondary">v1.0.1</span>
            </div>
            <div class="col-12 col-md-8">
              <article id="1-0-1">
                <h3>
                  <p>
                    <code>1.0.1</code> - 5 May 2020
                  </p>
                </h3>
                <div>
                  <ul>
                    <li>Fixed issues with the export feature</li>
                  </ul>
                </div>
              </article>

              <article id="1-0-0">
                <h3>
                  <p>
                    <code>1.0.0</code> - 1 May 2020
                  </p>
                </h3>
                <div>
                  <ul>
                    <li>Initial release</li>
                  </ul>
                </div>
              </article>
            </div>
            <div class="col">
              <nav
                id="nav-components"
                class="nav flex-md-column"
                data-sticky="below-nav"
              >
                <a data-smooth-scroll href="#1-0-1" class="nav-link text-small">
                  1.0.1
                </a>
                <a data-smooth-scroll href="#1-0-0" class="nav-link text-small">
                  1.0.0
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};

export default Changelog;
