import React from "react";
import ContentEditable from "react-contenteditable";

const Feature2 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    backgroundColor,
    image1,
    image2,
    image3,
    title1,
    title2,
    title3,
    text1,
    text2,
    text3,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      className={`feature-variant-3 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div className="px-4 md:px-8 lg:px-12 xl:px-16">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-8 lg:gap-x-6 lg:gap-y-6 xl:gap-y-8 xl:gap-x-8 place-items-center">
          <div>
            <div>
              <div
                className="mktoText"
                id="module-feature-variant-3--image-1"
                mktoname="Image"
              >
                <img src={`${image1}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
            <div className="mt-4 lg:mt-6 xl:mt-8">
              <div className="editor text-center">
                <div
                  className="mktoText"
                  id="module-feature-variant-3--title-1"
                  mktoname="Title"
                >
                  <ContentEditable
                    html={title1}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title1", event);
                    }}
                  />
                </div>
              </div>
              <div className="editor mt-2 text-center">
                <div
                  className="mktoText"
                  id="module-feature-variant-3--text-1"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text2}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text2", event);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className="mktoText"
                id="module-feature-variant-3--image-2"
                mktoname="Image"
              >
                <img src={`${image2}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
            <div className="mt-4 lg:mt-6 xl:mt-8">
              <div className="editor text-center">
                <div
                  className="mktoText"
                  id="module-feature-variant-3--title-2"
                  mktoname="Title"
                >
                  <ContentEditable
                    html={title2}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title2", event);
                    }}
                  />
                </div>
              </div>
              <div className="editor mt-2 text-center">
                <div
                  className="mktoText"
                  id="module-feature-variant-3--text-2"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text2}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text2", event);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className="mktoText"
                id="module-feature-variant-3--image-3"
                mktoname="Image"
              >
                <img src={`${image3}?x-timestamp=${Date.now()}`} alt="" />
              </div>
            </div>
            <div className="mt-4 lg:mt-6 xl:mt-8">
              <div className="editor text-center">
                <div
                  className="mktoText"
                  id="module-feature-variant-3--title-3"
                  mktoname="Title"
                >
                  <ContentEditable
                    html={title3}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("title3", event);
                    }}
                  />
                </div>
              </div>
              <div className="editor mt-2 text-center">
                <div
                  className="mktoText"
                  id="module-feature-variant-3--text-3"
                  mktoname="Text"
                >
                  <ContentEditable
                    html={text3}
                    disabled={false}
                    onChange={(event) => {
                      handleChangeFunction("text3", event);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature2;
