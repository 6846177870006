import React from "react";
import ContentEditable from "react-contenteditable";

const Image1 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const {
    blockKey,
    topSpacer,
    bottomSpacer,
    image,
    backgroundColor,
    title1,
    title2,
    text,
    buttonText,
    buttonUrl,
    titleColor,
    textColor,
    buttonColor,
    buttonTextColor,
    imageUrl,
  } = props;

  return (
    <section
      key={blockKey}
      class={`image-variant-1 bg-white ${topSpacer} ${bottomSpacer}`}
    >
      <div>
        <div
          class="mktoText"
          id="module-image-variant-1--image"
          mktoname="Image"
        >
          <img src={`${image}?x-timestamp=${Date.now()}`} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Image1;
