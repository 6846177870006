import React from "react";
import ContentEditable from "react-contenteditable";
import "../../../../components/themes/aptean/styles-aptean.css";

const Feature7 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const { backgroundColours } = brandGuidelines;
  const { blockKey, title1, title2, subTitle1, subTitle2, text1, text2 } =
    props;

  return (
    <section key={blockKey} class="bg-white py-6 lg:py-12">
      <div class="relative px-4 lg:container lg:mx-auto lg:px-20">
        <div class="grid gap-y-12 md:grid-cols-2 md:gap-y-0 md:gap-x-8 lg:gap-x-12">
          <div>
            <div
              class="wysiwyg-title"
              pardot-region="Feature-Variant-7--Title-1"
            >
              <ContentEditable
                html={title1}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("title1", event);
                }}
              />
            </div>
            <div class="mt-8 lg:mt-10">
              <div
                class="wysiwyg-subtitle"
                pardot-region="Feature-6-Subtitle-1"
              >
                <ContentEditable
                  html={subTitle1}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("subTitle1", event);
                  }}
                />
              </div>
            </div>
            <div class="mt-6 lg:mt-8">
              <div
                class="wysiwyg-text-base"
                pardot-region="Feature-Variant-7--Text-1"
              >
                <ContentEditable
                  html={text1}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text1", event);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div
              class="wysiwyg-title"
              pardot-region="Feature-Variant-7--Title-1"
            >
              <ContentEditable
                html={title2}
                disabled={false}
                onChange={(event) => {
                  handleChangeFunction("title2", event);
                }}
              />
            </div>
            <div class="mt-8 lg:mt-10">
              <div
                class="wysiwyg-subtitle"
                pardot-region="Feature-Variant-7--Subtitle-1"
              >
                <ContentEditable
                  html={subTitle2}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("subTitle2", event);
                  }}
                />
              </div>
            </div>
            <div class="mt-6 lg:mt-8">
              <div
                class="wysiwyg-text-base"
                pardot-region="Feature-Variant-7--Text-1"
              >
                <ContentEditable
                  html={text2}
                  disabled={false}
                  onChange={(event) => {
                    handleChangeFunction("text2", event);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature7;
