// https://github.com/diegohaz/arc/wiki/Atomic-Design test
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../helper/api";
import useErrorHandler from "../hooks/errorHandler";
import useMessageHandler from "../hooks/messageHandler";
import { authContext } from "../contexts/AuthContext";

import { PageTemplate } from "../components";
import TitleBar from "../components/TitleBar";
import ErrorMessage from "../components/SystemMessages/ErrorMessage";
import SuccessMessage from "../components/SystemMessages/SuccessMessage";

const Account = () => {
  const { error, showPersistentError } = useErrorHandler(null);
  const { successMessage, showSuccessMessage } = useMessageHandler(null);
  const auth = useContext(authContext);
  const user = auth.getAuthStatus();

  const history = useHistory();

  const [, setLoading] = useState(false);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [company, setCompany] = useState(user.company);
  const [website, setWebsite] = useState(user.website);
  const [email, setEmail] = useState(user.email);
  const [landline, setLandline] = useState(user.landline);
  const [mobile, setMobile] = useState(user.mobile);
  const [newPassword, setNewPassword] = useState("");

  const [css, setCss] = useState(user.css === undefined ? [] : user.css);
  const [extraHeader, setExtraHeader] = useState(user.extraHeader === undefined ? '' : user.extraHeader);
  const [extraFooter, setExtraFooter] = useState(user.extraFooter === undefined ? '' : user.extraFooter);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      setLoading(true);
      // TODO Add some validation here for it all.
      const details = {
        firstname,
        lastname,
        company,
        website,
        landline,
        mobile,
        email,
        css,
        extraHeader,
        extraFooter,
      };
      // TODO Validate new password here
      if (newPassword !== "") {
        details["password"] = newPassword;
      }
      const response = await apiRequest("user/" + user._id, "PUT", details);
      // TODO Make the match the 'success' response
      if (!response.success) {
        setLoading(false);
        const { errorCode } = response;
        if (errorCode === 401) {
          history.replace("/login");
        } else {
          showPersistentError(
            "Unable to save account details: " + response.error,
          );
        }
      } else {
        // Update the user with the new details
        auth.setAuthStatus({ ...user, ...details, isAuthenticated: true });
        showSuccessMessage("Account Details Updated Successfully :)");
      }
    } catch (error) {
      setLoading(false);
      showPersistentError(error.message);
    }
  };

  const updateCss = (idx, field, value) => {
    css[idx][field] = value;
    setCss([...css]);
  };
  console.log(css);
  return (
    <PageTemplate titleBar={<TitleBar title="My Account" />}>
      <div className="cs-card">
        <div className="cs-card-content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="cs-space-y-8"
          >
            <div className="cs-space-y-2">
              <label for="first-name">First Name:</label>
              <input
                type="text"
                name="firstName"
                defautValue={firstname}
                value={firstname}
                id=""
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="lastName">Last Name:</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                defaultValue={lastname}
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="company">Company:</label>
              <input
                type="text"
                name="company"
                id="company"
                defaultValue={company}
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="website">Website:</label>
              <input
                type="text"
                name="website"
                id="website"
                defaultValue={website}
                value={website}
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="email">Email:</label>
              <input
                type="email"
                name="email"
                id="email"
                defaultValue={email}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="telephone">Telephone:</label>
              <input
                type="text"
                name="telephone"
                id="telephone"
                defaultValue={landline}
                value={landline}
                onChange={(e) => {
                  setLandline(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="mobile">Mobile:</label>
              <input
                type="text"
                name="mobile"
                id="mobile"
                defaultValue={mobile}
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="mobile">Change Password:</label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                defaultValue={newPassword}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
            <div className="cs-space-y-2">
              <label for="">CSS Files:</label>
              <div className="cs-space-y-4">
                {css.map((c, idx) => {
                  const { name, description, value } = c;
                  return (
                    <div
                      key={idx}
                      className="cs-grid cs-gap-4 lg:cs-grid-cols-3"
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => {
                            updateCss(idx, "name", e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="URL to the CSS file"
                          value={value}
                          onChange={(e) => {
                            updateCss(idx, "value", e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Description (Optional)"
                          value={description}
                          onChange={(e) => {
                            updateCss(idx, "description", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCss([
                        ...css,
                        { name: "", value: "", description: "" },
                      ]);
                    }}
                    className="cs-btn-outline"
                  >
                    Add New CSS File
                  </button>
                </div>
              </div>
            </div>
            <div className="cs-space-y-2">
              <label for="mobile">Extra Header Content:</label>
              <textarea
                name="extraHeader"
                id="extraHeader"
                onChange={(e) => {
                  setExtraHeader(e.target.value);
                }}>
                  {extraHeader}
              </textarea>
              
            </div>
            <div className="cs-space-y-2">
              <label for="mobile">Extra Footer Content:</label>
              <textarea
                name="extraFooter"
                id="extraFooter"
                onChange={(e) => {
                  setExtraFooter(e.target.value);
                }}
                defaultValue={extraFooter}
                value={extraFooter}/>              
            </div>
            <div>
              {error ? <ErrorMessage errorMessage={error} /> : null}
              {successMessage ? (
                <SuccessMessage successMessage={successMessage} />
              ) : null}
            </div>
            <div>
              <button type="submit">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </PageTemplate>
  );
};

export default Account;
