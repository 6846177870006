import React, { Component } from "react";

export default class Block extends Component {
  render() {
    const { name, image, onClick } = this.props;
    return (
      <div
        onClick={onClick}
        className="cs-cursor-pointer cs-rounded-lg cs-bg-grey-400 cs-p-2"
      >
        <img
          src={image}
          alt={name}
          draggable="false"
          className="duration-500 cs-h-auto cs-w-full cs-rounded-md cs-transition-all hover:cs-scale-101"
        />
      </div>
    );
  }
}
