import React from "react";
import ContentEditable from "react-contenteditable";
import ReactComment from "../../../ReactComment";
import "../../../../components/themes/feedmeonline.css";

const Cta1 = (props) => {
  const { brandGuidelines } = props;

  const handleChangeFunction = (element, event) => {
    const { blockKey, onContentChange } = props;
    if (event.target.value !== undefined) {
      onContentChange(blockKey, element, event.target.value);
    }
  };

  const {
    backgroundColor,
    titleColor,
    textColor,
    image1,
    image2,
    image3,
    title,
    text,
    ctaUrl1,
    ctaUrl2,
    ctaUrl3,
    blockKey,
  } = props;

  const comment1 =
    '[if (gte mso 9)|(IE)]><table border="0" cellSpacing="0" cellPadding="0" align="center" role="presentation"><tr><td valign="top"><![endif]';
  const comment2 = '[if (gte mso 9)|(IE)]></td><td valign="top"><![endif]';
  const comment3 = "[if (gte mso 9)|(IE)]></td></tr></table><![endif]";

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            className="cta1Wrapper"
            bgcolor={backgroundColor}
            style={{ backgroundColor: backgroundColor }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td valign="top" align="center">
                    <a
                      href={ctaUrl1}
                      style={{ textDecoration: "none" }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`${image1}?x-timestamp=${Date.now()}`}
                        width="100"
                        alt=""
                        className="cs-sm-m-0-auto cta1Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height="15" className="cta1Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: titleColor,
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.h2.size,
                      lineHeight: brandGuidelines.h2.lineHeight,
                      fontWeight: brandGuidelines.h2.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={title}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("title", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="10" className="cta1Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    className="cs-fb-font"
                    style={{
                      color: textColor,
                      fontFamily: brandGuidelines.fontStack.primary,
                      fontSize: brandGuidelines.pLead.size,
                      lineHeight: brandGuidelines.pLead.lineHeight,
                      fontWeight: brandGuidelines.pLead.fontWeight,
                      textAlign: "center",
                    }}
                    valign="top"
                  >
                    <ContentEditable
                      html={text}
                      disabled={false}
                      onChange={(event) => {
                        handleChangeFunction("text", event);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td height="5" className="cta1Spacer">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    className="cta1ButtonsContainer"
                    align="center"
                    valign="top"
                  >
                    <ReactComment text={comment1} />
                    <div className="cta1ButtonsColumn">
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="cta1Button"
                              valign="top"
                              align="center"
                            >
                              <a
                                href={ctaUrl2}
                                className="cta1CtaLink"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  src={`${image2}?x-timestamp=${Date.now()}`}
                                  width="127"
                                  height="52"
                                  alt=""
                                  className="cta1CtaImage"
                                  crossOrigin="anonymous"
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ReactComment text={comment2} />
                    <div className="cta1ButtonsColumn">
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="cta1Button"
                              valign="top"
                              align="center"
                            >
                              <a
                                href={ctaUrl3}
                                className="cta1CtaLink"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  src={`${image3}?x-timestamp=${Date.now()}`}
                                  width="136"
                                  height="52"
                                  alt=""
                                  className="cta1CtaImage"
                                  crossOrigin="anonymous"
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <ReactComment text={comment3} />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Cta1;
