import { useState } from "react";

const useMessageHandler = (initialState) => {
  const [successMessage, setSuccessMessage] = useState(initialState);
  const showSuccessMessage = (message) => {
    setSuccessMessage(message);
    window.setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };
  const showPersistentSuccessMessage = (message) => {
    setSuccessMessage(message);
  };
  const clearSuccessMessage = () => {
    setSuccessMessage(null);
  };
  return {
    successMessage,
    showSuccessMessage,
    showPersistentSuccessMessage,
    clearSuccessMessage,
  };
};

export default useMessageHandler;
