import React, { createContext } from "react";
import { DEFAULT_USER } from "../Constants";
import useAuthHandler from "../hooks/authHandler";
import { getStoredUserAuth } from "../helper/storage";

export const authContext = createContext({
  auth: DEFAULT_USER,
  isAuthenticated: false,
  setAuthStatus: () => {},
  setUnauthStatus: () => {},
  getBrandGuidelines: () => {},
  getBlockPermissions: () => {},
  getBlockPath: () => {},
});

const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  const {
    auth,
    setAuthStatus,
    setUnauthStatus,
    getAuthStatus,
    isAuthenticated,
    getBrandGuidelines,
    getBlockPermissions,
    getBlockPath,
  } = useAuthHandler(getStoredUserAuth());
  return (
    <Provider
      value={{
        auth,
        setAuthStatus,
        setUnauthStatus,
        getAuthStatus,
        isAuthenticated,
        getBrandGuidelines,
        getBlockPermissions,
        getBlockPath,
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthProvider;
