import React from "react";
import "../../../../components/themes/feedmeonline.css";

const Hero1 = (props) => {
  const { backgroundColor, imageUrl, ctaUrl, blockKey } = props;

  return (
    <table
      key={blockKey}
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      role="presentation"
    >
      <tbody>
        <tr>
          <td
            className="hero1Wrapper"
            width="100%"
            bgcolor={backgroundColor}
            style={{ backgroundColor: backgroundColor, padding: "30px" }}
            valign="top"
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              role="presentation"
            >
              <tbody>
                <tr>
                  <td valign="top">
                    <a
                      href={ctaUrl}
                      className="hero1ImageLink"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={`${imageUrl}?x-timestamp=${Date.now()}`}
                        width="560"
                        alt=""
                        className="hero1Image"
                        crossOrigin="anonymous"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Hero1;
